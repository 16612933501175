import CustomStats from "dashboard/CustomStats";
import { Stack, Typography } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import styles from "./styles.module.scss";
import CustomSortBy from "./CustomSortBy";

export default function SearchResultsHeader({ setOpenFacetsDrawer }) {
  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      spacing={1}
      sx={{
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: "45px",
        "@media (max-width: 1024px)": {
          height: "auto",
          flexDirection: "column",
        },
      }}
    >
      <CustomStats />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        useFlexGap
        className={`w-full lg:justify-end ${styles.sortFilter}`}
        spacing={2}
      >
        <Stack
          direction="row"
          alignItems="center"
          onClick={() => setOpenFacetsDrawer(true)}
          spacing={1}
          className="
            flex lg:hidden
            justify-between w-[90px]
            bg-white px-quarter
            rounded-full 
            border border-solid border-[#d2d6da] rounded-soft
            shadow-lg cursor-pointer"
        >
          <Typography>Filters</Typography>
          <ViewListIcon sx={{ color: "#7f8188" }} />
        </Stack>
        <CustomSortBy />
      </Stack>
    </Stack>
  );
}
