import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material";
import OrderTablePO from "./OrderTablePO";

const TABLE_HEADERS = [
  "Date",
  "Vendor",
  "PO #",
  "Order #",
  "Invoice #",
  "Items",
  "Tracking #",
  "Total",
];

const FlatPaper = styled(Paper)({
  boxShadow: "none",
});

const OrderTable = ({ orders, searchInput }) => {
  return (
    <TableContainer
      component={FlatPaper}
      sx={{
        maxWidth: "100%",
        overflowX: "auto",
        border: "1px solid #d2d6da",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {TABLE_HEADERS.map((header) => (
              <TableCell
                key={header}
                sx={{
                  fontWeight: "bold",
                  // Hide "Order #" and "Invoice #" below 1000px
                  "@media (max-width: 1000px)": {
                    display:
                      header === "Order #" || header === "Invoice #"
                        ? "none"
                        : "table-cell",
                  },
                  // Hide "Items", "Order #", and "Invoice #" below 750px
                  "@media (max-width: 750px)": {
                    display:
                      header === "Items" ||
                      header === "Order #" ||
                      header === "Invoice #"
                        ? "none"
                        : "table-cell",
                  },
                  // Hide "Total" in addition to the others below 500px
                  "@media (max-width: 500px)": {
                    display:
                      header === "Total" ||
                      header === "Items" ||
                      header === "Order #" ||
                      header === "Invoice #"
                        ? "none"
                        : "table-cell",
                    textAlign:
                      header === "Date" || header === "Vendor"
                        ? "center"
                        : "right", // Center-align Date and Vendor, right-align others
                    paddingRight: header === "Tracking #" ? "1rem" : "0", // Add padding to the Tracking # header
                    width:
                      header === "Date"
                        ? "22%"
                        : header === "Vendor"
                        ? "26%"
                        : header === "PO #"
                        ? "22%"
                        : header === "Tracking #"
                        ? "29%"
                        : undefined, // Adjust widths based on the content layout
                  },
                }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order, index) => (
            <OrderTablePO
              order={order}
              index={index}
              searchInput={searchInput}
              numOfTableHeaders={TABLE_HEADERS}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderTable;
