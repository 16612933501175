import { useState, useEffect, useMemo } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Typography, Box, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useParams, useSearchParams } from "react-router-dom";
import { getURLColor, getValidImageUrls } from "utilities/imageUrlUtils";
import ProductLongDescription from "./ProductLongDescription";
import ProductCategories from "./ProductCategories";
import ProductHeaderDetails from "./ProductHeaderDetails";
import DirectLogo from "items/DirectLogo";

const ImageList = styled("div")({
  display: "flex",
  flexWrap: "nowrap",
  gap: "8px",
  alignItems: "center",
  width: "100%",
  position: "relative",
  marginTop: "10px",
  padding: "4px",
  overflowX: "hidden",
  justifyContent: "center",
});

const ThumbnailImage = styled("img", {
  shouldForwardProp: (prop) => prop !== "isSelected",
})(({ isSelected }) => ({
  width: "60px",
  height: "60px",
  objectFit: "cover",
  cursor: "pointer",
  border: isSelected
    ? "2px solid rgb(2 129 192)" // lighterblueblue
    : "2px solid lightgray",
  borderRadius: "8px",
  "&:hover": {
    opacity: 0.8,
  },
  flexShrink: 0,
}));

const Arrow = styled("div")({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  padding: "0 10px",
  "&:hover": {
    opacity: 0.8,
  },
  height: "100%",
});

const Placeholder = ({ isScreenBelow440px }) => (
  <Box
    sx={{
      flexGrow: 1,
      height: "400px",
      bgcolor: "#F6F6F6",
      width: isScreenBelow440px ? "300px" : "336px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "#d2d6da",
      borderRadius: 1,
      p: 1.5,
    }}
  >
    <Typography variant="h1" sx={{ color: "inherit", marginBottom: 0 }}>
      DGI
    </Typography>
    <Typography variant="body1" sx={{ color: "inherit" }}>
      Image coming soon
    </Typography>
  </Box>
);

const RenderDirectLogo = ({ isDirectProduct }) => {
  if (!isDirectProduct) {
    return null;
  }
  return (
    <Box className="w-full flex justify-start ml-[3rem] mt-[0.5rem]">
      <DirectLogo onProductPage={true} />
    </Box>
  );
};

const ThumbnailImages = ({
  imageUrls,
  setImageUrls,
  selectedImageIndex,
  setSelectedImageIndex,
}) => {
  // Calculate which thumbnails to show
  const visibleThumbnails = useMemo(() => {
    if (imageUrls.length <= 5) {
      // If 5 or fewer images, show all of them
      return imageUrls.map((url, index) => ({
        url,
        index,
      }));
    }

    const result = [];
    // Show 5 thumbnails with selected in middle (index 2)
    for (let i = -2; i <= 2; i++) {
      let index = selectedImageIndex + i;
      // Wrap around if needed
      if (index < 0) index = imageUrls.length + index;
      if (index >= imageUrls.length) index = index - imageUrls.length;
      result.push({
        url: imageUrls[index],
        index: index,
      });
    }
    return result;
  }, [selectedImageIndex, imageUrls]);

  const onImgError = (url) => {
    setImageUrls((prevImageUrls) => {
      const urlIndex = prevImageUrls.findIndex((prevUrl) => prevUrl === url);
      const newImageUrls = prevImageUrls
        .slice(0, urlIndex)
        .concat(prevImageUrls.slice(urlIndex + 1));
      return newImageUrls;
    });
  };

  if (imageUrls.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        width: "360px",
        position: "relative",
        height: "85px",
        marginBottom: "1.5em",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ImageList>
        {visibleThumbnails.map(({ url, index }) => (
          <ThumbnailImage
            key={index}
            src={url}
            onError={() => onImgError(url)}
            onClick={() => setSelectedImageIndex(index)}
            isSelected={index === selectedImageIndex}
          />
        ))}
      </ImageList>
    </Box>
  );
};

const RenderSMExclusiveThumbnails = ({
  dgiStyle,
  colorQuery,
  vendorProduct,
  imageUrls,
  setImageUrls,
  selectedImageIndex,
  setSelectedImageIndex,
  setLoadingImages,
}) => {
  useEffect(() => {
    const images = vendorProduct?.data?.images || [];
    setImageUrls(images);
    if (vendorProduct) {
      setLoadingImages(false);
    }
  }, [
    dgiStyle,
    colorQuery,
    setLoadingImages,
    JSON.stringify(vendorProduct?.data?.images),
  ]);

  return (
    <ThumbnailImages
      imageUrls={imageUrls}
      setImageUrls={setImageUrls}
      selectedImageIndex={selectedImageIndex}
      setSelectedImageIndex={setSelectedImageIndex}
    />
  );
};

const RenderThumbnails = ({
  dgiStyle,
  colorQuery,
  imageUrls,
  setImageUrls,
  selectedImageIndex,
  setSelectedImageIndex,
  setLoadingImages,
}) => {
  useEffect(() => {
    const searchColor = { name: colorQuery };
    const imgColor = getURLColor(searchColor);
    const newImageUrls = getValidImageUrls(dgiStyle, imgColor);
    setImageUrls(newImageUrls);
    setLoadingImages(false);
  }, [dgiStyle, colorQuery, setLoadingImages]);

  return (
    <ThumbnailImages
      imageUrls={imageUrls}
      setImageUrls={setImageUrls}
      selectedImageIndex={selectedImageIndex}
      setSelectedImageIndex={setSelectedImageIndex}
    />
  );
};

const EnlargedProductImage = ({
  goToPreviousImage,
  goToNextImage,
  imageUrls,
  selectedImageIndex,
  loadingImages,
}) => {
  const theme = useTheme();
  const isScreenBelow440px = useMediaQuery(theme.breakpoints.down(440));

  const hasImages = imageUrls.length > 0;

  if (loadingImages) {
    return <Skeleton variant="rectangular" width="425px" height="425px" />;
  }

  return (
    <Box className="flex items-center h-full">
      <Arrow onClick={goToPreviousImage}>
        <ArrowBackIosNewIcon />
      </Arrow>
      <div>
        {hasImages ? (
          <img
            src={imageUrls[selectedImageIndex]}
            alt="Selected Product"
            style={{
              width: isScreenBelow440px ? "300px" : "360px",
              height: "auto",
            }}
          />
        ) : (
          <Placeholder isScreenBelow440px={isScreenBelow440px} />
        )}
      </div>
      <Arrow onClick={goToNextImage}>
        <ArrowForwardIosIcon />
      </Arrow>
    </Box>
  );
};

const ProductDetailsSection = ({
  selectedVendorCode,
  vendorProduct,
  smExclusive,
  isLGOrAbove,
  isDirectProduct,
}) => {
  const { dgiStyle } = useParams();
  const [searchParams] = useSearchParams();
  const colorQuery = searchParams.get("color");
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);
  const [loadingImages, setLoadingImages] = useState(true);
  const theme = useTheme();
  const isScreenAbove550px = useMediaQuery(theme.breakpoints.up(550));

  const goToNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex(
      (prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length
    );
  };

  let thumbnails = null;
  if (isScreenAbove550px) {
    if (smExclusive) {
      thumbnails = (
        <RenderSMExclusiveThumbnails
          dgiStyle={dgiStyle}
          colorQuery={colorQuery}
          vendorProduct={vendorProduct}
          imageUrls={imageUrls}
          setImageUrls={setImageUrls}
          selectedImageIndex={selectedImageIndex}
          setSelectedImageIndex={setSelectedImageIndex}
          setLoadingImages={setLoadingImages}
        />
      );
    } else {
      thumbnails = (
        <RenderThumbnails
          dgiStyle={dgiStyle}
          colorQuery={colorQuery}
          imageUrls={imageUrls}
          setImageUrls={setImageUrls}
          selectedImageIndex={selectedImageIndex}
          setSelectedImageIndex={setSelectedImageIndex}
          setLoadingImages={setLoadingImages}
        />
      );
    }
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      {!isLGOrAbove && <RenderDirectLogo isDirectProduct={isDirectProduct} />}
      <ProductHeaderDetails />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        minHeight="350px"
      >
        <EnlargedProductImage
          goToPreviousImage={goToPreviousImage}
          goToNextImage={goToNextImage}
          imageUrls={imageUrls}
          selectedImageIndex={selectedImageIndex}
          loadingImages={loadingImages}
        />
        {thumbnails}
        <ProductCategories />
      </Box>
      <ProductLongDescription
        selectedVendorCode={selectedVendorCode}
        vendorProduct={vendorProduct}
      />
    </Box>
  );
};

export default ProductDetailsSection;
