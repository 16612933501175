import styles from "./styles.module.scss";

export default function VendorLogo({ vendorCode }) {
  const vendorLogoSrc = `https://d34c0c1in94n75.cloudfront.net/${vendorCode}_logo.png`;

  return (
    <div
      className={styles.vendorLogo}
      data-upsize={
        vendorCode === "ab" ||
        vendorCode === "as" ||
        vendorCode === "pg" ||
        vendorCode === "acc" ||
        vendorCode === "sta"
      }
      data-downsize={vendorCode === "ss"}
      data-downsize-more={vendorCode === "slc"}
    >
      <img src={vendorLogoSrc} alt={null} />
    </div>
  );
}
