import React, { useState } from "react";

import styles from "./styles.module.scss";
import VendorCart from "./VendorCart";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useDataContext } from "App";
import AlertSnackbar from "alerts/AlertSnackbar";
import CartsHeader from "./CartsHeader";
import DirectVendorCart from "./DirectVendorCart";
import vendors from "constants/vendors";
import { useSelector } from "react-redux";

export const getVendorName = (vendorCode, selectedDirectCart = false) => {
  if (vendorCode === "All Carts") {
    return "All Carts";
  } else if (selectedDirectCart) {
    return "DGI Direct";
  }
  const targetFilter = Object.values(vendors).find(
    (vendor) => vendor.code === vendorCode
  );
  return targetFilter?.name;
};

function VendorCartWrapper({
  selectedFilter,
  selectedDirectCart,
  loadingCarts,
  allCarts,
}) {
  const userDistrConfig = useSelector(
    (state) => state.user?.user?.Distributors
  );

  function transformAndSortByTotal(carts, allCartsCase = true) {
    const array = Object.entries(carts)
      .filter((cart) => {
        if (!allCartsCase) {
          // direct case
          return true;
        }
        const vendorCode = cart[0];
        return Boolean(
          Object.values(vendors).find((v) => v.code === vendorCode)
        );
      })
      .map(([key, value]) => ({
        code: key,
        ...value,
      }));

    return array?.sort((a, b) => a?.name?.localeCompare(b?.name));;
  }
  const sortedCarts = transformAndSortByTotal(allCarts);

  const selectedCart = allCarts?.[selectedFilter]

  if (loadingCarts) {
    return (
      <div className={styles.loadingCartsSpinner}>
        <CircularProgress size="80px" />
        <div className={styles.loadingCartsMsg}>
          <span>Loading carts...</span>
        </div>
      </div>
    );
  } else if (selectedDirectCart) {
    return (
      <div className={`${styles.vendorCarts} gap-[32px]`}>
        <DirectVendorCart
          key="direct-vendorCart"
          transformAndSortByTotal={transformAndSortByTotal}
        />
      </div>
    );
  } else if (selectedFilter !== "All Carts" && sortedCarts) {
    return (
      <div className={`${styles.vendorCarts} gap-[32px]`}>
        <VendorCart
          key={`${selectedFilter}-vendorCart`}
          freeShippingMin={userDistrConfig?.[selectedFilter]?.free_shipping_minimum}
          vendorCode={selectedFilter}
          vendorName={selectedCart?.name}
          vendorCartData={selectedCart}
        />
      </div>
    );
  } else if (sortedCarts) {
    return (
      <div className={`${styles.vendorCarts} gap-[32px]`}>
        <DirectVendorCart
          key="direct-vendorCart"
          transformAndSortByTotal={transformAndSortByTotal}
        />
        {sortedCarts.map((cart) => (
          <VendorCart
            key={`${cart.code}-vendorCart`}
            freeShippingMin={userDistrConfig?.[cart?.code]?.free_shipping_minimum}
            vendorCode={cart?.code}
            vendorName={cart?.name}
            vendorCartData={cart}
          />
        ))}
      </div>
    );
  } else {
    return null;
  }
}
export default function CartCheckout({
  selectedFilter,
  loadingCarts,
  setLoadingCarts,
  allCarts,
}) {
  const { connectedVendorCodes } = useDataContext();
  const [refreshSuccessOpen, setRefreshSuccessOpen] = useState(false);
  const [refreshErrorOpen, setRefreshErrorOpen] = useState(false);

  const handleCloseRefreshSuccess = () => {
    setRefreshSuccessOpen(false);
  };

  const handleCloseRefreshError = () => {
    setRefreshErrorOpen(false);
  };

  const selectedDirectCart = Boolean(
    !Object.values(vendors).find((vendor) => vendor.code === selectedFilter) &&
      selectedFilter !== "All Carts"
  );

  return (
    <div className={styles.cartCheckout} data-loading-carts={loadingCarts}>
      <Stack spacing={2} gap="24px" sx={{ marginBottom: "24px" }}>
        <CartsHeader
          selectedFilter={selectedFilter}
          selectedDirectCart={selectedDirectCart}
          connectedVendorCodes={connectedVendorCodes}
        />
        <VendorCartWrapper
          selectedFilter={selectedFilter}
          loadingCarts={loadingCarts}
          setLoadingCarts={setLoadingCarts}
          selectedDirectCart={selectedDirectCart}
          allCarts={allCarts}
        />
      </Stack>
      <AlertSnackbar
        open={refreshSuccessOpen}
        severity="success"
        text="Successfully Refreshed Carts"
        handleClose={handleCloseRefreshSuccess}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      <AlertSnackbar
        open={refreshErrorOpen}
        severity="error"
        text="Unable to Refresh Carts"
        handleClose={handleCloseRefreshError}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
}
