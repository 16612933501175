import React from "react";
import styles from "./styles.module.scss";

import ColorSelection from "./ColorSelection";
import { Stack } from "@mui/material";
import { ProductInfoText } from "custom/text/shopping_page/StandardProductCardTexts";
import { formatBrandName } from "utilities/formatBrandName";
import UtilityBar from "./UtilityBar";

export default function ProductDetails({
  displayStyleNumber,
  hit,
  sendJsonMessage,
  similarClickHandle
}) {
  const { master_short_description, master_brand, colors, direct } = hit;

  return (
    <div className={styles.productDetails}>
      <Stack
        id="product-details-header"
        direction="column"
        className="cursor-pointer"
      >
        <ProductInfoText
          emboldenedContent={`${displayStyleNumber} • `}
          content={formatBrandName(master_brand)}
          textClassName={styles.productInfoText}
        />
        <ProductInfoText
          emboldenedContent={null}
          content={master_short_description}
          textClassName={styles.productInfoText}
        />
      </Stack>
      {colors && (
        <ColorSelection
          hit={hit}
          colorsData={colors}
          sendJsonMessage={sendJsonMessage}
          displayStyleNumber={displayStyleNumber}
        />
      )}
      <UtilityBar
        hit={hit}
        similarClickHandle={similarClickHandle}
        direct={direct}
      />
    </div>
  );
}
