import React from "react";
import VendorCart from "./VendorCart";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { CheckoutButton } from "./CartSummary";
import { Accordion, AccordionSummary, AccordionDetails, Skeleton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSelector } from "react-redux";
import { LOADING_STATES, selectDirectCarts } from "features/cartsSlice";

const DirectVendorCart = ({ transformAndSortByTotal }) => {
  const navigate = useNavigate();
  const directCarts = useSelector((state) => selectDirectCarts(state));
  const sortedDirectCarts = transformAndSortByTotal(directCarts, false);

  if (
    !directCarts ||
    directCarts?.loading === LOADING_STATES.LOADING_STATUS ||
    directCarts?.loading === LOADING_STATES.IDLE_STATUS
  ) {
    return <Skeleton variant="rectangular" height={48} />;
  }

  const startExpanded = sortedDirectCarts.length > 0;

  // write state fcn
  const totalSubtotal = Object.values(sortedDirectCarts).reduce(
    (total, cart) => total + (cart?.totalPrice || 0),
    0
  );
  const formattedTotalSubtotal = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(totalSubtotal);

  const handleProceedToCheckout = () => {
    // Navigate to a combined checkout page or handle multi-vendor checkout
    navigate("/direct/checkout");
  };

  const blueBaseDirectLogo =
    "https://d34c0c1in94n75.cloudfront.net/direct_logo_blue_base_orange_arrow.png";

  return (
    <Accordion
      defaultExpanded={startExpanded}
      elevation={0}
      sx={{
        "&:first-of-type": {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        borderLeft: "3px solid #FFA552",
        "&:hover": {
          boxShadow:
            "0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07)",
        },
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon className="text-charcoal" />}
        aria-controls="direct-vendor-cart-content"
        id="direct-vendor-cart-header"
        disableGutters={true}
        classes={{
          expanded: styles.vendorCartHeader,
          content: styles.vendorCartHeader,
          root: styles.vendorCartHeader,
        }}
        sx={{
          borderRight: "1px solid #e0e3e9",
          borderTop: "1px solid #e0e3e9",
          borderBottom: "1px solid #e0e3e9",
        }}
        className="bg-babyBlue"
      >
        <img
          src={blueBaseDirectLogo}
          alt="DGI Direct Cart"
          style={{
            maxHeight: "36px",
            padding: "4px 0px",
          }}
        />
      </AccordionSummary>
      <AccordionDetails
        className={`${styles.vendorCartData} relative pt-[32px] pb-[24px]`}
        sx={{
          borderRight: "1px solid rgba(225, 229, 230, 1)",
          borderBottom: "1px solid rgba(225, 229, 230, 1)",
          paddingX: "0",
        }}
      >
        <div className="flex justify-start gap-[20px] w-full">
          <div className={`${styles.vendorCarts} gap-[20px]`}>
            {sortedDirectCarts.map((directCart) => (
              <div className="flex justify-center">
                <div className="w-[98%]">
                  <VendorCart
                    key={`${directCart.code}-vendorCart`}
                    isDirectCart={true}
                    freeShippingMin={directCart?.freeShippingMin}
                    vendorCode={directCart?.code}
                    vendorName={directCart?.name}
                    vendorCartData={directCart}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className={styles.cartSummary}>
            <div className="sticky top-[20px] zIndex-[5]">
              <div className={styles.cartSummaryContent}>
                <div className={styles.cartSubtotal}>
                  <span className="text-grayscaleStone">
                    DGI Direct Subtotal:{" "}
                  </span>
                  <span className={styles.cartSubtotalValue}>
                    {formattedTotalSubtotal}
                  </span>
                </div>
                <CheckoutButton
                  handleProceedToCheckout={handleProceedToCheckout}
                  checkoutButtonText={"Checkout on DGI"}
                  checkoutDisabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default DirectVendorCart;
