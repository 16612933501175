import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import directClient from "../../axios/directClient";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
  Paper,
  Box,
  Typography,
  Divider,
  Button,
  Stack,
  Alert,
  AlertTitle,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchDirectCartSuccess } from "features/cartsSlice";

const DirectOrderSummary = ({
  userCreds,
  checkoutDetails,
  poNumber,
  selectedAddress,
  selectedShippingMethods,
  formInputs,
  setFormErrors,
  shippingTotal,
  needAddress,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderTotal =
    Number(shippingTotal) +
    Number(checkoutDetails?.taxTotal?.toFixed(2)) +
    Number(checkoutDetails?.subTotal?.toFixed(2));

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const someInvalidInput = Object.values(formInputs).some(
      (inputVal) => !inputVal,
    );
    if (someInvalidInput) {
      setFormErrors(() => {
        const errors = {};
        for (const [formInputKey, formInputVal] of Object.entries(formInputs)) {
          errors[formInputKey] = !formInputVal;
        }
        return errors;
      });
      setIsLoading(false);
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      setIsLoading(false);
      return;
    }

    // Create the ConfirmationToken using the details collected by the Payment Element
    // and additional shipping information
    const { error, confirmationToken } = await stripe.createConfirmationToken({
      elements,
      params: {
        setup_future_usage: "on_session",
        payment_method_save: true,
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
      setIsLoading(false);
      return;
    }

    // Create the PaymentIntent
    const headers = {
      "User-Identifier": userCreds.cognitoID,
    };

    const body = {
      action: "checkout",
      stripeConfirmationTokenId: confirmationToken.id,
      poNumber: poNumber,
      vendorShippingMethods: selectedShippingMethods,
      shippingAddress: selectedAddress,
      ...checkoutDetails,
    };
    try {
      const checkoutResp = await directClient.post(
        "/direct/checkout/complete",
        body,
        {
          headers,
        },
      );
      handleServerResponse(checkoutResp.data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuccessfulCheckout = (orderNumber) => {
    const directVendorCodes = [];
    for (let vendor of checkoutDetails?.vendors) {
      directVendorCodes.push(vendor.directVendor);
    }
    const directCartPayload = {
      action: "get_carts",
      error: false,
      vendorCode: "direct",
    };
    // prepare to clear direct carts on frontend
    for (let vendorCode of directVendorCodes) {
      directCartPayload[vendorCode] = {};
    }
    dispatch(
      fetchDirectCartSuccess({
        directCartData: directCartPayload,
      }),
    );
    // navigate to confirmation page
    navigate(`/receipt/dgi?orderId=${orderNumber}`);
  };

  const handleServerResponse = async (response) => {
    if (response.statusCode >= 400) {
      // Show error from server on payment form
      setMessage(response.error);
    } else if (response.status === "requires_action") {
      // Use Stripe.js to handle the required next action
      const { error, paymentIntent } = await stripe.handleNextAction({
        clientSecret: response.client_secret,
      });

      console.log(paymentIntent);
      if (error) {
        // Show error from Stripe.js in payment form
        setMessage(error.message);
      } else {
        // Actions handled, show success message
        handleSuccessfulCheckout(response.orderNumber);
      }
    } else {
      // No actions needed, show success message
      handleSuccessfulCheckout(response.orderNumber);
    }
  };

  const handleError = (err) => {
    console.error(err);
    setMessage(err?.response?.data?.message);
    return;
  };

  const totalItemNumber =
    checkoutDetails?.vendors?.reduce((total, vendor) => {
      return total + (vendor.items?.length || 0);
    }, 0) || 0;

  if (needAddress) {
    return null;
  }

  return (
    <Stack
      direction="column"
      spacing={1}
      className="max-w-[545px] sticky top-[10px]"
    >
      <Paper elevation={3} className="h-[fit-content]">
        <Box p={2} className="flex flex-col gap-[32px]">
          <Box>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography>Items ({totalItemNumber}):</Typography>
              <Typography>${checkoutDetails?.subTotal?.toFixed(2)}</Typography>
            </Box>

            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography>Shipping & handling:</Typography>
              <Typography>${shippingTotal}</Typography>
            </Box>

            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography>Estimated tax to be collected*:</Typography>
              <Typography>${checkoutDetails?.taxTotal?.toFixed(2)}</Typography>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography variant="h6">Order total:</Typography>
              <Typography variant="h6">${orderTotal.toFixed(2)}</Typography>
            </Box>
          </Box>
          <Box className="flex flex-col items-center gap-[10px]">
            <Typography
              variant="body2"
              color="text.secondary"
              className="text-base"
            >
              By placing your order, you agree to DGI's{" "}
              <Link
                to="/privacy"
                target="_blank"
                style={{ color: "inherit" }}
                className="text-base"
              >
                privacy notice
              </Link>{" "}
              and{" "}
              <Link
                to="/terms"
                target="_blank"
                style={{ color: "inherit" }}
                className="text-base"
              >
                conditions of use
              </Link>
              .
            </Typography>
            <Button
              variant="contained"
              className="bg-hardOrange hover:bg-softOrange max-w-[365px] py-[10px]"
              fullWidth
              sx={{ my: 1.75 }}
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? (
                <CircularProgress
                  className="text-dgiWhite"
                  size="20px"
                  thickness={7}
                />
              ) : (
                <Typography className="text-dgiWhite text-sm">
                  Place your order
                </Typography>
              )}
            </Button>
          </Box>
        </Box>
        {message && (
          <Alert severity="error" sx={{ fontSize: "15px " }}>
            <AlertTitle sx={{ fontSize: "18px " }}>{message}</AlertTitle>
          </Alert>
        )}
      </Paper>
      <Box className="p-[12px]">
        <Typography align="left" className="flex flex-col gap-[8px] text-sm">
          <strong>Note:</strong>
          <span className="text-[14px]">
            After placing an order with DGI Direct, you will receive order
            confirmation details from DGI Apparel. Shipping and fulfillment is
            handled by our trusted Direct Vendors.
          </span>
          <span className="text-[14px]">
            Questions? Press the chat button at the bottom right of your screen
            for further assistance.
          </span>
        </Typography>
      </Box>
    </Stack>
  );
};

export default DirectOrderSummary;
