import React from "react";
import VendorTab from "./VendorTab";
import styles from "./styles.module.scss";

export default function VendorTabs({ 
  vendorDataCodes, 
  autoAssignTab ,
  setCartAlertContent,
  setShowCartAlert,
  showCartAlert,
  vendorsBestPrices,
  hidePrices,
  selectedVendorCode,
  setSelectedVendorCode
}) {
  return (
    <div className={styles.vendorTabs}>
      {vendorDataCodes.length <= 5 ? (
        vendorDataCodes.map((vendorDataCode) => (
          <VendorTab
            key={vendorDataCode}
            vendorDataCode={vendorDataCode}
            isDropdown={false}
            autoAssignTab={autoAssignTab}
            setCartAlertContent={setCartAlertContent}
            setShowCartAlert={setShowCartAlert}
            showCartAlert={showCartAlert}
            vendorsBestPrices={vendorsBestPrices}
            hidePrices={hidePrices}
            selectedVendorCode={selectedVendorCode}
            setSelectedVendorCode={setSelectedVendorCode}
          />
        ))
      ) : (
        <>
          {vendorDataCodes.slice(0, 4).map((vendorDataCode) => (
            <VendorTab
              key={vendorDataCode}
              vendorDataCode={vendorDataCode}
              isDropdown={false}
              autoAssignTab={autoAssignTab}
              setCartAlertContent={setCartAlertContent}
              setShowCartAlert={setShowCartAlert}
              showCartAlert={showCartAlert}
              vendorsBestPrices={vendorsBestPrices}
              hidePrices={hidePrices}
              selectedVendorCode={selectedVendorCode}
              setSelectedVendorCode={setSelectedVendorCode}
            />
          ))}
          <VendorTab
            isDropdown={true}
            dropdownKeys={vendorDataCodes.slice(4)}
            autoAssignTab={autoAssignTab}
            setCartAlertContent={setCartAlertContent}
            setShowCartAlert={setShowCartAlert}
            showCartAlert={showCartAlert}
            vendorsBestPrices={vendorsBestPrices}
            hidePrices={hidePrices}
            selectedVendorCode={selectedVendorCode}
            setSelectedVendorCode={setSelectedVendorCode}
          />
        </>
      )}
    </div>
  );
}
