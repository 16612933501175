import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDataContext } from "App";
import { usePostHog } from "posthog-js/react";
import { TextField, IconButton, ClickAwayListener } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import SearchResults from "./SearchResults";
import { useSearchBar } from "./useSearchBar";

const SearchTextField = ({
  inputValue,
  inputRef,
  showSuggestions,
  submitQuery,
  handleChange,
  handleFocus,
  handleKeyDown,
}) => {
  return (
    <div
      className={`w-full h-12 bg-grayscaleWhite
    rounded-tr-softer rounded-tl-softer
    ${
      !showSuggestions &&
      "rounded-br-softer rounded-bl-softer rounded-tr-softer rounded-tl-softer"
    }`}
    >
      <div
        className={`flex flex-row items-end gap-[0.5rem] 
                        w-full h-full p-[4px]
                        ${
                          showSuggestions
                            ? "rounded-tr-softer rounded-tl-softer"
                            : "rounded-br-softer rounded-bl-softer rounded-tr-softer rounded-tl-softer"
                        }`}
      >
        <div className="flex-grow h-full w-full relative">
          <TextField
            fullWidth
            autoComplete="off"
            value={inputValue}
            onChange={handleChange}
            placeholder="Search items by style number, description, or brand..."
            variant="standard"
            className="pb-[1px]"
            InputProps={{
              disableUnderline: !showSuggestions,
              className:
                "custom-search-input bg-grayscaleWhite w-full min-h-full rounded-l-full rounded-r-full flex justify-start gap-[0.75rem] pt-[2px]",
              inputRef: inputRef,
              classes: {
                underline: "custom-underline",
              },
              startAdornment: (
                <IconButton
                  onClick={() => submitQuery()}
                  className="hover:bg-transparent pt-[6px]"
                  aria-label="search"
                >
                  <SearchIcon className="fill-charcoal text-[22px]" />
                </IconButton>
              ),
              inputProps: {
                className: `${styles.customPlaceholder} ${
                  inputValue.length > 0 && "pt-[6px]"
                }`,
              },
            }}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
    </div>
  );
};

const SearchBar = ({ 
  setShowBackdrop, 
  inputValue,
  setInputValue,
  refine,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { searchSession, setSearchSession } = useDataContext();
  const posthog = usePostHog();
  const containerRef = useRef(null);
  const [menuWidth, setMenuWidth] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);
  const firstName = useSelector((state) => state.user?.user?.firstName);
  // for scrolling on down and up arrows
  const itemRefs = useRef([]);
  const resultsContainerRef = useRef(null);

  const {
    isFocused,
    anchorEl,
    isInitialFocusLoading,
    showNewRecommendations,
    filteredNewRecommendations,
    filteredFavorites,
    autoCompleteSuggestions,
    usingDefaultFavorites,
    handleChange,
    handleFocus,
    setIsFocused,
    setAnchorEl,
  } = useSearchBar(containerRef, inputValue, setInputValue);

  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(
      0,
      filteredNewRecommendations.length +
        filteredFavorites.length +
        autoCompleteSuggestions.length
    );
    const searchResultsAvailable =
      filteredNewRecommendations.length ||
      filteredFavorites.length ||
      autoCompleteSuggestions.length;
    if (isFocused) {
      if (searchResultsAvailable) {
        setShowBackdrop(true);
      } else {
        setShowBackdrop(false);
      }
    } else {
      setShowBackdrop(false);
    }
  }, [
    filteredNewRecommendations,
    filteredFavorites,
    autoCompleteSuggestions,
    isFocused,
  ]);

  // Improved scroll handler
  useEffect(() => {
    if (selectedIndex >= 0 && itemRefs.current[selectedIndex]) {
      if (selectedIndex < 4) {
        // Scroll to absolute top close to top
        resultsContainerRef.current?.scrollTo({
          top: 0,
          behavior: "instant",
        });
      } else {
        // Get the item and container
        const item = itemRefs.current[selectedIndex];
        const container = resultsContainerRef.current;

        if (item && container) {
          // Get positions
          const itemRect = item.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();

          // Check if item is out of view
          const isItemBelow = itemRect.bottom > containerRect.bottom;
          const isItemAbove = itemRect.top < containerRect.top;

          if (isItemBelow || isItemAbove) {
            item.scrollIntoView({
              block: "nearest",
              behavior: "instant",
            });
          }
        }
      }
    }
  }, [selectedIndex]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "k") {
        event.preventDefault();
        inputRef.current?.focus();
      }
    };

    const handleResize = () => {
      setMenuWidth(containerRef.current?.offsetWidth || 0);
    };

    handleResize();
    document.addEventListener("keydown", handleKeyDown);
    window.addEventListener("resize", handleResize);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!location.pathname.includes("/shop")) {
      setInputValue("");
      setSelectedIndex(-1);
      refine("");
    }
  }, [location.pathname]);

  const handleClose = () => {
    setIsFocused(false);
    setAnchorEl(null);
  };

  const submitQuery = (value = inputValue) => {
    if (searchSession.in_progress) {
      posthog?.capture("search_and_update_cart", {
        updated_cart: searchSession.updated_cart,
        items_added: new Set(searchSession.items_added),
        search_term: searchSession.search_term,
        added_direct_vendor_items: searchSession.added_direct_vendor_items,
      });
    }
    setSearchSession({
      in_progress: true,
      search_term: value,
      items_added: [],
      updated_cart: false,
      prev_path: null,
      added_direct_vendor_items: false,
    });
    if (location.pathname !== "/shop") {
      navigate("/shop");
    }
    setInputValue(value);
    refine(value);
    handleClose();
  };

  const handleKeyDown = (event) => {
    const totalOptions =
      filteredNewRecommendations.length +
      filteredFavorites.length +
      (inputValue.length > 0 ? autoCompleteSuggestions.length : 0);

    // Ensure index is always within bounds when setting it
    const boundIndex = (index) => {
      if (index < 0) return 0;
      if (index >= totalOptions) return totalOptions - 1;
      return index;
    };

    switch (event.key) {
      case "Enter": {
        event.preventDefault();
        if (selectedIndex === -1 || selectedIndex >= totalOptions) {
          submitQuery();
          break;
        }

        const newRecsLength = filteredNewRecommendations.length;
        let option =
          selectedIndex < newRecsLength
            ? filteredNewRecommendations[selectedIndex]
            : selectedIndex < newRecsLength + filteredFavorites.length
            ? filteredFavorites[selectedIndex - newRecsLength]
            : autoCompleteSuggestions[
                selectedIndex - newRecsLength - filteredFavorites.length
              ];

        option = option.replace(/<\/?mark>/g, "");
        setInputValue(option);
        submitQuery(option);
        event.target.blur();
        break;
      }
      case "ArrowDown":
      case "Tab": {
        if (event.key === "Tab" && event.shiftKey) break;
        event.preventDefault();

        setSelectedIndex((prev) => {
          const nextIndex = prev + 1;
          return boundIndex(nextIndex);
        });
        break;
      }
      case "ArrowUp": {
        event.preventDefault();

        setSelectedIndex((prev) => {
          const nextIndex = prev - 1;
          return boundIndex(nextIndex);
        });
        break;
      }
    }
  };

  const showSuggestions =
    isFocused &&
    !isInitialFocusLoading &&
    (filteredNewRecommendations.length > 0 ||
      filteredFavorites.length > 0 ||
      autoCompleteSuggestions.length > 0);

  const searchProps = {
    inputValue,
    inputRef,
    showSuggestions,
    submitQuery,
    handleChange,
    handleFocus,
    handleKeyDown,
  };

  const resultsProps = {
    showSuggestions,
    menuWidth,
    anchorEl,
    isFocused,
    showNewRecommendations,
    filteredNewRecommendations,
    filteredFavorites,
    autoCompleteSuggestions,
    selectedIndex,
    inputValue,
    usingDefaultFavorites,
    firstName,
    submitQuery,
    itemRefs,
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div
        ref={containerRef}
        className={`flex flex-col max-w-[550px] w-full bg-grayscaleWhite 
            rounded-tr-softer rounded-tl-softer
            ${!showSuggestions && "rounded-br-softer rounded-bl-softer"}`}
      >
        <SearchTextField {...searchProps} />
        {showSuggestions && (
          <SearchResults
            {...resultsProps}
            resultsContainerRef={resultsContainerRef}
            itemRefs={itemRefs}

          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export default SearchBar;
