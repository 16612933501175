import React from "react";
import { Box, ToggleButtonGroup, ToggleButton } from "@mui/material";

const HeaderBar = ({ selectedTab, handleTabChange }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "50px",
        border: "1px solid #d2d6da",
        borderRadius: "5px",
        mb: 2,
        overflow: "hidden", // Ensure children don't overflow the rounded corners
      }}
    >
      <ToggleButtonGroup
        value={selectedTab}
        exclusive
        onChange={handleTabChange}
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <ToggleButton
          value="products"
          sx={{
            width: "50%", // Each button takes up 50% of the parent
            height: "100%",
            textAlign: "center",
            fontSize: "1rem",
            fontWeight: "bold",
            color: "#676F78", // Default text color
            border: "none",
            borderRadius: 0, // Remove default border radius
            "&.Mui-selected": {
              color: "rgba(0, 88, 141, 1)", // Selected text color
              backgroundColor: "#E0F7FA", // Selected background color
              borderBottom: "2px solid rgba(0, 88, 141, 1)", // Bottom border
            },
            "&:hover": {
              backgroundColor: "#E0F7FA",
            },
          }}
        >
          Products
        </ToggleButton>
        <ToggleButton
          value="about"
          sx={{
            width: "50%",
            height: "100%",
            textAlign: "center",
            fontSize: "1rem",
            fontWeight: "bold",
            color: "#676F78", // Default text color
            border: "none",
            borderRadius: 0,
            "&.Mui-selected": {
              color: "rgba(0, 88, 141, 1)", // Selected text color
              backgroundColor: "#E0F7FA",
              borderBottom: "2px solid rgba(0, 88, 141, 1)", // Bottom border
            },
            "&:hover": {
              backgroundColor: "#E0F7FA",
            },
          }}
        >
          About & Reviews
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default HeaderBar;
