import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import openInNewTab from "utilities/openInNewTab";

// Function to generate links based on vendor codes and order IDs
function generateVendorConfirmationLink(vendorCode, orderId) {
  switch (vendorCode) {
    case "sm":
      return orderId ? `https://www.sanmar.com/mysanmar/order-history/orderDetails?salesOrderNumber=${orderId}` : "https://www.sanmar.com/mysanmar/order-history";
    case "ss":
      return "https://www.ssactivewear.com/myaccount/orders";
    case "ab":
      return orderId ? `https://www.alphabroder.com/order-successful?orderRow=${orderId}` : "https://www.alphabroder.com/cgi-bin/livewamus/wam_tmpl/order_history_status.p?site=ABLive&layout=Responsive&page=order_history_status#/";
    case "cm":
      return "https://www.carolinamade.com/cgi-bin/live/wam_tmpl/order_status.p?site=CMD&layout=Base_b2b&page=order_status";
    default:
      return "#";  // Default link in case none of the conditions are met
  }
}
const NavButtons = ({ vendorName }) => {
  const { vendorCode } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleVendorClick = () => {
    if (vendorCode !== "dgi") {
      openInNewTab(generateVendorConfirmationLink(vendorCode, searchParams.get("orderId")))
      return;
    }
    navigate("/order-history")
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: 4,
        paddingBottom: 4,
      }}
    >
      <Button
        variant="outlined"
        sx={{
          marginRight: 6,
          minWidth: 220,
          color: "rgba(0, 110, 165, 1)",  // Custom color for text
          borderColor: "rgba(0, 110, 165, 1)",  // Custom border color
          "&:hover": {
            backgroundColor: "rgba(0, 110, 165, 0.1)",  // Lighter shade on hover
            borderColor: "rgba(0, 110, 165, 1)",  // Maintain border color on hover
          },
        }}
        className="hover:bg-lightblue border-lighterblueblue text-lighterblueblue"
        onClick={handleVendorClick}
      >
        View on {vendorName}
      </Button>
      <Button
        variant="contained"
        sx={{
          marginLeft: 6,
          minWidth: 220,
          backgroundColor: "rgba(0, 110, 165, 1)",  // Custom background color
          color: '#fff',  // Ensure text is white for better readability
          "&:hover": {
            backgroundColor: "rgba(0, 110, 165, 0.9)",  // Slightly darker on hover for effect
          }
        }}
        onClick={() => navigate(`/cart`)}
        className="bg-blueblue hover:bg-denim text-grayscaleWhite"
      >
        Return to Cart Page
      </Button>
    </Box>
  );
};

export default NavButtons;
