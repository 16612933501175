// ConnectionDetails.js

import React from "react";
import { Typography, Stack, useMediaQuery } from "@mui/material";
import {
  MonetizationOn,
  Warehouse,
  ShoppingCart,
  PointOfSale,
} from "@mui/icons-material";
import AccountCreationPrompt from "./AccountCreationPrompt";
import { checkoutDisabledVendorNames } from "../constants/vendors";

function ConnectionDetails({ vendorInfo }) {
  const isAbove500px = useMediaQuery("(min-width:500px)");

  if (checkoutDisabledVendorNames.has(vendorInfo.name)) {
    return (
      <>
        <Typography variant="h6" align="center" sx={{ mt: 2, mb: 1 }}>
          Connecting your {vendorInfo.name} account will enable:
        </Typography>
        <Stack direction="column" spacing={1} alignItems="flex-start">
          <Typography
            variant="body1"
            style={{
              color: "black",
              paddingLeft: "16px",
              textAlign: "left",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MonetizationOn
              style={{
                fontSize: "1.25rem",
                marginRight: "8px",
                color: "#45bd47",
              }}
            />
            <span>Your negotiated & sale pricing</span>
          </Typography>
          <Typography
            variant="body1"
            style={{
              color: "black",
              paddingLeft: "16px",
              textAlign: "left",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Warehouse
              style={{
                fontSize: "1.25rem",
                marginRight: "8px",
                color: "#45bd47",
              }}
            />
            <span>Live inventory from all warehouses</span>
          </Typography>
          <Typography
            variant="body1"
            style={{
              color: "black",
              paddingLeft: "16px",
              textAlign: "left",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ShoppingCart
              style={{
                fontSize: "1.25rem",
                marginRight: "8px",
                color: "#45bd47",
              }}
            />
            <span>Cart management</span>
          </Typography>
        </Stack>
        {isAbove500px && (
          <AccountCreationPrompt
            accountCreationLink={vendorInfo?.account_creation_link}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Typography variant="h6" align="center" sx={{ mt: 2, mb: 1 }}>
        Connecting your {vendorInfo.name} account will enable:
      </Typography>
      <Stack direction="column" spacing={1} alignItems="flex-start">
        <Typography
          variant="body1"
          style={{
            color: "black",
            paddingLeft: "16px",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          <MonetizationOn
            style={{
              fontSize: "1.25rem",
              marginRight: "8px",
              color: "#45bd47",
            }}
          />
          <span>Your negotiated & sale pricing</span>
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: "black",
            paddingLeft: "16px",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Warehouse
            style={{
              fontSize: "1.25rem",
              marginRight: "8px",
              color: "#45bd47",
            }}
          />
          <span>Live inventory from all warehouses</span>
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: "black",
            paddingLeft: "16px",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ShoppingCart
            style={{
              fontSize: "1.25rem",
              marginRight: "8px",
              color: "#45bd47",
            }}
          />
          <span>Cart management</span>
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: "black",
            paddingLeft: "16px",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          <PointOfSale
            style={{
              fontSize: "1.25rem",
              marginRight: "8px",
              color: "#45bd47",
            }}
          />
          <span>Checkout with your existing billing methods</span>
        </Typography>
      </Stack>
      {isAbove500px && (
        <AccountCreationPrompt
          accountCreationLink={vendorInfo?.account_creation_link}
        />
      )}
    </>
  );
}

export default ConnectionDetails;
