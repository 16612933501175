import React, { useState } from "react";
import {
  FormGroup,
  FormControlLabel,
  Switch,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { fetchUserSuccess } from "features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import prod_client from "../axios/prod_client.js";
import { debounce } from "lodash";
import endpoints from "../axios/endpoints.js";

export const DATA_TOGGLE_TYPES = {
  HIDE_PRICES: "hide_prices",
  SHOW_ONLY_SYNCED_VENDORS: "show_only_synced_vendors",
};

const DATA_TOGGLE_TEXTS = {
  HIDE_PRICES: "Hide Prices",
  SHOW_ONLY_SYNCED_VENDORS: "Show Only Synced Vendors",
};

const CustomSwitch = styled(Switch)(({ theme, onProductPage }) => ({
  width: onProductPage ? 28 : 32, // Even smaller on product page
  height: onProductPage ? 16 : 18, // Even smaller on product page
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: onProductPage ? "translateX(12px)" : "translateX(14px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "rgb(2 129 192)",
        opacity: 1,
        border: 0,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "4px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    color: "rgba(255, 255, 255, 1)",
    boxSizing: "border-box",
    width: onProductPage ? 12 : 14,
    height: onProductPage ? 12 : 14,
  },
  "& .MuiSwitch-track": {
    borderRadius: onProductPage ? 16 / 2 : 18 / 2,
    backgroundColor: "rgba(161, 164, 166, 1)",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const IconContainer = styled("div")(({ onProductPage }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 1,
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: onProductPage ? 16 : 18,
  height: onProductPage ? 16 : 18,
}));

function ProductDataToggle({ dataToggleType, userId, onProductPage }) {
  const dispatch = useDispatch();
  const isToggleChecked = useSelector(
    (state) => state.user?.user?.[dataToggleType]
  );
  const [loading, setLoading] = useState(false);

  const getLabelText = () => {
    switch (dataToggleType) {
      case DATA_TOGGLE_TYPES.HIDE_PRICES:
        return DATA_TOGGLE_TEXTS.HIDE_PRICES;
      case DATA_TOGGLE_TYPES.SHOW_ONLY_SYNCED_VENDORS:
        return DATA_TOGGLE_TEXTS.SHOW_ONLY_SYNCED_VENDORS;
    }
  };
  const labelText = getLabelText();

  const handleChange = async () => {
    try {
      setLoading(true);
      const userResp = await prod_client.put(endpoints.users(userId), {
        [dataToggleType]: !isToggleChecked,
        generic: true,
      });
      dispatch(fetchUserSuccess({ userObj: userResp?.data }));
    } catch (error) {
      console.error(`Error handling user preference change: ${error}`);
    } finally {
      setLoading(false);
    }
  };
  const debouncedHandleChange = debounce(handleChange, 300);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            {loading ? (
              <CustomSwitch
                checked={isToggleChecked ?? false}
                onChange={null}
                inputProps={{
                  "aria-label": labelText,
                }}
                checkedIcon={
                  <CircularProgress 
                    size={onProductPage ? 12 : 14} 
                    thickness={4} 
                    className="text-grayscaleWhite"
                  />
                }
                icon={
                  <CircularProgress 
                    size={onProductPage ? 12 : 14} 
                    thickness={4} 
                    className="text-grayscaleWhite"
                  />
                }
                onProductPage={onProductPage}
              />
            ) : (
              <CustomSwitch
                checked={isToggleChecked ?? false}
                onChange={debouncedHandleChange}
                inputProps={{
                  "aria-label": labelText,
                }}
                onProductPage={onProductPage}
              />
            )}
            <IconContainer sx={{ left: 0 }} onProductPage={onProductPage}>
              <CheckIcon
                sx={{
                  color: "rgba(255, 255, 255, 1)",
                  opacity: isToggleChecked ? 1 : 0,
                  fontSize: onProductPage ? 10 : 12,
                }}
              />
            </IconContainer>
            <IconContainer sx={{ right: 0 }} onProductPage={onProductPage}>
              <CloseIcon
                sx={{
                  color: "rgba(255, 255, 255, 1)",
                  opacity: isToggleChecked ? 0 : 1,
                  fontSize: onProductPage ? 10 : 12,
                }}
              />
            </IconContainer>
          </Box>
        }
        label={
          <Box className="w-full">
            <Box className="w-auto">
              <Typography className={`${onProductPage ? "text-almostSM" : ""} font-bold text-charcoal`}>
                {labelText}
              </Typography>
            </Box>
          </Box>
        }
        sx={{
          mx: "0px",
          gap: onProductPage ? "12px" : "16px",
          display: "flex",
          alignItems: "flex-start",
          "& .MuiFormControlLabel-label": {
            color: "white",
            display: "flex",
            justifyContent: "right",
            width: "100%",
          },
        }}
      />
    </FormGroup>
  );
}

export default ProductDataToggle;
