import React from "react";
import styles from "./styles.module.scss";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

import { useUserStateContext } from "App";
import openInNewTab from "utilities/openInNewTab";
import { checkoutDisabledVendorNames } from "constants/vendors";
import Typography from "@mui/material/Typography/Typography";

const vendorURLs = {
  Alphabroder: "https://www.alphabroder.com/cart",
  "S&S Activewear": "https://www.ssactivewear.com/checkout/cart",
  "Carolina Made":
    "https://www.carolinamade.com/cgi-bin/live/wam_tmpl/cart.p?site=CMD&layout=Base_b2b&page=cart",
  Sanmar: "https://www.sanmar.com/cart",
  "Augusta Sportswear": "https://www.augustasportswear.com/Cart",
  "Atlantic Coast Cotton":
    "https://www.orderacc.com/cgi-bin/liveb2b/wam_tmpl/cart.p?site=ACC&layout=Responsive&page=cart",
  PrintGear: "https://www.printgear.com/cart",
  // TODO need to pull redirect url from user object
  "AS Colour": "https://www.ascolour.com/cart",
  Staton:
    "https://www.statononline.com/web_checkout?utm_source=dgi&utm_medium=referral&utm_campaign=integration",
  "SLC Activewear": "https://slcactivewear.com/cart?utm_source=dgi_apparel",
};

const vendorIDs = {
  Alphabroder: "ab",
  "S&S Activewear": "ss",
  "Carolina Made": "cm",
  Sanmar: "sm",
  "Augusta Sportswear": "as",
  "Atlantic Coast Cotton": "acc",
  PrintGear: "pg",
  "AS Colour": "asc",
  Staton: "sta",
  "SLC Activewear": "slc",
};

export function CheckoutButton({
  handleProceedToCheckout,
  checkoutButtonText,
  checkoutDisabled = false,
}) {
  return (
    <Button
      className={`bg-lighterblueblue hover:bg-blueblue text-grayscaleWhite ${styles.viewCartButton}`}
      onClick={handleProceedToCheckout}
      disabled={checkoutDisabled}
    >
      {checkoutButtonText + " "}
      <ArrowForwardIosIcon
        style={{ height: "0.6em", verticalAlign: "middle" }}
      />
    </Button>
  );
}

export default function CartSummary({ subTotal, vendorName, vendorCartData }) {
  const navigate = useNavigate();
  const { userCreds } = useUserStateContext();

  const handleViewCart = () => {
    let vendorUrl = vendorURLs[vendorName];
    if (vendorName === "AS Colour") {
      vendorUrl = vendorCartData?.redirect_url;
    }
    if (vendorUrl) {
      openInNewTab(vendorUrl);
    } else {
      console.error(`No URL found for vendor ${vendorName}`);
    }
  };

  const handleProceedToCheckout = () => {
    const vendorId = vendorIDs[vendorName];
    if (vendorId) {
      navigate(`/checkout/${vendorId}`);
    } else {
      console.error(`No ID found for vendor ${vendorName}`);
    }
  };

  const checkoutDisabled =
    !userCreds?.checkoutEnabled && checkoutDisabledVendorNames.has(vendorName);
  const checkoutButtonText = checkoutDisabled
    ? `Checkout on ${vendorName}`
    : "Checkout on DGI";
  const ascCartBlurb = `This button transfers items to your AS Colour cart. 
    Ensure you’re logged into their site before completing your purchase.`;

  function ProceedToCheckoutButtons() {
    if (checkoutDisabled) {
      return (
        <div className="flex flex-col gap-[16px]">
          <Button
            className={`bg-lighterblueblue hover:bg-blueblue text-grayscaleWhite ${styles.viewCartButton}`}
            onClick={handleViewCart}
          >
            {checkoutButtonText + " "}
            <ArrowForwardIosIcon
              style={{ height: "0.6em", verticalAlign: "middle" }}
            />
          </Button>
          {vendorName === "AS Colour" && (
            <div className="flex gap-[6px]">
              <span className="font-bold text-2xl text-[#414141]">*</span>
              <Typography className="font-bold text-sm text-[#414141]">
                {ascCartBlurb}
              </Typography>
            </div>
          )}
        </div>
      );
    }
    return (
      <>
        <CheckoutButton 
           handleProceedToCheckout={handleProceedToCheckout}
           checkoutButtonText={checkoutButtonText}
           checkoutDisabled={checkoutDisabled}
        />
        <div className="text-center">
          <a
            href="#"
            className="text-sm text-blueblue "
            style={{ textDecoration: "none" }}
            onClick={handleViewCart}
          >
            {`or view on ${vendorName}`}
          </a>
        </div>
      </>
    );
  }

  return (
    <div className={styles.cartSummary}>
      <div className={styles.cartSummaryContent}>
        <div className={styles.cartSubtotal}>
          <span className="text-grayscaleStone">Subtotal:</span>
          <span className={styles.cartSubtotalValue}> {subTotal}</span>
        </div>
        <ProceedToCheckoutButtons />
      </div>
    </div>
  );
}
