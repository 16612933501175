// ConnectionPage.jsx
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material"; // Import useMediaQuery from MUI
import styles from "./styles.module.scss";
import ConnectionInfo from "./ConnectionInfo";
import ConnectionModules from "./ConnectionModules";
import TutorialDialog from "./TutorialDialog";

const ConnectionPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isTutorialMode = params.get("mode") === "tutorial";

  const [openTutorial, setOpenTutorial] = useState(isTutorialMode);

  // useMediaQuery to check if screen width or height is below 650px
  const isScreenLargeEnough = useMediaQuery("(min-width:650px) and (min-height:650px)");

  const handleClose = () => {
    setOpenTutorial(false);
  };

  return (
    <div className={styles.integrationsPageBackground}>
      <div className={styles.connectionPage}>
        <div className="flex flex-col items-center">
          <h1 className="text-4xl mt-[0px] font-normal pb-[1em]">
            Which vendors would you like to connect?
          </h1>
        </div>
        <div className={styles.connectionPageContent}>
          <ConnectionModules />
          <ConnectionInfo />
        </div>
      </div>
      {openTutorial && isScreenLargeEnough && (
        <TutorialDialog open={openTutorial} onClose={handleClose} />
      )}
    </div>
  );
};

export default ConnectionPage;
