import React, { useState } from "react";
import { AppBar, Typography, Box, Tooltip } from "@mui/material";
import white_lock_icon from "../assets/white_lock_icon.png";
import { useNavigate } from "react-router-dom";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

function SecureCheckoutMenu() {
  const [openNavMenu, setOpenNavMenu] = useState(false);

  const handleNavMenuClick = (event) => {
    if (openNavMenu) {
      handleNavMenuClose();
    } else {
      setOpenNavMenu(true);
    }
  };

  const handleNavMenuClose = () => {
    setOpenNavMenu(false);
  };
  return (
    <Tooltip
      title="We secure your payment and personal information when you share or save it with us. We don't sell your information to others."
      placement="bottom"
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            backgroundColor: "#FFFFFF",
            color: "#000000",
            padding: "12px 20px",
            fontSize: "15px",
            borderRadius: "4px",
            marginTop: "8px",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
            border: "1px solid rgba(0, 0, 0, 0.05)",
          },
          "& .MuiTooltip-arrow": {
            color: "#FFFFFF",
            "&::before": {
              boxShadow: "-1px -1px 1px rgba(0, 0, 0, 0.05)",
            },
          },
        },
      }}
      arrow
    >
      <Box
        className="flex gap-[12px] justify-center items-center cursor-pointer px-[5px] mt-[5px]"
        onClick={handleNavMenuClick}
        sx={{
          borderBottom: "2px solid transparent",
          "&:hover": {
            borderColor: "#EEF3F5",
          },
        }}
      >
        <Typography className="text-dgiWhite text-xl">
          Secure Checkout
        </Typography>
        <img
          src={white_lock_icon}
          alt={null}
          style={{
            height: "16px",
            width: "auto",
            display: "block",
            marginBottom: "3px",
          }}
        />
      </Box>
    </Tooltip>
  );
}

function DirectCheckoutHeader() {
  const navigate = useNavigate();
  const whiteBaseDirectLogo =
    "https://d34c0c1in94n75.cloudfront.net/direct_logo_white_base_orange_arrow.png";
  return (
    <AppBar
      position="static"
      elevation={0}
      className="bg-denim flex flex-row justify-between items-center py-[8px]"
    >
      <Box
        className="flex cursor-pointer pl-[1rem]"
        sx={{
          transition: "transform 0.3s",
          "&:hover": {
            transform: "scale(1.05)",
          },
        }}
      >
        <img
          src={whiteBaseDirectLogo}
          alt={null}
          style={{ height: "28px", width: "auto", display: "block" }}
        />
      </Box>
      <SecureCheckoutMenu />
      <Box
        className="pr-[1rem] hover:opacity-80 cursor-pointer mt-[5px] flex items-center gap-[6px]"
        onClick={() => navigate("/cart")}
      >
        <ShoppingCartOutlinedIcon fontSize="small" className="text-dgiWhite" />
        <Typography className="text-dgiWhite">Return to Cart</Typography>
      </Box>
    </AppBar>
  );
}

export default DirectCheckoutHeader;
