import React from "react";
import { Stack, Typography, LinearProgress } from "@mui/material";

function CheckoutLoadingProgress({ message }) {
  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      sx={{
        gap: "20px",
        paddingTop: "48px",
        paddingBottom: "48px",
      }}
    >
      <Typography variant="subtitle1">{message}</Typography>
      <LinearProgress sx={{ width: "400px" }} />
    </Stack>
  );
}

export default CheckoutLoadingProgress;
