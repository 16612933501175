import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import CheckoutContent from "./CheckoutContent";
import NavButtons from "./NavButtons";
import axios from "../../axios/prod_client.js";
import ssClient from "../../axios/ssClient.js";
import directClient from "../../axios/directClient";
import endpoints from "../../axios/endpoints.js";
import { useUserStateContext } from "../../App";

async function getCheckoutDetails(
  vendorCode,
  userCreds,
  setCheckoutDetails,
  setLoadingCheckoutDetails,
) {
  if (!userCreds.cognitoID) {
    return;
  }

  const headers = {
    "User-Identifier": userCreds.cognitoID,
  };

  const params = {};
  if (vendorCode == "sm") {
    params.separateShippingMethods = true;
  }
  try {
    var client;
    if (vendorCode === "ss") {
      client = ssClient;
    } else {
      client = directClient;
    }
    const checkoutDetailsResp = await client.get(
      endpoints.checkoutDetails(vendorCode),
      {
        headers,
        params,
      },
    );
    setCheckoutDetails(checkoutDetailsResp.data);
    setLoadingCheckoutDetails(false);
  } catch (error) {
    console.error(
      `Error getting checkout details for distr ${vendorCode}: ${error}`,
    );
    setLoadingCheckoutDetails(false);
  }
}

export async function getShippingMethods({
  extended,
  shippingAddrCode,
  vendorCode,
  userCreds,
  setCheckoutDetails,
  setLoadingShippingMethods,
  setErrorFetchingShippingMethods,
}) {
  setLoadingShippingMethods(true);
  setErrorFetchingShippingMethods(false);
  if (!userCreds.cognitoID) {
    return;
  }

  const headers = {
    "User-Identifier": userCreds.cognitoID,
  };

  const body = {
    extended: extended,
    shippingAddrCode: shippingAddrCode,
  };
  try {
    const resp = await directClient.post(
      endpoints.getShippingMethods(vendorCode),
      body,
      {
        headers,
      },
    );
    setCheckoutDetails((prevCheckoutDetails) => ({
      ...prevCheckoutDetails,
      shippingMethods: resp.data?.shippingMethods,
    }));
    setLoadingShippingMethods(false);
  } catch (error) {
    console.error(
      `Error getting shipping methods for distr ${vendorCode}: ${error}`,
    );
    setErrorFetchingShippingMethods(true);
    setLoadingShippingMethods(false);
  }
}

const CheckoutPage = () => {
  const { vendorCode } = useParams();
  const { userCreds } = useUserStateContext();
  const [checkoutDetails, setCheckoutDetails] = useState(null);
  const [loadingCheckoutDetails, setLoadingCheckoutDetails] = useState(true);
  const [loadingShippingMethods, setLoadingShippingMethods] = useState(true);
  const [errorFetchingShippingMethods, setErrorFetchingShippingMethods] =
    useState(false);
  // We don't include this state var in calculating canConfirmOrder
  // because a PO # is not needed to complete the order.
  // We just can't have an invalid PO #.
  const [invalidPONumber, setInvalidPONumber] = useState(false);

  useEffect(() => {
    (async () => {
      if (vendorCode) {
        await getCheckoutDetails(
          vendorCode,
          userCreds,
          setCheckoutDetails,
          setLoadingCheckoutDetails,
        );
      }
    })();
  }, [vendorCode, userCreds]);

  useEffect(() => {
    if (checkoutDetails) {
      setLoadingCheckoutDetails(false);
      if (vendorCode == "sm" && checkoutDetails?.shippingMethods == null) {
        getShippingMethods({
          extended: false,
          shippingAddrCode: checkoutDetails.shippingAddr.code,
          vendorCode,
          userCreds,
          setCheckoutDetails,
          setLoadingShippingMethods,
          setErrorFetchingShippingMethods,
        });
      } else {
        setLoadingShippingMethods(false);
      }
    }
  }, [checkoutDetails]);

  return (
    <Container
      maxWidth={false}
      sx={{ maxWidth: 1000, width: "100%", margin: "0 auto" }}
    >
      <Stack direction="row">
        <CheckoutContent
          checkoutDetails={checkoutDetails}
          loadingCheckoutDetails={loadingCheckoutDetails}
          getShippingMethods={(extended) =>
            getShippingMethods({
              extended: extended,
              shippingAddrCode: checkoutDetails.shippingAddr.code,
              vendorCode,
              userCreds,
              setCheckoutDetails,
              setLoadingShippingMethods,
              setErrorFetchingShippingMethods,
            })
          }
          loadingShippingMethods={loadingShippingMethods}
          errorFetchingShippingMethods={errorFetchingShippingMethods}
          setInvalidPONumber={setInvalidPONumber}
        />
      </Stack>
      <NavButtons invalidPONumber={invalidPONumber} />
    </Container>
  );
};

export default CheckoutPage;
