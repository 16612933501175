import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserStateContext, useCheckoutContext } from "App";
import ConfirmationHeader from "./ConfirmationHeader";
import ConfirmationFields from "./ConfirmationFields";
import { CheckoutDisclaimer } from "custom/text/StandardCheckoutTexts";
import directClient from "../../axios/directClient";
import ssClient from "../../axios/ssClient.js";
import endpoints from "../../axios/endpoints";
import vendors from "constants/vendors";
import { DISABLE_CHECKOUT } from "constants/featureFlags";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Box, Typography, LinearProgress } from "@mui/material";

const ConfirmationContent = ({ setCanExecuteOrder }) => {
  const { checkoutFormData } = useCheckoutContext();
  const { vendorCode } = useParams();
  const { userCreds } = useUserStateContext();
  const navigate = useNavigate();
  const [orderSummary, setOrderSummary] = useState({
    creditCardLostCashDiscount: 0,
    shippingCost: 0,
    subTotal: 0,
    total: 0,
  });
  const [loadingOrderSummary, setLoadingOrderSummary] = useState(true);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [messageIndex, setMessageIndex] = useState(0);
  const vendorName = vendors[vendorCode + "_data"]["name"];
  const timerRef = useRef(null);

  const messages = [
    `Connecting to ${vendorName}...`,
    "Loading your order summary...",
    "Verifying your info...",
    "Almost done...",
  ];

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setMessageIndex((prevIndex) =>
        prevIndex < messages.length - 1 ? prevIndex + 1 : prevIndex,
      );
    }, 3000);

    return () => clearInterval(timerRef.current);
  }, []);

  useEffect(() => {
    fetchOrderSummary();
  }, [checkoutFormData]);

  const fetchOrderSummary = async () => {
    const headers = {
      "User-Identifier": userCreds.cognitoID,
    };
    const body = {
      shippingAddress: checkoutFormData.shippingAddress,
      shippingMethods: checkoutFormData.warehouseShippingMethods,
      paymentMethod: checkoutFormData.paymentMethod,
    };
    setDisplayErrorMessage(false);
    setLoadingOrderSummary(true);
    try {
      var client;
      if (vendorCode === "ss") {
        client = ssClient;
      } else {
        client = directClient;
      }
      const checkoutFinalizeResp = await client.post(
        endpoints.checkoutFinalize(vendorCode),
        body,
        { headers },
      );
      if (!checkoutFinalizeResp.data) {
        navigate("/cart");
      }
      setOrderSummary(checkoutFinalizeResp.data);
      if (!DISABLE_CHECKOUT.has(userCreds.cognitoID)) {
        setCanExecuteOrder(true);
      }
      setLoadingOrderSummary(false);
      clearInterval(timerRef.current);
    } catch (error) {
      console.error(`Failed to fetch order summary with error: ${error}`);
      setDisplayErrorMessage(true);
      setLoadingOrderSummary(false);
      clearInterval(timerRef.current);
    }
  };

  let renderConfirmationFields;
  if (!loadingOrderSummary && !displayErrorMessage) {
    renderConfirmationFields = (
      <ConfirmationFields orderSummary={orderSummary} />
    );
  } else if (!loadingOrderSummary && displayErrorMessage) {
    renderConfirmationFields = (
      <Alert
        severity="error"
        action={
          <Button
            color="inherit"
            size="small"
            sx={{ fontSize: "14px" }}
            onClick={fetchOrderSummary}
          >
            RETRY
          </Button>
        }
        sx={{ width: "65%", fontSize: "16px" }}
      >
        Failed to load order summary for {vendorName}.
      </Alert>
    );
  } else if (loadingOrderSummary) {
    renderConfirmationFields = (
      <Stack
        flexDirection="column"
        alignItems="center"
        sx={{
          gap: "20px",
          paddingTop: "48px",
          paddingBottom: "48px",
        }}
      >
        <Typography variant="subtitle1">{messages[messageIndex]}</Typography>
        <LinearProgress sx={{ width: "400px" }} />
      </Stack>
    );
  }

  return (
    <Box
      className="flex-1 py-4"
      sx={{
        "& .confirmationSection": {
          border: "1px solid lightgray",
          borderLeft: "2px solid rgb(2, 129, 192)", // lighterblueblue
        },
      }}
    >
      <Stack direction="column" spacing={2} alignItems="center">
        <ConfirmationHeader vendorName={vendorName} />
        <CheckoutDisclaimer vendorName={vendorName} />
        {renderConfirmationFields}
      </Stack>
    </Box>
  );
};

export default ConfirmationContent;
