import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

const DirectBanner = ({ vendorCode, isLoadingVendor }) => {
  const [imageStatus, setImageStatus] = useState("loading"); // "loading" | "valid" | "invalid"
  const bannerUrl = `https://d34c0c1in94n75.cloudfront.net/${vendorCode}_direct_banner.png`;

  useEffect(() => {
    if (!vendorCode || isLoadingVendor) return;
    setImageStatus("loading");

    // Create new image object to preload
    const img = new Image();
    img.src = bannerUrl;

    img.onload = () => {
      setImageStatus("valid");
    };

    img.onerror = () => {
      setImageStatus("invalid");
    };

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [vendorCode, bannerUrl, isLoadingVendor]);

  if (
    isLoadingVendor ||
    imageStatus === "invalid" ||
    imageStatus === "loading"
  ) {
    return null;
  }

  // Show image once loaded
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1568px",
        margin: "0 auto",
        textAlign: "center",
      }}
    >
      <Box
        component="img"
        src={bannerUrl}
        alt={null}
        sx={{
          width: "100%",
          height: "auto",
          maxHeight: "160px",
        }}
      />
    </Box>
  );
};

export default DirectBanner;
