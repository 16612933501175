import React, { useMemo, useCallback } from "react";
import styles from "./styles.module.scss";
import SideVendorCart from "./SideVendorCart";

const SideVendorCarts = ({ vendorCarts, vendorTotals, hidePrices }) => {
  // Memoize the sorting function
  const sortVendorDataBySubtotals = useCallback((vendorCartA, vendorCartB) => {
    const vendorCodeA = vendorCartA[0];
    const vendorCodeB = vendorCartB[0];

    if (
      vendorTotals?.[vendorCodeA] === undefined ||
      vendorTotals?.[vendorCodeB] === undefined
    ) {
      return 0;
    }

    const cartSubtotalA = vendorTotals[vendorCodeA]["subtotal"];
    const cartSubtotalB = vendorTotals[vendorCodeB]["subtotal"];

    if (cartSubtotalB < cartSubtotalA) {
      return -1;
    } else if (cartSubtotalA < cartSubtotalB) {
      return 1;
    }

    return 0;
  }, [vendorTotals]);

  // Memoize the sorted carts
  const sortedVendorCarts = useMemo(() => {
    return Object.keys(vendorCarts).length === 0 
      ? [] 
      : Object.entries(vendorCarts).sort(sortVendorDataBySubtotals);
  }, [vendorCarts, sortVendorDataBySubtotals]);

  // Memoize the rendered carts
  const renderedCarts = useMemo(() => 
    sortedVendorCarts.map((vendorCart, index) => (
      <SideVendorCart
        key={`side-vendor-cart-${index}`}
        vendorCart={vendorCart}
        totals={vendorTotals?.[vendorCart[0]]}
        hidePrices={hidePrices}
      />
    ))
  , [sortedVendorCarts, vendorTotals, hidePrices]);

  return (
    <div className={styles.sideVendorCarts}>
      {renderedCarts}
    </div>
  );
};

export default React.memo(SideVendorCarts);