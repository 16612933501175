import React, { useEffect, useState } from "react";
import { typesenseProductsIndexName } from "App";
import { useSortBy } from "react-instantsearch";
import { useLocation } from "react-router-dom";

export default function CustomSortBy() {
  const location = useLocation();
  const [selectedValue, setSelectedValue] = useState(typesenseProductsIndexName);
  const { refine, options } = useSortBy({
    items: [
      { label: "Sort By: Relevancy", value: typesenseProductsIndexName },
      {
        label: "Sort By: Wholesale Price Low to High",
        value: `${typesenseProductsIndexName}/sort/catalog_price:asc`,
      },
      {
        label: "Sort By: Wholesale Price High to Low",
        value: `${typesenseProductsIndexName}/sort/catalog_price:desc`,
      },
    ],
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const sortParam = urlParams.get('sortBy');
    if (sortParam && options.some(option => option.value === sortParam)) {
      setSelectedValue(sortParam);
      refine(sortParam);
    } else {
      setSelectedValue(typesenseProductsIndexName);
      refine(typesenseProductsIndexName);
    }
  }, [location.pathname, location.search, refine, options]);

  return (
    <div className="flex grow flex-col-reverse bg-white max-w-[300px]">
      <select
        className="text-charcoal text-base rounded-soft border border-solid border-[#d2d6da] shadow-lg p-quarter cursor-pointer"
        onChange={(event) => {
          setSelectedValue(event.target.value);
          refine(event.target.value);
        }}
        value={selectedValue}
      >
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            className="rounded-soft cursor-pointer"
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
