import React, { useMemo } from "react";
import styles from "./styles.module.scss";
import { TotalFigureText } from "custom/text/shopping_page/StandardSideCartTexts";

const SideCartHeader = ({ vendorTotals, hidePrices }) => {
  const { totalNumberOfItems, totalAmount, numberOfCarts } = useMemo(() => {
    if (Object.keys(vendorTotals).length === 0) {
      return {
        totalNumberOfItems: 0,
        totalAmount: "$0.00",
        numberOfCarts: 0,
      };
    }

    const vendorTotalValues = Object.values(vendorTotals);
    const calculatedValues = vendorTotalValues.reduce(
      (acc, vendorValues) => ({
        totalNumberOfItems: acc.totalNumberOfItems + vendorValues.totalItems,
        totalAmount: acc.totalAmount + vendorValues.subtotal,
        numberOfCarts: acc.numberOfCarts + 1,
      }),
      {
        totalNumberOfItems: 0,
        totalAmount: 0.0,
        numberOfCarts: 0,
      }
    );

    const formattedTotalAmount = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(calculatedValues.totalAmount);

    return {
      ...calculatedValues,
      totalAmount: formattedTotalAmount,
    };
  }, [vendorTotals]);

  const figures = [
    { content: totalNumberOfItems, label: "items" },
    { content: totalAmount, label: "subtotal" },
    { content: numberOfCarts, label: "carts" },
  ];

  return (
    <div className={styles.sideCartHeader}>
      {figures.map((figure, index) => (
        <TotalFigureText
          key={index}
          content={figure.content}
          label={figure.label}
          hidePrices={hidePrices}
        />
      ))}
    </div>
  );
};

export default SideCartHeader;
