import React from "react";
import { Box, Skeleton } from "@mui/material";
import StoreFront from "./StoreFront"; // Import the StoreFront component
import ContactInfo from "./ContactInfo"; // Import the ContactInfo component

const ContactColumn = ({ vendor, handleTabChange, isVendorLoading }) => {
  const LoadingSkeleton = () => (
    <Box
      sx={{
        p: 2,
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: 1,
      }}
    >
      <Skeleton variant="rectangular" width="100%" height={50} sx={{ mb: 1 }} />
      <Skeleton variant="text" width="80%" />
      <Skeleton variant="text" width="60%" />
      <Skeleton variant="text" width="70%" />
      <Skeleton variant="text" width="60%" />
    </Box>
  );

  return (
    <Box
      sx={{
        width: "270px",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        "@media (max-width: 800px)": {
          width: "100%",
        },
      }}
    >
      <Box
        sx={{
          "@media (max-width: 800px)": {
            width: "100%",
          },
        }}
      >
        {isVendorLoading ? (
          <LoadingSkeleton />
        ) : (
          <StoreFront vendor={vendor} handleTabChange={handleTabChange} />
        )}
      </Box>
      <Box
        sx={{
          "@media (max-width: 800px)": {
            width: "100%",
          },
        }}
      >
        {isVendorLoading ? <LoadingSkeleton /> : <ContactInfo vendor={vendor} />}
      </Box>
    </Box>
  );
};

export default ContactColumn;
