import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import VendorLogo from "custom/img/VendorLogo";

import styles from "./styles.module.scss";

const CartItemsTotal = ({ displayTotal, isSelected, quantity }) => {

  if (!displayTotal) {
    return (
      <Skeleton variant="circular">
        <Box
          sx={{
            width: "26px",
            height: "26px",
            borderRadius: "26px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className={isSelected ? "bg-blueblue" : "bg-grayscaleSmoke"}
        >
          <Typography className="text-grayscaleWhite" sx={{fontSize: "0.85em" }}>
            {quantity}
          </Typography>
        </Box>
      </Skeleton>
    );
  }

  return (
    <Box
      sx={{
        width: "26px",
        height: "26px",
        borderRadius: "26px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className={isSelected ? "bg-blueblue" : "bg-grayscaleSmoke"}
    >
      <Typography className="text-grayscaleWhite" sx={{ fontSize: "0.85em" }}>
        {quantity}
      </Typography>
    </Box>
  );
};

export default function CartFilter({
  filterCode,
  selectedFilter,
  setSelectedFilter,
  vendorQuantityTotals,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  let displayTotal = false;
  const vendorTotalPresent =
    Object.keys(vendorQuantityTotals).includes(filterCode);
  if (vendorTotalPresent) {
    displayTotal = true;
  }

  useEffect(() => {
    if (searchParams.get("distributor") != null) {
      setSelectedFilter(searchParams.get("distributor"));
    } else {
      setSelectedFilter("All Carts");
    }
  }, [searchParams]);

  // useSearchParams as opposed to useNavigate
  const onSelectFilter = () => {
    if (filterCode === 'All Carts') {
      setSelectedFilter(filterCode);
      setSearchParams();
    } else {
      setSelectedFilter(filterCode);
      setSearchParams({ distributor: filterCode });
    }
  };

  return (
    <div
      className={styles.cartFilterWrapper}
      data-selected={filterCode === selectedFilter}
      onClick={onSelectFilter}
    >
      <div
        className={styles.cartFilter}
        data-selected={filterCode === selectedFilter}
      >
        {filterCode === 'All Carts' ? (<Typography>All Carts</Typography>) : (
          <VendorLogo vendorCode={filterCode} />
        )}
        
        <CartItemsTotal
          displayTotal={displayTotal}
          isSelected={filterCode === selectedFilter}
          quantity={vendorQuantityTotals[filterCode]}
        />
      </div>
    </div>
  );
}
