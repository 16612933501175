import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Notifications() {
  const navigate = useNavigate();

  const notifications = [
    {
      id: 1,
      text: (
        <>
          <strong>New Supplier:</strong> VERTEX Apparel is now available on DGI Direct, offering four popular styles at incredible prices. Free shipping on orders over $200!{" "}
          <Link
            component="button"
            color="primary"
            onClick={() => navigate('/direct/vendors/vertex')}
          >Shop VERTEX here
          </Link>
        </>
      ),
      borderColor: "#3357FF", // Blue
    },
    // {
    //   id: 2,
    //   text: (
    //     <>
    //       Thanksgiving: Most vendor warehouses will be closed Thursday, 11/28 for Thanksgiving and have limited capacity on Friday 11/29. Please place all orders before the standard cut-off time on Wednesday, 11/27 to ensure your orders are shipped before the holiday.{" "}
    //     </>
    //   ),
    //   borderColor: "#33FF57", // Green
    // },
    // {
    //   id: 3,
    //   text: (
    //     <>
    //       <strong>New Feature:</strong> We've added a new reporting dashboard!{" "}
    //       <Link
    //         component="button"
    //         color="primary"
    //         onClick={() => navigate('/order-history')}
    //       >
    //         Check it out here!
    //       </Link>
    //     </>
    //   ),
    //   borderColor: "#3357FF", // Blue
    // },
    // {
    //   id: 4,
    //   text: (
    //     <>
    //       <strong>Upcoming Event:</strong> Don't miss the next vendor integration webinar!{" "}
    //       <Link
    //         component="button"
    //         color="primary"
    //         onClick={() => navigate('/integrations')}
    //       >
    //         Register here!
    //       </Link>
    //     </>
    //   ),
    //   borderColor: "#FFD700", // Yellow
    // },
  ];

  return (
    <Box>
      {notifications.map((notification) => (
        <Box
          key={notification.id}
          sx={{
            borderLeft: `5px solid ${notification.borderColor}`,
            padding: "0.5rem",
            display: "flex",
            alignItems: "center",
            mb: "0.3rem",
          }}
        >
          <Typography variant="body1" sx={{ flexGrow: 1, fontSize: "13.5px" }}>
            {notification.text}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
