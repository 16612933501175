import React from "react";
import { Typography, Box, Stack, Divider } from "@mui/material";

function DirectCartHeader({ vendorCode }) {
  const CLOUDFRONT_URL = "https://d34c0c1in94n75.cloudfront.net/";
  const vendorLogo = `${CLOUDFRONT_URL}${vendorCode}_logo.png`;
  return (
    <Box
      sx={{
        width: "100%",
        p: 1,
        px: 2,
        display: "flex",
        alignItems: "center",
      }}
      className="bg-grayscaleIce"
    >
      <img
        src={vendorLogo}
        alt={null}
        style={{
          display: "block",
          height: "26px",
          width: "auto",
        }}
      />
    </Box>
  );
}

const getFormattedPrices = (totalPrice, unitPrice, quantity) => {
  const stripNumber = (val) => {
    if (Number(val)) {
      return val;
    }
    return parseFloat(val.replace(/[^\d.-]/g, ""));
  };

  const totalPriceFigure = parseFloat(
    totalPrice || stripNumber(unitPrice) * stripNumber(quantity),
  ).toFixed(2);

  const formattedTotalPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(totalPriceFigure);
  const formattedUnitPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(unitPrice);
  return [formattedTotalPrice, formattedUnitPrice];
};

function OrderInfo({ unitPrice, warehouse, quantity, size, totalPrice }) {
  const [formattedTotalPrice, formattedUnitPrice] = getFormattedPrices(
    totalPrice,
    unitPrice,
    quantity,
  );
  const showPriceBreakdown = !(unitPrice === totalPrice);
  const unitPriceBreakdown =
    showPriceBreakdown && `(${formattedUnitPrice} / Each)`;
  const itemSpend = `${formattedTotalPrice} ${unitPriceBreakdown}`;

  return (
    <Stack spacing={1}>
      {size && (
        <Typography className="flex items-center">
          <span className="w-[120px] text-left text-sm">Size:</span>
          <span className="font-semibold text-sm min-w-[120px]">{size}</span>
        </Typography>
      )}
      <Typography className="flex items-center">
        <span className="w-[120px] text-left text-sm">Quantity:</span>
        <span className="font-semibold text-sm min-w-[120px]">{quantity}</span>
      </Typography>
      <Typography className="flex items-center">
        <span className="w-[120px] text-left text-sm">Total spend:</span>
        <span className="font-semibold text-sm min-w-[120px]">{itemSpend}</span>
      </Typography>
      <Typography className="flex items-center">
        <span className="w-[120px] text-left text-sm">Warehouse:</span>
        <span className="font-semibold text-sm min-w-[120px]">{warehouse}</span>
      </Typography>
    </Stack>
  );
}

function CartItemInfo({
  itemStyle,
  description,
  unitPrice,
  warehouse,
  quantity,
  size,
  totalPrice,
  masterColor,
  showProductInfo = true,
}) {
  return (
    <Box>
      <Stack direction="column" spacing={3}>
        {showProductInfo && (
          <Box>
            <Typography className="text-lg font-semibold">
              {itemStyle}
            </Typography>
            <Typography className="text-base">{description}</Typography>
            {masterColor && (
              <Typography className="text-sm">
                <span className="text-fadedGray text-sm">Color:</span>{" "}
                {masterColor}
              </Typography>
            )}
          </Box>
        )}
        <OrderInfo
          unitPrice={unitPrice}
          warehouse={warehouse}
          quantity={quantity}
          size={size}
          totalPrice={totalPrice}
        />
      </Stack>
    </Box>
  );
}

function DirectCartItems({ items }) {
  const groupedItems = items.reduce((acc, item) => {
    const key = `${item.style}-${item?.master_color || "no-color"}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  return (
    <Box className="flex flex-col gap-[28px]" sx={{ p: 2, pb: 2.75 }}>
      {Object.values(groupedItems).map((group, groupIndex) => (
        <>
          {groupIndex > 0 && <Divider className="my-[16px]" />}
          <Box className="flex flex-wrap items-start gap-[64px]">
            <Box
              sx={{ width: "220px", display: "flex", alignItems: "flex-start" }}
            >
              <img
                src={group[0]?.product_image_url}
                style={{
                  display: "block",
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
            <Stack spacing={5}>
              {group.map((item, itemIndex) => (
                <CartItemInfo
                  key={itemIndex}
                  itemStyle={item.style}
                  description={item.description}
                  unitPrice={item.unitPrice}
                  warehouse={item.warehouse}
                  quantity={item.quantity}
                  size={item?.size}
                  totalPrice={item.totalPrice}
                  masterColor={item?.master_color}
                  showProductInfo={itemIndex === 0}
                />
              ))}
            </Stack>
          </Box>
        </>
      ))}
    </Box>
  );
}

function DirectCarts({ vendors }) {
  return (
    <div>
      {vendors?.map((v) => (
        <Box className="flex flex-col gap-[28px]">
          <DirectCartHeader vendorCode={v.directVendor} />
          <DirectCartItems items={v.items} />
        </Box>
      ))}
    </div>
  );
}

export default DirectCarts;
