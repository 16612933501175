import React from 'react';
import { Box, Typography } from '@mui/material';

const DirectVendorsHeader = () => {
  return (
    <Box pt={8} pb={10} textAlign="center">
      <img
        src="https://d34c0c1in94n75.cloudfront.net/direct_logo_full_orange_arrow.png"
        alt="Direct Vendors Logo"
        style={{ width: '100%', maxWidth: '740px', height: 'auto' }}
      />
      <Typography variant="h6" color="textSecondary" mt={4}>
        Discover and order from independent vendors who list their products directly on DGI.
      </Typography>
    </Box>
  );
};

export default DirectVendorsHeader;
