import React from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import { useGetVendorByCodeQuery } from "features/baseApi";

export function RenderSupplierRating({
  vendorInfo,
  showReviewCount = true,
  starSize = "medium", // Customizable star size
  reviewTextSize = "0.875rem", // Default size for review count text
  handleTabChange=null
}) {
  const reviews = vendorInfo?.reviews ? Object.values(vendorInfo.reviews) : [];
  const reviewCount = reviews.length;
  const averageRating =
    reviewCount > 0
      ? reviews.reduce((sum, review) => sum + Number(review.stars), 0) /
        reviewCount
      : 0;
  const roundedRating = Math.round(averageRating * 2) / 2;

  const renderStars = () => {
    const stars = [];
    let remainingRating = roundedRating;

    for (let i = 0; i < 5; i++) {
      if (remainingRating >= 1) {
        stars.push(
          <StarRoundedIcon
            key={i}
            sx={{
              color: "#FFA552",
              fontSize: typeof starSize === "string" ? starSize : `${starSize}px`, // Use starSize prop
            }}
          />
        );
        remainingRating -= 1;
      } else if (remainingRating === 0.5) {
        stars.push(
          <StarHalfRoundedIcon
            key={i}
            sx={{
              color: "#FFA552",
              fontSize: typeof starSize === "string" ? starSize : `${starSize}px`, // Use starSize prop
            }}
          />
        );
        remainingRating -= 0.5;
      } else {
        stars.push(
          <StarOutlineRoundedIcon
            key={i}
            sx={{
              color: "#FFA552",
              fontSize: typeof starSize === "string" ? starSize : `${starSize}px`, // Use starSize prop
            }}
          />
        );
      }
    }

    return stars;
  };

  return (
    <Box display="flex" alignItems="center">
      {renderStars()}
      {showReviewCount && (
        <Typography
          variant="body2"
          sx={{
            marginLeft: 0.5,
            color: "#676F78",
            fontSize: reviewTextSize,
          }}
         onClick={(e) => {
          if (handleTabChange) {
            handleTabChange(e, 'about')
          } 
         }}
        >
          (
          <span
            style={{
              textDecoration: "none",
              color: "#676F78",
              cursor: "pointer",
            }}
            onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
          >
            {reviewCount === 0
              ? "0 reviews"
              : reviewCount === 1
              ? "1 review"
              : `${reviewCount} reviews`}
          </span>
          )
        </Typography>
      )}
    </Box>
  );
}

export default function SupplierRating({ vendorInfo, showReviewCount = true }) {
  const {
    data: vendorData,
    error,
    isLoading,
  } = useGetVendorByCodeQuery(vendorInfo.code);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width={150}
        height={20}
        animation="wave"
        sx={{ borderRadius: 1 }}
      />
    );
  }

  if (error) {
    return (
      <Typography color="error" variant="body2">
        Error loading rating
      </Typography>
    );
  }

  return (
    <RenderSupplierRating vendorInfo={vendorData} showReviewCount />
  );
}
