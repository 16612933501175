import React, { useState } from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import ColorInventoryGroup from "./ColorInventoryGroup";
import ProductDetailsSection from "./ProductDetailsSection";
import { useProductContext } from "./ProductPage";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { createSelectVendorProducts } from "features/productsSlice";
import { useParams, useSearchParams } from "react-router-dom";

const ProductPageContent = ({
  sendJsonMessage,
  unsortedDisplayVendors,
  displaySuggestedVendors,
  vendorCodes,
  unconnectedVendorCodes,
  styleData,
}) => {
  const isLGOrAbove = useMediaQuery("(min-width:1250px)");
  const { loadingProductData, vendorFields, colorObj } = useProductContext();
  const [selectedVendorCode, setSelectedVendorCode] = useState("");
  const { dgiStyle } = useParams();
  const [searchParams] = useSearchParams();
  const colorQuery = searchParams.get("color");
  const selectVendorProducts = createSelectVendorProducts(
    dgiStyle,
    vendorCodes,
    colorObj?.name,
  );
  const vendorProducts = useSelector(selectVendorProducts);
  const isDirectProduct = styleData?.direct;

  const selectedProductKey = dgiStyle + selectedVendorCode + colorObj?.name;
  const vendorProduct = vendorProducts[selectedProductKey];
  const smExclusive = vendorCodes?.length === 1 && vendorCodes[0] === "sm";

  function getVendorLowestPrice(vendorCode) {
    const productKey = dgiStyle + vendorCode + colorObj.name;
    const vendorPrice =
      vendorProducts && vendorProducts[productKey]
        ? vendorProducts[productKey].data?.product_price
        : null;
    if (!vendorPrice) {
      return Infinity;
    }
    return vendorPrice;
  }

  function sortVendorsByPrice(vendorA, vendorB) {
    const vendorAKey = vendorA["code"];
    const vendorBKey = vendorB["code"];
    const vendorALowestPrice = getVendorLowestPrice(vendorAKey);
    const vendorBLowestPrice = getVendorLowestPrice(vendorBKey);
    if (vendorALowestPrice < vendorBLowestPrice) {
      return -1;
    }
    if (vendorALowestPrice > vendorBLowestPrice) {
      return 1;
    }
    return 0;
  }

  // recreating a field we use in Product that is then passed to InventoryDetails
  let displayVendors = [];
  // we pass this down as a prop to evaluate whether we need a selector for the inventory table
  const hasColors = Boolean(styleData?.colors?.length);
  if (vendorFields && hasColors && colorObj) {
    // for apparel items
    displayVendors = unsortedDisplayVendors.sort(sortVendorsByPrice);
  } else if (vendorFields && !hasColors) {
    // for hard goods with no color
    displayVendors = unsortedDisplayVendors;
  }

  if (loadingProductData || !styleData) {
    return (
      <div className="flex flex-col gap-[20px] justify-center items-center w-full mt-[85px]">
        <CircularProgress size="80px" />
        <div className="text-[#313131] text-lg">
          <span>Fetching product information...</span>
        </div>
      </div>
    );
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {isLGOrAbove ? (
        <Box display="flex" flexDirection="row" width="auto" gap="30px">
          <ProductDetailsSection
            selectedVendorCode={selectedVendorCode}
            vendorProduct={vendorProduct}
            smExclusive={smExclusive}
            isLGOrAbove={isLGOrAbove}
            isDirectProduct={isDirectProduct}
          />
          <ColorInventoryGroup
            selectedVendorCode={selectedVendorCode}
            setSelectedVendorCode={setSelectedVendorCode}
            displayVendors={displayVendors}
            displaySuggestedVendors={displaySuggestedVendors}
            vendorProducts={vendorProducts}
            dgiStyle={dgiStyle}
            colorQuery={colorQuery}
            sendJsonMessage={sendJsonMessage}
            unconnectedVendorCodes={unconnectedVendorCodes}
            hasColors={hasColors}
            isDirectProduct={isDirectProduct}
            isLGOrAbove={isLGOrAbove}
          />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" gap="2rem" alignItems="center">
          <ProductDetailsSection
            selectedVendorCode={selectedVendorCode}
            vendorProduct={vendorProduct}
            isLGOrAbove={isLGOrAbove}
          />
          <ColorInventoryGroup
            selectedVendorCode={selectedVendorCode}
            setSelectedVendorCode={setSelectedVendorCode}
            displayVendors={displayVendors}
            displaySuggestedVendors={displaySuggestedVendors}
            vendorProducts={vendorProducts}
            dgiStyle={dgiStyle}
            colorQuery={colorQuery}
            sendJsonMessage={sendJsonMessage}
            unconnectedVendorCodes={unconnectedVendorCodes}
            hasColors={hasColors}
            isDirectProduct={isDirectProduct}
            isLGOrAbove={isLGOrAbove}
          />
        </Box>
      )}
    </Box>
  );
};

export default ProductPageContent;
