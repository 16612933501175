import React from "react";
import { Popper, Paper } from "@mui/material";
import { Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import SearchIcon from "@mui/icons-material/Search";
import { usePostHog } from "posthog-js/react";

const SearchResultOption = ({ option, index, selectedIndex, submitQuery, itemRef }) => {
    if (!option) return null;
    
    const isSelected = index === selectedIndex;
    const parts = option.split(/(<mark>|<\/mark>)/).filter(Boolean);
  
    return (
      <div
        ref={itemRef}
        className={`flex flex-col gap-[8px] font-bold py-[8px]
          hover:bg-grayscaleIce hover:cursor-pointer w-full
          ${isSelected ? 'bg-grayscaleIce' : ''}`}
        onClick={() => submitQuery(option.replace(/<\/?mark>/g, ''))}
      >
        <div className="pl-[12px] flex items-center">
          <div className="pl-[2.66rem]">
            {parts.map((part, idx) =>
              part === '<mark>' || part === '</mark>' ? '' : (
                <span key={idx} className={parts[idx - 1] === '<mark>' ? "font-bold" : "font-normal"}>
                  {part}
                </span>
              )
            )}
          </div>
        </div>
      </div>
    );
  };

  const SearchSection = ({ 
    type, 
    show, 
    items, 
    baseIndex, 
    selectedIndex, 
    usingDefaultFavorites, 
    firstName, 
    submitQuery,
    itemRefs 
  }) => {
    const posthog = usePostHog();
    if (!show) return null;

  const sectionConfig =
    {
      new: {
        icon: (
          <AddIcon
            style={{
              color: "#FFA552",
              paddingBottom: "2px",
              fontSize: "1.7rem",
            }}
          />
        ),
        title: "New on DGI",
      },
      favorites: {
        icon: (
          <WhatshotIcon
            style={{
              color: "#FFA552",
              paddingBottom: "2px",
              fontSize: "1.7rem",
            }}
          />
        ),
        title: usingDefaultFavorites
          ? "Trending"
          : firstName
          ? `${firstName}'s Favorites`
          : "Your Favorites",
      },
      suggested: {
        icon: (
          <SearchIcon
            style={{
              color: "#FFA552",
              paddingBottom: "2px",
              fontSize: "1.8rem",
            }}
          />
        ),
        title: "Suggested",
      },
    }[type] || {};

  const handleSubmitQuery = (value) => {
    const searchingNewRecs = sectionConfig.title === "New on DGI";
    if (searchingNewRecs) {
      // understanding entry point for direct shoppers
      posthog?.capture("shopping_by_brand_or_vendor", {
        vendor: value,
        search_location: "new_in_searchbar",
      })
    }
    submitQuery(value);
  }

  return (
    <>
      <div className="pl-[12px] flex items-center pt-[12px] pb-[2px]">
        {sectionConfig.icon}
        <div className="pl-[14px] flex items-center w-full">
          <Typography variant="caption" className="text-grayscaleSmoke text-sm">
            {sectionConfig.title}
          </Typography>
          <div className="flex-grow ml-2" />
        </div>
      </div>
      {items.map((option, index) => {
        const actualIndex = baseIndex + index;
        return (
          <SearchResultOption
            key={`${type}-${index}`}
            option={option}
            index={actualIndex}
            selectedIndex={selectedIndex}
            submitQuery={handleSubmitQuery}
            itemRef={(el) => {
              if (itemRefs.current) {
                itemRefs.current[actualIndex] = el;
              }
            }}
          />
        );
      })}
    </>
  );
};

const SearchResults = ({
    showSuggestions,
    menuWidth,
    anchorEl,
    isFocused,
    showNewRecommendations,
    filteredNewRecommendations,
    filteredFavorites,
    autoCompleteSuggestions,
    selectedIndex,
    inputValue,
    usingDefaultFavorites,
    firstName,
    submitQuery,
    itemRefs,
    resultsContainerRef
  }) => {
    if (!showSuggestions) return null;
  
    const sections = [
      {
        type: "new",
        show: showNewRecommendations && filteredNewRecommendations.length > 0,
        items: filteredNewRecommendations,
        baseIndex: 0
      },
      {
        type: "favorites",
        show: filteredFavorites.length > 0,
        items: filteredFavorites,
        baseIndex: filteredNewRecommendations.length
      },
      {
        type: "suggested",
        show: inputValue !== "" && autoCompleteSuggestions.length > 0,
        items: autoCompleteSuggestions,
        baseIndex: filteredNewRecommendations.length + filteredFavorites.length
      }
    ];
  
    return (
      <Popper
        open={isFocused}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ width: menuWidth, zIndex: 1300 }}
      >
        <Paper
          elevation={3}
          style={{
            maxHeight: "400px",
            overflowY: "auto",
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0",
          }}
        >
          <div 
            ref={resultsContainerRef}
            className="max-h-[400px] overflow-y-auto rounded-bl-[25px] rounded-br-[25px]"
          >
            {sections.map(section => (
              <SearchSection
                key={section.type}
                {...section}
                selectedIndex={selectedIndex}
                usingDefaultFavorites={usingDefaultFavorites}
                firstName={firstName}
                submitQuery={submitQuery}
                itemRefs={itemRefs}
              />
            ))}
          </div>
        </Paper>
      </Popper>
    );
  };

  export default SearchResults;