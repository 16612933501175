import React, { useState } from "react";

import styles from "./styles.module.scss";
import {
  RefinementList,
  CurrentRefinements,
  ClearRefinements,
} from "react-instantsearch";
import { facets } from "constants/searchFacets";
import {
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatBrandName } from "utilities/formatBrandName";
import { usePostHog } from "posthog-js/react";

const Facet = ({ facet, index }) => {
  const posthog = usePostHog();
  const [displayFacet, setDisplayFacet] = useState(true);

  const filterOperator =
    facet[0] === "master_category_tags" || facet[0] === "master_size_list"
      ? "and"
      : "or";

  const handleFilterEvent = (itemLabel) => {
    // for understanding entry point for direct shoppers
    posthog?.capture("shopping_by_brand_or_vendor", {
      vendor: itemLabel,
      search_location: "refinement_lists",
    });
  };

  let transformItems = (items) => {
    const formatItemCount = (count) => "(" + count + ")";

    const filteredItems = items.filter((item) => item.label !== "Prime Line");

    return filteredItems.map((item) => ({
      ...item,
      label: formatBrandName(item.label),
      count: formatItemCount(item.count),
    }));
  };

  if (facet[0] === "master_size_list") {
    // items in sizeOrder always come first and maintain their defined order,
    // while the remaining items are sorted by their count from highest to lowest.
    const sizeOrder = [
      "6XL",
      "5XL",
      "4XL",
      "XS",
      "XXS",
      "3XLT",
      "2XLT",
      "XLT",
      "LT",
      "MT",
    ];
    const sizeSet = new Set(sizeOrder);
    transformItems = (items) => {
      const formatItemCount = (count) => `(${count})`;
      return items
        .sort((a, b) => {
          const aIndex = sizeOrder.indexOf(a.label);
          const bIndex = sizeOrder.indexOf(b.label);
          if (aIndex !== -1 && bIndex !== -1) return aIndex - bIndex;
          if (aIndex !== -1) return -1;
          if (bIndex !== -1) return 1;
          // Sort by count in descending order for non-sizeOrder items
          return b.count - a.count;
        })
        .map((item) => ({
          ...item,
          label: sizeSet.has(item.label)
            ? item.label
            : formatBrandName(item.label),
          count: formatItemCount(item.count),
        }));
    };
  }
  return (
    <Accordion
      expanded={displayFacet}
      sx={{
        backgroundColor: "transparent",
        margin: "0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        paddingTop: (index === 0 || displayFacet) ?? "1.25rem",
        "&::before": {
          display: "none",
        },
      }}
      elevation={0}
      className={styles.facetAccordion}
      disableGutters={true}
    >
      <AccordionSummary
        onClick={() => setDisplayFacet(!displayFacet)}
        expandIcon={<ExpandMoreIcon className="text-charcoal" />}
        aria-controls={`panel-${facet[1]}-content`}
        id={`panel-${facet[1]}-header`}
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "28px",
          px: "0",
          maxHeight: "20px",
          width: "170px",
        }}
        className={styles.facetAccordionSummary}
      >
        <Typography className="text-charcoal" sx={{ fontWeight: "600" }}>
          {facet[1]}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        useflexgap
        sx={{
          marginTop: "0px",
          padding: "0px 0px 4px",
        }}
      >
        <RefinementList
          attribute={facet[0]}
          sortBy={["count:desc"]}
          operator={filterOperator}
          limit={200}
          classNames={{
            root: "flex flex-col gap-12 items-center w-[177px]",
            list: "list-none pl-0 hover:scroll-auto max-h-[200px] overflow-y-scroll",
            item: "mb-2",
            label: "flex gap-3",
            labelText: "text-sm w-110 truncate",
            count: "text-sm",
            searchBox: "w-[170px] focus:outline-blueblue",
            checkbox: "accent-blueblue hover:accent-denim",
          }}
          transformItems={transformItems}
          searchable={true}
          searchablePlaceholder={`Search ${facet[1].toLowerCase()}...`}
          className={styles.refinementList}
          onClick={(e) => {
            if (facet[0] === "distributors" || facet[0] === "master_brand") {
              const refinementValue = e.target.value;
              handleFilterEvent(refinementValue);
            }
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

function RefinementLists() {
  const transformRefinementItems = (items) => {
    const labelLookup = {
      master_brand: "Brands",
      master_category_tags: "Categories",
      master_size_list: "Sizes",
      distributors: "Vendors",
      "colors.name": "Colors",
    };
    return items.map((item) => {
      return {
        ...item,
        label: labelLookup[item.attribute] || item.attribute,
      };
    });
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      spacing={2}
      sx={{
        width: "auto",
        gap: "0px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "left",
          justifyContent: "flex-start",
        }}
      >
        <ClearRefinements
          translations={{
            resetButtonText: "Clear all filters",
          }}
          classNames={{
            disabledButton: "hidden",
            root: "py-0 my-0 text-blue",
            button: `border-none bg-transparent cursor-pointer p-0 pl-[8px] pb-[4px] text-blueblue text-sm`,
          }}
          style={{ color: "blue-200" }}
        />
      </Box>
      <CurrentRefinements
        classNames={{
          item: `w-[177px] p-[8px] mb-[8px] 
            gap-quarter flex flex-wrap items-center list-none 
            p-0 m-0 border border-solid border-[#d2d6da] rounded-soft`,
          label: "text-xs font-bold text-charcoal",
          category: `flex justify-center items-center bg-grayscaleIce
            text-xs pl-[4px] border border-solid border-[#d2d6da] rounded-soft cursor-default`,
          categoryLabel: "text-blueblue text-almostSM bg-grayscaleIce",
          delete:
            "text-xs border-none text-blueblue bg-grayscaleIce cursor-pointer",
          list: "gap-[8px] m-0 py-0 px-[0px]",
          root: "mt-0-important pt-0",
        }}
        transformItems={transformRefinementItems}
      />
      <Box
        sx={{
          marginTop: "0px !important",
        }}
      >
        {Object.entries(facets).map((facet, index) => (
          <Facet key={facet[0]} facet={facet} index={index} />
        ))}
      </Box>
    </Stack>
  );
}

export default RefinementLists;
