import React from "react";
import { Box, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

const DirectButton = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/direct/vendors");
  };

  return (
    <Box
      onClick={handleNavigate}
      mt={2}
      px={3}
      py={4}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      border="1px solid #ddd"
      borderRadius="5px"
      bgcolor="#f9f9f9"
      sx={{
        cursor: "pointer",
        "&:hover": { 
          borderColor: "rgba(0, 110, 165, 1)",
          "& .MuiSvgIcon-root": { color: "rgba(0, 110, 165, 1)" }
        },
        width: "95%",
        flexDirection: { xs: "column", sm: "row" },  // Adjust layout for responsiveness
        textAlign: { xs: "center", sm: "left" },  // Center text on smaller screens
      }}
    >
      {/* Logo on the left */}
      <Box display="flex" alignItems="center" mr={{ xs: 0, sm: 4 }} mb={{ xs: 2, sm: 0 }}>
        <img
          src="https://d34c0c1in94n75.cloudfront.net/direct_logo_blue_base_orange_arrow.png"
          alt="DGI Direct Logo"
          style={{ width: "160px", height: "auto" }}
        />
      </Box>

      {/* Text content */}
      <Box flex="1">
        <Typography variant="subtitle1" fontWeight="bold">
          View all vendors that list their products directly on DGI
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Discover exclusive products and deals from our direct suppliers.
        </Typography>
      </Box>

      {/* Arrow icon */}
      <ArrowForwardIosIcon color="action" sx={{ transition: "color 0.3s", mt: { xs: 2, sm: 0 }, ml: 1 }} />
    </Box>
  );
};

export default DirectButton;
