import React from "react";
import { Box, Typography, Link } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LanguageIcon from "@mui/icons-material/Language";

const ContactInfo = ({ vendor }) => {
  const { contactName, contactRole, email, phone, address, socialLinks } =
    vendor;
  const websiteLink = socialLinks?.websiteLink;

  return (
    <Box
      sx={{
        p: 2,
        border: "1px solid #d2d6da",
        borderRadius: "5px",
        backgroundColor: "#fff",
      }}
    >
      <Box
        sx={{
          pb: 0,
          mb: 2,
          borderBottom: "1px solid #d2d6da",
        }}
      >
        <Typography variant="h6">Contact {vendor.vendorName}</Typography>
      </Box>

      {contactName && (
        <Box display="flex" alignItems="flex-start" sx={{ mb: 2 }}>
          <PersonIcon fontSize="small" sx={{ color: "#676F78", mr: 1.5 }} />
          <Box>
            <Typography variant="body2" fontWeight="bold">
              Name
            </Typography>
            <Typography variant="body2">{`${contactName}, ${contactRole}`}</Typography>
          </Box>
        </Box>
      )}

      {/* Email */}
      {email && (
        <Box display="flex" alignItems="flex-start" sx={{ mb: 2 }}>
          <EmailIcon fontSize="small" sx={{ color: "#676F78", mr: 1.5 }} />
          <Box>
            <Typography variant="body2" fontWeight="bold">
              Email
            </Typography>
            <Typography variant="body2">{email}</Typography>
          </Box>
        </Box>
      )}

      {/* Phone Number */}
      {phone && (
        <Box display="flex" alignItems="flex-start" sx={{ mb: 2 }}>
          <PhoneIcon fontSize="small" sx={{ color: "#676F78", mr: 1.5 }} />
          <Box>
            <Typography variant="body2" fontWeight="bold">
              Phone Number
            </Typography>
            <Typography variant="body2">{phone}</Typography>
          </Box>
        </Box>
      )}

      {/* Address */}
      {address && (
        <Box display="flex" alignItems="flex-start" sx={{ mb: 2 }}>
          <LocationOnIcon fontSize="small" sx={{ color: "#676F78", mr: 1.5 }} />
          <Box>
            <Typography variant="body2" fontWeight="bold">
              Address
            </Typography>
            <Typography variant="body2">
              {address.street1}
              {address.street2 && <>, {address.street2}</>}
              <br />
              {address.city}, {address.state} {address.zip}
            </Typography>
          </Box>
        </Box>
      )}

      {/* Website Link */}
      {websiteLink && (
        <Box display="flex" alignItems="flex-start" sx={{ mb: 2 }}>
          <LanguageIcon fontSize="small" sx={{ color: "#676F78", mr: 1.5 }} />
          <Box>
            <Typography variant="body2" fontWeight="bold">
              Website
            </Typography>
            <Link
              href={websiteLink}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: "rgba(0, 88, 141, 1)",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {vendor.vendorName}
            </Link>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ContactInfo;
