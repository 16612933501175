import React, { useState, useEffect } from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import WriteReview from "../../integrations/WriteReviews";

const LoadingSkeleton = () => (
  <Box sx={{ p: 2, pt: 6 }}>
    <Box
      display="flex"
      justifyContent="center"
      sx={{ marginBottom: 4, marginTop: 1 }}
    >
      <Skeleton
        variant="rectangular"
        width={400}
        height={100}
        className="rounded-[5px]"
      />
    </Box>

    <Box sx={{ marginTop: 2 }}>
      <Typography variant="body2" sx={{ fontWeight: "bold", marginBottom: 1 }}>
        About:
      </Typography>
      <Skeleton variant="text" width="100%" height={20} />
      <Skeleton variant="text" width="90%" height={20} />
      <Skeleton variant="text" width="95%" height={20} />
    </Box>

    <Box
      sx={{
        borderBottom: "1px dashed",
        borderColor: "text.secondary",
        marginTop: 1,
        marginBottom: 2,
      }}
    />

    <Box sx={{ marginTop: 2 }}>
      <Typography variant="body2" sx={{ fontWeight: "bold", marginBottom: 1 }}>
        Warehouses:
      </Typography>
      <Box display="flex" alignItems="center" sx={{ marginBottom: 0.5 }}>
        <LocationOnOutlinedIcon
          fontSize="small"
          sx={{ color: "rgba(0, 110, 165, 1)", marginRight: 1 }}
        />
        <Skeleton variant="text" width={200} height={24} />
      </Box>
    </Box>

    <Box
      sx={{
        borderBottom: "1px dashed",
        borderColor: "text.secondary",
        marginTop: 1,
        marginBottom: 2,
      }}
    />

    <Box sx={{ marginTop: 2 }}>
      <Typography variant="body2" sx={{ fontWeight: "bold", marginBottom: 1 }}>
        Brands:
      </Typography>
      <Skeleton variant="text" width="100%" height={24} />
    </Box>

    <Box
      sx={{
        borderBottom: "1px dashed",
        borderColor: "text.secondary",
        marginTop: 1,
        marginBottom: 2,
      }}
    />

    <Box sx={{ marginTop: 2 }}>
      <Typography variant="body2" sx={{ fontWeight: "bold", marginBottom: 1 }}>
        Review This Supplier:
      </Typography>
      <Box display="flex" justifyContent="left">
        <Skeleton variant="rectangular" width={150} height={36} />
      </Box>
    </Box>

    <Box
      sx={{
        borderBottom: "1px dashed",
        borderColor: "text.secondary",
        marginTop: 1,
        marginBottom: 2,
      }}
    />

    <Box sx={{ marginTop: 2 }}>
      <Typography variant="body2" sx={{ fontWeight: "bold", marginBottom: 1 }}>
        Reviews:
      </Typography>
      {[1, 2].map((index) => (
        <Box key={index} sx={{ marginBottom: 2.5 }}>
          <Box display="flex" alignItems="center">
            <Skeleton variant="text" width={120} height={24} />
          </Box>
          <Skeleton variant="text" width="100%" height={20} />
          <Skeleton variant="text" width="95%" height={20} />
          <Skeleton
            variant="text"
            width="40%"
            height={16}
            sx={{ marginTop: 0.5 }}
          />
        </Box>
      ))}
    </Box>
  </Box>
);

const About = ({ vendor, isVendorLoading }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    if (vendor?.vendorCode) {
      // Reset states when vendor code changes
      setImageLoaded(false);
      setImageError(false);

      const img = new Image();
      img.src = `https://d34c0c1in94n75.cloudfront.net/${vendor.vendorCode}_logo.png`;

      img.onload = () => {
        setImageLoaded(true);
      };

      img.onerror = () => {
        setImageError(true);
      };

      return () => {
        img.onload = null;
        img.onerror = null;
      };
    }
  }, [vendor?.vendorCode]);

  const renderStars = (rating) => {
    const roundedRating = Math.round(rating * 2) / 2;
    const stars = [];
    let remainingRating = roundedRating;

    for (let i = 0; i < 5; i++) {
      if (remainingRating >= 1) {
        stars.push(
          <StarRoundedIcon
            key={i}
            fontSize="medium"
            sx={{ color: "#FFA552" }}
          />
        );
        remainingRating -= 1;
      } else if (remainingRating === 0.5) {
        stars.push(
          <StarHalfRoundedIcon
            key={i}
            fontSize="medium"
            sx={{ color: "#FFA552" }}
          />
        );
        remainingRating -= 0.5;
      } else {
        stars.push(
          <StarOutlineRoundedIcon
            key={i}
            fontSize="medium"
            sx={{ color: "#FFA552" }}
          />
        );
      }
    }
    return stars;
  };

  if (isVendorLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <Box sx={{ p: 2, pt: 6 }}>
      <Box
        display="flex"
        justifyContent="center"
        sx={{ marginBottom: 4, marginTop: 1 }}
      >
        {!imageLoaded ? (
          <Skeleton
            variant="rectangular"
            width={360}
            height={150}
            className="rounded-[5px]"
          />
        ) : imageError ? (
          <Box
            sx={{
              width: 360,
              height: 150,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "grey.100",
              borderRadius: "5px",
            }}
          >
            <Typography color="text.secondary">Logo not available</Typography>
          </Box>
        ) : (
          <img
            src={`https://d34c0c1in94n75.cloudfront.net/${vendor?.vendorCode}_logo.png`}
            alt={`${vendor?.vendorName} logo`}
            style={{
              maxWidth: 360,
              opacity: imageLoaded ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
            }}
          />
        )}
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", marginBottom: 1 }}
        >
          About:
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {vendor?.about || "Information not available."}
        </Typography>
      </Box>
      <Box
        sx={{
          borderBottom: "1px dashed",
          borderColor: "text.secondary",
          marginTop: 1,
          marginBottom: 2,
        }}
      />
      <Box sx={{ marginTop: 2 }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", marginBottom: 1 }}
        >
          Warehouses:
        </Typography>
        <Box>
          {vendor?.warehouses?.map((location, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              sx={{ marginBottom: 0.5 }}
            >
              <LocationOnOutlinedIcon
                fontSize="small"
                sx={{ color: "rgba(0, 110, 165, 1)", marginRight: 1 }}
              />
              <Typography variant="body2" color="textSecondary">
                {location}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          borderBottom: "1px dashed",
          borderColor: "text.secondary",
          marginTop: 1,
          marginBottom: 2,
        }}
      />
      <Box sx={{ marginTop: 2 }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", marginBottom: 1 }}
        >
          Brands:
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {vendor?.brands?.join(", ") || "No brands available."}
        </Typography>
      </Box>
      <Box
        sx={{
          borderBottom: "1px dashed",
          borderColor: "text.secondary",
          marginTop: 1,
          marginBottom: 2,
        }}
      />
      <Box sx={{ marginTop: 2 }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", marginBottom: 1 }}
        >
          Review This Supplier:
        </Typography>
        <Box display="flex" justifyContent="left">
          <WriteReview vendorCode={vendor?.vendorCode} />
        </Box>
      </Box>
      <Box
        sx={{
          borderBottom: "1px dashed",
          borderColor: "text.secondary",
          marginTop: 1,
          marginBottom: 2,
        }}
      />
      <Box sx={{ marginTop: 2 }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", marginBottom: 1 }}
        >
          Reviews:
        </Typography>
        <Box>
          {vendor?.reviews ? (
            Object.entries(vendor.reviews).map(([userId, review]) => (
              <Box key={review.reviewId} sx={{ marginBottom: 2.5 }}>
                <Box display="flex" alignItems="center">
                  {renderStars(review.stars)}
                </Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    marginTop: 0.5,
                    whiteSpace: "pre-line",
                  }}
                >
                  {review.writeUp}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  Reviewed by: {review.first_name || "Anonymous"}
                </Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              No reviews available.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default About;
