import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Skeleton } from "@mui/material";
import { typesenseClient } from "App";
import { constructImageUrl } from "utilities/imageUrlUtils";
import { useNavigate } from "react-router-dom";
import { buildProductUrl } from "utilities/navigation";
import { typesenseProductsIndexName } from "App";

const ProductCardSkeleton = () => (
  <Box className="flex flex-col items-center gap-[18px] p-[6px]">
    <Box className="h-[230px] w-full">
      <Skeleton
        variant="rectangular"
        width="100%"
        height="100%"
        sx={{
          borderRadius: "3px",
          bgcolor: "rgba(0, 0, 0, 0.08)",
        }}
      />
    </Box>

    <div className="flex flex-col w-[210px]">
      <div className="mb-[5px]">
        <Skeleton width="120px" height={24} />
      </div>
      <Skeleton width="100%" height={24} />
    </div>
  </Box>
);

const ProductCard = ({ product }) => {
  const [imageError, setImageError] = useState(false);
  const navigate = useNavigate();

  const firstColorName = product?.colors?.[0]?.name || "";
  const imageUrl = constructImageUrl(product.dgi_style_sku, firstColorName, 0);
  const productUrl = buildProductUrl(product.dgi_style_sku, firstColorName);

  return (
    <Box
      key={product.master_style}
      className="flex flex-col items-center gap-[18px] cursor-pointer p-[6px]"
      sx={{
        "&:hover": {
          ".detailText": {
            color: "rgba(0, 110, 165, 1)", // blueblue
          },
        },
      }}
      onClick={() => navigate(productUrl)}
    >
      {imageError ? (
        <Box className="flex flex-col justify-center items-center h-[230px] w-auto">
          <Box
            className={
              `flex flex-col items-center justify-center gap-[6px]
              max-h-[210px] min-h-[210px] p-[12px] bg-grayscaleSilver rounded-[4px]`}
          >
            <Typography className="text-5xl text-grayscaleStone">
              DGI
            </Typography>
            <Typography className="text-lg text-grayscaleStone">
              Image coming soon
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          className="
            flex flex-col justify-center 
            h-[230px] w-full bg-white 
            border border-solid border-grayscaleSilver rounded-[3px]"
        >
          <img
            src={imageUrl}
            alt={product.master_short_description}
            onError={() => setImageError(true)}
            style={{
              display: "inline-block",
              height: "auto",
              maxHeight: "100%",
              margin: 0,
              maxWidth: "100%",
              width: "auto",
              objectFit: "contain",
            }}
          />
        </Box>
      )}
      <div className="flex flex-col w-[210px]">
        <div className="mb-[5px]">
          <Typography className="text-base text-denim font-semibold">
            Starting at ${product.catalog_price?.toFixed(2)}
          </Typography>
        </div>
        <Typography className="detailText text-base font-semibold">
          {product.master_style}
        </Typography>
        <Typography className="detailText text-sm">
          {product.master_short_description}
        </Typography>
      </div>
    </Box>
  );
};

const Products = ({ vendor, isVendorLoading }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    (async () => {
      if (!vendor?.vendorName) {
        return;
      }
      const { hits } = await typesenseClient
        .collections(typesenseProductsIndexName)
        .documents()
        .search({
          q: vendor?.vendorName,
          query_by: "master_brand",
          prefix: false,
          typo_tolerance: 0,
          min_len_1typo: 10,
          min_len_2typo: 10,
          per_page: 100,
          sort_by: "popularity:desc",
        });
      setProducts(() => hits.map(({ document }) => document));
    })();
  }, [vendor?.vendorName]);

  if (isVendorLoading) {
    return (
      <Box>
        <Grid container spacing={2} className="mt-[0.5rem]">
          {[...Array(8)].map((_, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={`skeleton-${index}`}
              sx={{ py: 4 }}
            >
              <ProductCardSkeleton />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={2} className="mt-[0.5rem]">
        {products.map((product) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={product.masterStyle}
            sx={{ py: 4 }}
          >
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Products;
