import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.scss";
import { Stack, Dialog, IconButton, useMediaQuery } from "@mui/material";
import Close from "@mui/icons-material/Close";
import directClient from "../axios/directClient";
import ssClient from "../axios/ssClient.js";
import endpoints from "../axios/endpoints.js";
import { vendors } from "../constants/vendors";
import { debounce } from "lodash";
import { usePostHog } from "posthog-js/react";
import { fetchUserSuccess } from "features/userSlice";
import { useDispatch } from "react-redux";
import { useUserStateContext, useDataContext } from "../App";
import ConnectionDetails from "./ConnectionDetails";
import PasswordFields from "./PasswordFields";

const MAX_ATTEMPTS_BEFORE_WARNING = 2;

export default function LoginDialog({
  open,
  onClose,
  vendorInfo,
  vendorLogoSrc,
  resetPasswordLink,
}) {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [failedConnectionAlertType, setFailedConnectionAlertType] =
    useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [usernameBorder, setUsernameBorder] = useState(true);
  const [passwordBorder, setPasswordBorder] = useState(true);
  const loginAttemptsRef = useRef(0);
  const posthog = usePostHog();
  const { userCreds } = useUserStateContext();
  const { setConnectedVendorCodes } = useDataContext();
  const [focused, setFocused] = useState(false);
  const focusedRef = useRef(focused);
  const loginCredsRef = useRef({
    username: username,
    password: password,
  });
  const dispatch = useDispatch();

  const isAbove500px = useMediaQuery("(min-width:500px)");

  useEffect(() => {
    if (showSuccessAlert) {
      setTimeout(() => onClose(), 1500);
    }
  }, [showSuccessAlert, onClose]);

  useEffect(() => {
    focusedRef.current = focused;
  }, [focused]);

  useEffect(() => {
    loginCredsRef.current = {
      username: username,
      password: password,
    };
  }, [password, username]);

  const onConnect = (e) => {
    e.stopPropagation();

    if (e.key === "Enter" && focusedRef.current) {
      if (
        loginCredsRef.current.username !== "" &&
        loginCredsRef.current.password !== ""
      ) {
        connectDistributor(loginCredsRef);
      }
    }
  };

  const onConnectClick = (e) => {
    e.stopPropagation();
    if (
      loginCredsRef.current.username !== "" &&
      loginCredsRef.current.password !== ""
    ) {
      connectDistributor(loginCredsRef);
    }
  };

  const connectDistributor = async (loginCredsRef) => {
    setLoading(true);

    try {
      // Set client
      var client;
      if (vendorInfo.code === "ss") {
        client = ssClient;
      } else {
        client = directClient;
      }
      const connectionResp = await client.put(
        endpoints.users(userCreds.cognitoID),
        {
          code: vendorInfo.code,
          username: loginCredsRef.current.username,
          password: loginCredsRef.current.password,
          free_shipping_minimum: vendorInfo?.freeShippingMin,
        },
      );
      setLoading(false);
      // Determine success or error UI
      if (connectionResp.status === 200) {
        setShowSuccessAlert(true);
        window.rdt("track", "Custom", { customEventName: "vendorConnected" });
      } else {
        setFailedConnectionAlertType("error");
      }
      // Update user obj + connected vendor codes in state
      const distrData = connectionResp.data.Distributors;
      let newConnectedCodes = {};
      const distrCodes = Object.keys(distrData);
      const vendorEntries = Object.entries(vendors);

      for (const entry of vendorEntries) {
        const [dataKey, vendorDataFields] = entry;
        const vendorCode = vendorDataFields.code;
        if (distrCodes.includes(vendorCode)) {
          newConnectedCodes[dataKey] = vendorCode;
        } else {
          newConnectedCodes[dataKey] = false;
        }
      }
      dispatch(fetchUserSuccess({ userObj: connectionResp.data }));
      setConnectedVendorCodes(newConnectedCodes);
      posthog?.people?.set({
        connectedVendors: true,
      });
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      if (loginAttemptsRef.current > MAX_ATTEMPTS_BEFORE_WARNING) {
        setFailedConnectionAlertType("warning");
      } else {
        loginAttemptsRef.current += 1;
        setFailedConnectionAlertType("error");
      }
    }
  };

  const debounceConnect = debounce(onConnect, 100);

  useEffect(() => {
    window.addEventListener("keypress", debounceConnect);

    return () => {
      window.removeEventListener("keypress", debounceConnect);
    };
  }, [debounceConnect]);

  const resetState = () => {
    setUsername("");
    setPassword("");
    setShowPassword(false);
    setFailedConnectionAlertType(null);
    setShowSuccessAlert(false);
    loginAttemptsRef.current = 0;
  };

  const onDialogClose = () => {
    resetState();
    onClose();
  };

  const handleUsernameBlur = () => {
    setUsernameBorder(true);
    setFocused(false);
  };

  const handleUsernameFocus = () => {
    setUsernameBorder(false);
    setFocused(true);
  };

  const handlePasswordBlur = () => {
    setPasswordBorder(true);
    setFocused(false);
  };

  const handlePasswordFocus = () => {
    setPasswordBorder(false);
    setFocused(true);
  };

  return (
    <Dialog
      onClose={onDialogClose}
      maxWidth="xlarge"
      open={open}
      classes={{
        container: {
          maxWidth: "100vw",
        },
      }}
    >
      <div className={styles.distributorLogin}>
        <Stack
          direction="column"
          alignItems="center"
          sx={{
            padding: isAbove500px
              ? "0 0.75rem 0.75rem 0.75rem"
              : "0 0.1rem 1.5rem 0.1rem",
            position: "relative",
          }}
        >
          <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
            <div
              className={styles.loginLogoWrapper}
              data-downsize={vendorInfo.code === "slc"}
            >
              <img src={vendorLogoSrc} alt={vendorInfo.name} />
            </div>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ position: "absolute", top: "0rem", right: "1rem" }}
          >
            <IconButton onClick={onDialogClose}>
              <Close />
            </IconButton>
          </Stack>
          <ConnectionDetails vendorInfo={vendorInfo} />
        </Stack>
        <PasswordFields
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          loading={loading}
          onConnectClick={onConnectClick}
          failedConnectionAlertType={failedConnectionAlertType}
          showSuccessAlert={showSuccessAlert}
          resetPasswordLink={resetPasswordLink}
          vendorInfo={vendorInfo}
          usernameBorder={usernameBorder}
          setUsernameBorder={setUsernameBorder}
          passwordBorder={passwordBorder}
          setPasswordBorder={setPasswordBorder}
          handleUsernameBlur={handleUsernameBlur}
          handleUsernameFocus={handleUsernameFocus}
          handlePasswordBlur={handlePasswordBlur}
          handlePasswordFocus={handlePasswordFocus}
        />
      </div>
    </Dialog>
  );
}
