import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

function DirectLogo({ onProductPage=false }) {
  const navigate = useNavigate();
  const blueBaseDirectLogo =
    "https://d34c0c1in94n75.cloudfront.net/direct_logo_blue_base_orange_arrow.png";
  return (
    <Box onClick={(e) => {
      e.stopPropagation();
      navigate('/direct/vendors');
    }} 
    sx={{ cursor: 'pointer' }}>
      <img
        src={blueBaseDirectLogo}
        alt={null}
        className={`${onProductPage ? "w-[115px]" : "w-[65px]"} max-h-auto`}
      />
    </Box>
  );
}

export default DirectLogo;
