import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";

import directClient from "../axios/directClient";
import endpoints from "../axios/endpoints.js";
import vendors from "../constants/vendors";
import { useUserStateContext, useDataContext } from "../App";

function DisconnectVendor({ onClose, vendorInfo }) {
  const { userCreds } = useUserStateContext();
  const { setConnectedVendorCodes } = useDataContext();
  const [removeVendorLoading, setRemoveVendorLoading] = useState(false);

  async function removeDistributor(
    userCreds,
    vendorCode,
    setConnectedVendorCodes,
    setRemoveVendorLoading,
    onClose,
  ) {
    setRemoveVendorLoading(true);
    try {
      const headers = {
        "User-Identifier": userCreds.cognitoID,
      };
      const body = {
        distributor: vendorCode,
        remove: "true",
      };
      const connectionResp = await directClient.put(
        endpoints.users(userCreds.cognitoID),
        body,
        { headers },
      );

      const distrData = connectionResp.data.Distributors;
      let newConnectedCodes = {};
      const distrCodes = Object.keys(distrData);
      const vendorEntries = Object.entries(vendors);

      for (const entry of vendorEntries) {
        const [dataKey, vendorDataFields] = entry;
        const vendorCode = vendorDataFields.code;
        if (distrCodes.includes(vendorCode)) {
          newConnectedCodes[dataKey] = vendorCode;
        } else {
          newConnectedCodes[dataKey] = false;
        }
      }
      setConnectedVendorCodes(newConnectedCodes);
      setRemoveVendorLoading(false);
      onClose();
    } catch (error) {
      console.log(error.message);
    }
    setRemoveVendorLoading(false);
  }

  if (removeVendorLoading) {
    return (
      <Dialog open={true} onClose={onClose}>
        <DialogTitle sx={{ textAlign: "center" }}>
          Disconnecting Vendor
        </DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle className="text-center">Disconnect Vendor</DialogTitle>
      <DialogContent className="text-base">
        Are you sure you want to disconnect from {vendorInfo.name}? You will
        need to re-connect this vendor if you want to continue shopping with
        them on DGI.
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", paddingX: "20px" }}>
        <Button onClick={onClose} className="text-blueblue hover:bg-lightblue">
          Cancel
        </Button>
        <Button
          onClick={() =>
            removeDistributor(
              userCreds,
              vendorInfo.code,
              setConnectedVendorCodes,
              setRemoveVendorLoading,
              onClose,
            )
          }
          sx={{
            color: "error.main",
            borderColor: "error.main",
            borderWidth: 1,
            borderStyle: "solid",
            "&:hover": {
              borderColor: "error.dark",
              backgroundColor: "rgba(255, 0, 0, 0.1)",
            },
          }}
        >
          Disconnect
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DisconnectVendor;
