import React, { useState } from "react";
import { Box } from "@mui/material";
import ContactColumn from "./ContactColumn"; // Import the ContactColumn component
import DirectVendorShopAboutContainer from "./DirectVendorShopAboutContainer"; // Import the new component

const DirectVendorBody = ({ vendor, isVendorLoading }) => {
  const [selectedTab, setSelectedTab] = useState("products"); // Initialize with "products"

  const handleTabChange = (event, newValue) => {
    if (newValue !== null) {
      setSelectedTab(newValue);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        py: "1rem",
        flexDirection: "row",
        "@media (max-width: 800px)": {
          flexDirection: "column", // Switch to column layout below 800px
        },
      }}
    >
      <ContactColumn
        vendor={vendor}
        handleTabChange={handleTabChange}
        isVendorLoading={isVendorLoading}
      />
      <DirectVendorShopAboutContainer
        vendor={vendor}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
        isVendorLoading={isVendorLoading}
      />
    </Box>
  );
};

export default DirectVendorBody;
