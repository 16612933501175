import { useState, useEffect, createContext, useContext, useMemo } from "react";

import styles from "./styles.module.scss";
import Dashboard from "../dashboard/Dashboard";
import ConnectionPage from "../integrations/ConnectionPage";
import Header from "../header/Header";
import Footer from "pages/landing_page/Footer";
import CartPage from "../pages/cart/CartPage";
import SignUpPage from "../pages/signup_page/SignUpPage";
import ProductPage from "pages/product/ProductPage";
import CheckoutPage from "pages/checkout/CheckoutPage";
import ConfirmationPage from "pages/confirmation/ConfirmationPage";
import DirectCheckoutPage from "pages/directCheckout/DirectCheckoutPage";
import ReceiptPage from "pages/receipt/ReceiptPage";
import InvoicePage from "pages/invoices/InvoicesPage";
import OrderHistoryPage from "pages/order-history/OrderHistoryPage";
import HomePage from "pages/home/HomePage";
import DirectVendors from "pages/directVendors/DirectVendors";
import DirectVendorPage from "pages/directVendorsPage/DirectVendorPage";
import { useUserStateContext, useDataContext } from "App";
import { sendGetCartsPayload } from "features/sendGetCartsPayload";
import { useDispatch } from "react-redux";
import { INVOICE_TESTERS } from "constants/featureFlags";
import { Navigate } from "react-router-dom";
import DirectCheckoutHeader from "header/DirectCheckoutHeader";

const WebsocketContext = createContext();
const useWebsocketContext = () => useContext(WebsocketContext);
export { WebsocketContext, useWebsocketContext };

const AuthedPage = ({ component, windowWidth, showHeader = true }) => {
  // for mobile shopping use
  const [bannerHeight, setBannerHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const { userCreds } = useUserStateContext();
  const { connectedVendorCodes } = useDataContext();
  const dispatch = useDispatch();

  const connectWebSocket = (user_id) => {
    dispatch({ type: "WEBSOCKET_CONNECT", payload: { user_id: user_id } });
  };
  useEffect(() => {
    if (userCreds?.cognitoID) {
      connectWebSocket(userCreds.cognitoID);
    }
  }, [userCreds.cognitoID]);

  const sendJsonMessage = (message) => {
    dispatch({ type: "WEBSOCKET_SEND_MESSAGE", payload: message });
  };
  const websocketContext = useMemo(
    () => ({ sendJsonMessage }),
    [sendJsonMessage],
  );

  useEffect(() => {
    sendGetCartsPayload(
      userCreds.cognitoID,
      sendJsonMessage,
      dispatch,
      connectedVendorCodes,
      true,
    );
  }, [JSON.stringify(connectedVendorCodes), userCreds.cognitoID]);

  let childComponent;
  if (component === "dashboard") {
    childComponent = (
      <WebsocketContext.Provider value={websocketContext}>
        <Dashboard
          windowWidth={windowWidth}
          bannerHeight={bannerHeight}
          headerHeight={headerHeight}
        />
      </WebsocketContext.Provider>
    );
  } else if (component === "connection_page") {
    childComponent = <ConnectionPage />;
  } else if (component === "cart") {
    childComponent = <CartPage />;
  } else if (component === "signup_page") {
    childComponent = <SignUpPage />;
  } else if (component === "product") {
    childComponent = <ProductPage sendJsonMessage={sendJsonMessage} />;
  } else if (component === "checkout") {
    childComponent = <CheckoutPage />;
  } else if (component === "confirmation") {
    childComponent = <ConfirmationPage />;
  } else if (component === "directCheckout") {
    childComponent = <DirectCheckoutPage />;
  } else if (component === "receipt_page") {
    childComponent = <ReceiptPage />;
  } else if (component === "directVendors") {
    childComponent = <DirectVendors />;
  }  else if (component === "directVendorPage") {
      childComponent = <DirectVendorPage />;
  } else if (component === "invoices") {
    if (!INVOICE_TESTERS.has(userCreds?.cognitoID)) {
      return <Navigate to="/shop" />;
    } else {
      childComponent = <InvoicePage />;
    }
  } else if (component === "order-history") {
    childComponent = <OrderHistoryPage />;
  } else if (component === "home") {
    childComponent = <HomePage />;
  }

  let renderHeader = null;
  if (component === "directCheckout") {
    renderHeader = <DirectCheckoutHeader />;
  } else {
    if (showHeader) {
      renderHeader = (
        <Header windowWidth={windowWidth} setHeaderHeight={setHeaderHeight} />
      );
    }
  }

  return (
    <>
      {renderHeader}
      <WebsocketContext.Provider value={websocketContext}>
        <main
          className={`${styles.mainContent} relative ${styles.isSearchFocused}`}
        >
          {childComponent}
        </main>
      </WebsocketContext.Provider>
      <Footer />
    </>
  );
};

export default AuthedPage;
