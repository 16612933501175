import axios from "axios";
import { Auth } from "aws-amplify";

const BASE_API_URLS = {
  development: "https://fcba17gw9k.execute-api.us-east-1.amazonaws.com",
  staging: "https://fcba17gw9k.execute-api.us-east-1.amazonaws.com",
  production: "https://byyhh7s57b.execute-api.us-east-1.amazonaws.com",
};

const BASE_API_URL =
  process.env.REACT_APP_LOCAL_DEV === "true"
    ? "http://127.0.0.1:8000"
    : BASE_API_URLS[process.env.NODE_ENV] || BASE_API_URLS.production;

const client = axios.create({
  baseURL: BASE_API_URL,
});

client.interceptors.request.use(async (config) => {
  const session = await Auth.currentSession();
  const jwtToken = session.accessToken.jwtToken;

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${jwtToken}`,
    },
  };
});

export default client;
