import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ComputerIcon from "@mui/icons-material/Computer";
import { RenderSupplierRating } from "integrations/SupplierRating";

const StoreFront = ({ vendor, handleTabChange }) => {
  const logoUrl = `https://d34c0c1in94n75.cloudfront.net/${vendor.vendorCode}_logo.png`;

  // Define icons and their corresponding vendor links
  const socialLinks = [
    { icon: FacebookIcon, link: vendor.socialLinks?.facebookLink },
    { icon: InstagramIcon, link: vendor.socialLinks?.instagramLink },
    { icon: LinkedInIcon, link: vendor.socialLinks?.linkedinLink },
    { icon: YouTubeIcon, link: vendor.socialLinks?.youtubeLink },
    { icon: ComputerIcon, link: vendor.socialLinks?.websiteLink },
  ];

  return (
    <Box
      sx={{
        border: "1px solid #d2d6da",
        borderRadius: "5px",
        backgroundColor: "#fff",
        textAlign: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "70px",
          backgroundColor: "rgba(214, 241, 249, 0.5)",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          mt: "40px",
          display: "inline-block",
          backgroundColor: "#fff",
          borderRadius: "5px",
          width: "180px",
          height: "60px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            p: 1.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            src={logoUrl}
            alt={`${vendor?.vendorName} Logo`}
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Box>
      <Typography variant="h5" sx={{ zIndex: 3, position: "relative" }}>
        {vendor?.vendorName}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 1,
          position: "relative",
        }}
      >
        {socialLinks.map(({ icon: Icon, link }, index) =>
          link ? (
            <IconButton
              key={index}
              component="a"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: "#676F78",
                "&:hover": {
                  color: "rgba(0, 88, 141, 1)",
                },
              }}
            >
              <Icon />
            </IconButton>
          ) : null
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          my: 2,
        }}
      >
        <RenderSupplierRating
          vendorInfo={vendor}
          starSize={32}
          reviewTextSize="1.1rem"
          handleTabChange={handleTabChange}
        />
      </Box>
      {vendor.joinedDGI && (
        <Typography
          variant="body2"
          sx={{
            color: "#676F78",
            mt: 3,
            mb: 2,
            position: "relative",
          }}
        >
          Joined DGI in {vendor.joinedDGI}
        </Typography>
      )}
    </Box>
  );
};

export default StoreFront;
