import React from "react";
import { CheckoutItemText } from "custom/text/shopping_page/StandardSideCartTexts";

const SideCheckoutItemEntry = ({ entryData, hidePrices }) => {
  const formattedTotalPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(entryData.totalPrice);

  return (
    <div
      className={`grid px-5 justify-center items-center gap-5 ${
        entryData.size ? "grid-cols-[35%_20%_40%]" : "grid-cols-[1fr_1fr]"
      }`}
    >
      {entryData.size && (
        <div className="flex justify-center">
          <CheckoutItemText content={`${entryData.size}: `} />
        </div>
      )}
      <div className="flex justify-center">
        <CheckoutItemText content={entryData.quantity} />
      </div>
      <div className="flex justify-center">
        <CheckoutItemText
          content={formattedTotalPrice}
          hidePrices={hidePrices}
        />
      </div>
    </div>
  );
};

export default SideCheckoutItemEntry;
