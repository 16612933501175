import { vendorStyleKeys } from "constants/vendors";

export default function getStyleNumber(productData) {
  if (!productData) {
    return "";
  }
  if (productData?.direct) {
    return productData?.master_style;
  }
  for (const styleKey of vendorStyleKeys) {
    // we might get back empty strings as styles
    if (productData[styleKey] && productData[styleKey].length > 0) {
      return productData[styleKey];
    }
  }
  return "";
}
