import React, { useState } from "react";
import {
  Box,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Typography,
} from "@mui/material";
import styles from "./styles.module.scss";
import vendors from "constants/vendors";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

export default function VendorTab({
  vendorDataCode,
  isDropdown,
  dropdownKeys,
  autoAssignTab,
  setCartAlertContent,
  setShowCartAlert,
  showCartAlert,
  vendorsBestPrices,
  hidePrices,
  selectedVendorCode,
  setSelectedVendorCode
}) {
  const [dropdownSelectedVendor, setDropdownSelectedVendor] = useState("");

  const newVendorSelected =
    selectedVendorCode && selectedVendorCode !== dropdownSelectedVendor;
  const shouldUpdateDropdownVendor =
    dropdownKeys && dropdownKeys.includes(selectedVendorCode);
  if (newVendorSelected && shouldUpdateDropdownVendor) {
    setDropdownSelectedVendor(selectedVendorCode);
  }
  if (dropdownSelectedVendor && !shouldUpdateDropdownVendor) {
    setDropdownSelectedVendor("");
  }

  const handleTabClick = () => {
    if (!isDropdown) {
      setSelectedVendorCode(vendorDataCode);
      if (autoAssignTab.current) {
        autoAssignTab.current = false;
      }
    }
    if (showCartAlert) {
      setCartAlertContent();
      setShowCartAlert(false);
    }
  };

  const handleDropdownChange = (event) => {
    const newVendorKey = event.target.value;
    setDropdownSelectedVendor(newVendorKey); // Update local dropdown selected vendor
    setSelectedVendorCode(newVendorKey); // Update global selected vendor
    if (autoAssignTab.current) {
      autoAssignTab.current = false;
    }
    if (showCartAlert) {
      setCartAlertContent();
      setShowCartAlert(false);
    }
  };

  const handleDropdownOpen = () => {
    // When the dropdown opens, update the global selectedVendorCode to the currently selected value in the dropdown
    if (dropdownSelectedVendor) {
      setSelectedVendorCode(dropdownSelectedVendor);
    }
  };

  let vendorPriceObj = null;
  if (vendorDataCode) {
    vendorPriceObj = vendorsBestPrices?.[vendorDataCode];
  } else if (isDropdown && !dropdownSelectedVendor) {
    let bestVendor = null;
    for (const dropdownKey of dropdownKeys) {
      const dropdownVendor = vendorsBestPrices?.[dropdownKey];
      if (
        !bestVendor ||
        dropdownVendor["productPrice"] < bestVendor["productPrice"]
      ) {
        bestVendor = dropdownVendor;
      }
    }
    vendorPriceObj = bestVendor;
  } else if (isDropdown && dropdownSelectedVendor) {
    vendorPriceObj = vendorsBestPrices?.[dropdownSelectedVendor];
  }
  const vendorPrice = vendorPriceObj?.productPrice;
  const displaySalesTag = vendorPriceObj?.saleExists;

  const isSelected =
    selectedVendorCode && selectedVendorCode === dropdownSelectedVendor;
  const dynamicHeight = isSelected ? "60px" : "45px";
  const showVendorPrice = !hidePrices && vendorPrice != undefined;

  const CLOUDFRONT_URL = "https://d34c0c1in94n75.cloudfront.net/";

  if (isDropdown) {
    return (
      <div className="flex flex-col items-center gap-[2px]">
        {showVendorPrice && (
          <div className="flex justify-center items-center gap-[4px]">
            {displaySalesTag && (
              <LocalOfferIcon sx={{ fontSize: "1.1rem", color: "#5bd4c0" }} />
            )}
            <Typography
              className="text-base font-semibold text-offBlack"
              sx={{ textShadow: "1px 1px 1px rgba(165, 165, 165, 0.25)" }}
            >
              {`${vendorPrice}`}
            </Typography>
          </div>
        )}
        <Box sx={{ minWidth: 135, display: "inline-block", maxHeight: "60px" }}>
          <FormControl
            fullWidth
            sx={{
              "&&": {
                borderRadius: "5px 5px 0 0",
                borderBottom: "none",
              },
              ".MuiOutlinedInput-root": {
                "&:hover:not(.Mui-disabled) fieldset": {
                  borderColor: "#c3c5cd",
                },
                "&:before": {
                  borderBottom: "none",
                },
                borderRadius: "5px 5px 0 0",
                "&.Mui-focused fieldset": {
                  borderColor: "#c3c5cd",
                  borderWidth: "1px",
                  borderBottom: "none",
                },
                "& fieldset": {
                  borderBottom: "none",
                },
              },
              height: dynamicHeight,
              "&:hover": {
                height: isSelected ? "60px" : "50px",
              },
              ".MuiInputBase-root": {
                height: "100%",
              },
              ".MuiSelect-select": {
                height: "100%",
                display: "flex",
                alignItems: "center",
                borderRadius: "5px 5px 0 0",
              },
            }}
          >
            {!dropdownSelectedVendor && (
              <InputLabel
                id="vendor-select-label"
                sx={{
                  marginTop: "2px", // Move the text up slightly
                  "&.Mui-focused": {
                    color: "gray", // Maintain the label color even when focused
                    marginTop: "2px", // Reset the margin when focused and label shrinks
                  },
                  // If you have a shrunk state, you might need to adjust this as well
                  "&.MuiInputLabel-shrink": {
                    marginTop: "24px",
                  },
                }}
                className="text-[14px] text-typeGrey"
              >
                More Vendors
              </InputLabel>
            )}
            <Select
              labelId="vendor-select-label"
              id="vendor-select"
              value={dropdownSelectedVendor}
              onChange={handleDropdownChange}
              onOpen={handleDropdownOpen} // Trigger global state update when dropdown opens
              renderValue={(selected) => {
                return (
                  <img
                    src={`${CLOUDFRONT_URL}${selected}_logo.png`}
                    alt={selected}
                    style={{
                      height:
                        selected === "ss" ||
                        selected === "slc"
                          ? "17px"
                          : "30px",
                      maxWidth: vendors[selected]?.name === "SLC Activewear" && "90%"
                    }}
                  />
                );
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  width: "1.2em",
                  height: "1.2em",
                  marginTop: "2px",
                  color: "#676F78", // typeGrey
                },
              }}
            >
              {dropdownKeys.map((key, index) => (
                <MenuItem
                  key={key}
                  value={key}
                  sx={{
                    marginTop: index > 0 && "8px", // adds some vertical spacing to the items
                  }}
                >
                  <img
                    src={`${CLOUDFRONT_URL}${key}_logo.png`}
                    alt={key}
                    style={{ height: key === "slc" ? "17px" : "30px" }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center gap-[2px]">
      {showVendorPrice && (
        <div className="flex justify-center items-center">
          {displaySalesTag && (
            <LocalOfferIcon sx={{ fontSize: "1.1rem", color: "#5bd4c0" }} />
          )}
          <Typography
            className={`${
              selectedVendorCode === vendorDataCode ? "text-lg" : "text-base"
            } font-semibold text-offBlack`}
            sx={{ textShadow: "1px 1px 1px rgba(165, 165, 165, 0.25)" }}
          >{`${vendorPrice}`}</Typography>
        </div>
      )}
      <div
        className={styles.vendorTab}
        onClick={handleTabClick}
        data-selected={selectedVendorCode === vendorDataCode}
        data-downsize={vendorDataCode === "slc"}
      >
        {vendorDataCode && (
          <img
            src={`${CLOUDFRONT_URL}${vendorDataCode}_logo.png`}
            alt={vendorDataCode}
          />
        )}
      </div>
    </div>
  );
}
