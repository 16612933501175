import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import directClient from "../../axios/directClient";
import ssClient from "../../axios/ssClient.js";
import endpoints from "../../axios/endpoints";
import getTodaysDate from "utilities/getTodaysDate";
import { useUserStateContext, useCheckoutContext } from "App";
import vendors from "constants/vendors";
import { usePostHog } from "posthog-js/react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useSelector, useDispatch } from "react-redux";
import { fetchCartSuccess, selectCart } from "features/cartsSlice";

const OrderErrorBanner = ({ errorMessageText, vendorName }) => {
  if (!errorMessageText) {
    return;
  }

  return (
    <Alert severity="error" sx={{ fontSize: "15px " }}>
      <AlertTitle sx={{ fontSize: "18px " }}>
        Sorry, we couldn't complete your order
      </AlertTitle>
      {errorMessageText}
      <br />
      If the issue persists, please use {vendorName}'s site to complete your
      purchase. We apologize for any inconvenience.
    </Alert>
  );
};

const NavButtons = ({ canExecuteOrder }) => {
  const { vendorCode } = useParams();
  const vendorName = Object.values(vendors).find(
    (vendor) => vendor.code === vendorCode,
  )?.name;
  const navigate = useNavigate();
  const { userCreds } = useUserStateContext();
  const { checkoutFormData } = useCheckoutContext();
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const vendorCartData = useSelector((state) => selectCart(state, vendorCode));

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState("");
  const [messageIndex, setMessageIndex] = useState(0);

  const messages = [
    `Sending your order to ${vendorName}...`,
    `Confirming your order with ${vendorName}...`,
  ];

  useEffect(() => {
    if (isLoading && messageIndex < messages.length - 1) {
      const timer = setTimeout(() => {
        setMessageIndex((prevIndex) => prevIndex + 1);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isLoading, messageIndex]);

  const handleConfirmOrder = async () => {
    setIsLoading(true);
    const headers = {
      "User-Identifier": userCreds.cognitoID,
    };
    const body = {
      shippingAddress: checkoutFormData["shippingAddress"],
      shippingMethods: checkoutFormData["warehouseShippingMethods"],
      paymentMethod: checkoutFormData["paymentMethod"],
      poNumber:
        checkoutFormData["poNumber"].length > 0
          ? checkoutFormData["poNumber"]
          : getTodaysDate(),
      whsePickupDate: checkoutFormData["whsePickupDate"] ?? "",
      whsePickupTime: checkoutFormData["whsePickupTime"] ?? "",
    };
    try {
      var client;
      if (vendorCode === "ss") {
        client = ssClient;
      } else {
        client = directClient;
      }
      const checkoutResp = await client.post(
        endpoints.checkout(vendorCode),
        body,
        { headers },
      );

      posthog?.capture("checkout", {
        total_price: vendorCartData?.totalPrice,
        vendor: vendorName,
      });

      // Clear vendor cart
      dispatch(
        fetchCartSuccess({
          vendorCode: vendorCode,
          cartData: { items: [], totalPrice: 0 },
        }),
      );

      if (checkoutResp.status >= 200 && checkoutResp.status < 300) {
        if (!checkoutResp?.data?.orderId) {
          navigate(`/receipt/${vendorCode}`);
        } else {
          navigate(
            `/receipt/${vendorCode}?orderId=${checkoutResp?.data?.orderId}`,
          );
        }
      }
    } catch (error) {
      const respStatus = error?.response?.status;
      const distributorError = respStatus && respStatus === 502;
      const internalError = respStatus && respStatus === 500;
      const apiError = respStatus && respStatus >= 400 && respStatus < 500;

      let errorMessage = "";
      if (error.response?.data?.errorMsg) {
        errorMessage = error?.response?.data?.errorMsg;
      } else if (apiError || internalError) {
        errorMessage = `Click the chat button at the bottom right of your screen for further assistance.`;
      } else if (distributorError) {
        errorMessage = `Click the chat button at the bottom right of your screen for further assistance.`;
      }

      setErrorMessageText(errorMessage);
      console.error(`Failed to confirm order with error: ${error}`);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <Stack
        flexDirection="column"
        alignItems="center"
        sx={{
          gap: "20px",
          paddingTop: "48px",
          paddingBottom: "48px",
        }}
      >
        <Typography variant="subtitle1" style={{ color: "#414b56" }}>
          {messages[messageIndex]}
        </Typography>
        <LinearProgress sx={{ width: "400px" }} />
      </Stack>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        marginTop: 7,
        paddingBottom: 3,
      }}
    >
      <OrderErrorBanner
        errorMessageText={errorMessageText}
        vendorName={vendorName}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: 26,
          paddingRight: 26,
        }}
      >
        <Button
          startIcon={
            <ArrowBackIcon sx={{ marginRight: 1 }} className="text-blueblue" />
          }
          variant="contained"
          sx={{
            marginRight: 6,
            backgroundColor: "transparent",
          }}
          className="text-blueblue hover:bg-lightblue"
          onClick={() => navigate(`/checkout/${vendorCode}`)}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{
            padding: "10px 24px",
            fontSize: "1rem",
          }}
          className="bg-blueblue hover:bg-denim hover:text-grayscaleWhite"
          disabled={canExecuteOrder ? false : true}
          onClick={handleConfirmOrder}
        >
          Submit Order
        </Button>
      </Box>
    </Box>
  );
};

export default NavButtons;
