import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Stack,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextsmsIcon from "@mui/icons-material/Textsms";
import styles from "./styles.module.scss";
import openInNewTab from "utilities/openInNewTab";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const renderTextWithItalics = (text) => {
  const parts = text.split(/(\*[^*]+\*)/g);
  return parts.map((part, index) => {
    if (part.startsWith("*") && part.endsWith("*")) {
      return <em key={index}>{part.slice(1, -1)}</em>;
    }
    return part;
  });
};

const FAQAccordion = ({
  question,
  shortAnswer,
  fullAnswer,
  isFirst,
  isLast,
}) => (
  <Accordion
    disableGutters
    sx={{
      background: "transparent",
      boxShadow: "none",
      "&:before": {
        display: "none",
      },
      "&.Mui-expanded": {
        margin: 0,
      },
      "&:last-child": {
        borderBottom: isLast ? "none" : "1px solid rgba(0, 0, 0, .125)",
      },
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      className="pl-[4px] pr-[0px] py-[10px] hover:bg-[rgb(224,224,224)]"
      sx={{
        "&.Mui-expanded": {
          minHeight: 48,
          py: "10px",
        },
        maxWidth: "850px",
        borderTop: isFirst ? "none" : "1px solid rgba(0, 0, 0, .125)",
      }}
    >
      <Typography className="text-xl">
        {renderTextWithItalics(question)}
      </Typography>
    </AccordionSummary>
    <AccordionDetails 
      className="px-[4px] pb-[40px]" 
      sx={{
        maxWidth: "850px",
      }}
    >
      <Typography>
        <strong>{renderTextWithItalics(shortAnswer)}</strong>{" "}
        <span className="text-blob">{renderTextWithItalics(fullAnswer)}</span>
      </Typography>
    </AccordionDetails>
  </Accordion>
);

const FAQAccordionSection = ({ faqData }) => {
  return (
    <Box className="flex flex-col max-w-[100%] lg:max-w-[55%]">
      {faqData.map((faq, index) => (
        <FAQAccordion
          key={index}
          question={faq.question}
          shortAnswer={faq.shortAnswer}
          fullAnswer={faq.fullAnswer}
          isFirst={index === 0}
          isLast={index === faqData.length - 1}
        />
      ))}
    </Box>
  );
};

const FAQContainer = ({ questionsText, answersText, faqData }) => {
  return (
    <div
      className={`${styles.faqWrapper} flex-wrap max-w-[90%] lg:max-w-[100%] lg:flex-nowrap`}
    >
      <Box className="grow flex justify-center items-center">
        <Stack spacing={0} direction="column">
          <Typography
            variant="h3"
            gutterBottom
            className="mb-[0.2em] text-left"
          >
            {questionsText}
          </Typography>
          <Typography
            variant="h3"
            gutterBottom
            className="font-semibold text-left"
          >
            {answersText}
          </Typography>
        </Stack>
      </Box>
      <FAQAccordionSection faqData={faqData} />
    </div>
  );
};

const LandingPageFAQ = ({ questionsText, answersText, faqData }) => {
  return (
    <div className="w-full flex flex-col items-center justify-center gap-[112px] pt-[56px] pb-[80px] bg-lightestGrey">
      <FAQContainer
        questionsText={questionsText}
        answersText={answersText}
        faqData={faqData}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 1,
          py: 5,
          width: '90%',
          maxWidth: '1200px',
        }}
        className="bg-dgiWhite"
      >
        <Box className="flex flex-col lg:flex-row justify-center items-center w-full gap-[4rem] px-4">
          <Box className="w-full lg:w-1/2 flex justify-center">
            <img
              src="https://d34c0c1in94n75.cloudfront.net/faq_headshots.svg"
              alt="FAQ Headshots"
              style={{
                width: '100%',
                height: 'auto',
                maxWidth: '400px',
                maxHeight: '350px',
                objectFit: 'contain',
              }}
            />
          </Box>
          <Stack 
            direction="column" 
            spacing={3} 
            justifyContent="center" 
            alignItems="center"
            className="w-full lg:w-1/2"
          >
              <Typography
                variant="h4"
                gutterBottom
                className="text-wolfGrey text-center"
                sx={{
                  fontSize: "1.9rem", // Default for h4 size
                  "@media (max-width: 500px)": {
                    fontSize: "1.5rem", // Smaller size for screens below 500px
                  },
                }}
              >
                Still have questions?
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className="text-wolfGrey text-center"
                sx={{
                  fontSize: "1.25rem", // Default for h6 size
                  "@media (max-width: 500px)": {
                    fontSize: "1rem", // Smaller size for screens below 500px
                  },
                }}
              >
                Get answers directly from the DGI Team.
              </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px',
                width: '100%',
                maxWidth: '350px',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className="text-lg normal-case text-grayscaleWhite bg-blueblue rounded-[7px] p-[12px]"
                onClick={() => {
                  if (window.Intercom) {
                    window.Intercom("show");
                  } else {
                    console.error("Intercom is not loaded");
                  }
                }}
              >
                <TextsmsIcon className="text-grayscaleWhite mr-[8px]" />
                <Typography className="text-lg text-grayscaleWhite normal-case">
                  Chat with the team
                </Typography>
              </Button>
              <Typography variant="body1" color="text.secondary" sx={{ my: 1 }}>
                OR
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                sx={{
                  p: 1,
                  px: 2,
                  borderRadius: "4px",
                  border: "1px solid rgb(2 129 192)",
                  justifyContent: "center",
                }}
                className="bg-babyBlue hover:bg-teenBlue p-[12px]"
                onClick={() =>
                  openInNewTab(
                    "https://calendly.com/ian-dgi/onboarding?month=2024-01"
                  )
                }
              >
                <CalendarMonthIcon className="text-blueblue mr-[8px]" />
                <Typography className="text-lg text-blueblue normal-case">
                  Schedule a free demo
                </Typography>
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
    </div>
  );
};

export default LandingPageFAQ;
