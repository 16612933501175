import { useState, useEffect } from 'react';
import { typesenseClient } from "App";
import DefaultFavorites from './DefaultFavorites';
import NewRecommendations from './NewRecommendations';
import { useSelector } from 'react-redux';

const highlightMatch = (items, searchValue) => {
  // Escape special characters in the search value
  const escapedSearchValue = searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(`^(${escapedSearchValue})`, "i");
  return items
    .filter((item) => item.toLowerCase().startsWith(searchValue.toLowerCase()))
    .map((item) => item.replace(regex, "<mark>$1</mark>"));
};

export const useSearchBar = (containerRef, inputValue, setInputValue) => {
  const [isFocused, setIsFocused] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isInitialFocusLoading, setIsInitialFocusLoading] = useState(false);
  const [showNewRecommendations, setShowNewRecommendations] = useState(false);
  const [filteredNewRecommendations, setFilteredNewRecommendations] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [filteredFavorites, setFilteredFavorites] = useState([]);
  const [autoCompleteSuggestions, setAutoCompleteSuggestions] = useState([]);
  const [usingDefaultFavorites, setUsingDefaultFavorites] = useState(false);
  const topStyles = useSelector((state) => state.user?.user?.topStyles);

  useEffect(() => {
    setSuggestionsFromTopStyles();
  }, [topStyles]);

  const setSuggestionsFromTopStyles = () => {
    const styles = (topStyles?.filter(item => item.style)
      .map(item => item.style) || DefaultFavorites)
      .slice(0, 20);
  
    setUsingDefaultFavorites(!topStyles?.length);
    setFavorites(styles);
    setAutoCompleteSuggestions(styles);
  };

  const getAutoCompleteSuggestions = async (query) => {
    if (!query) return;
  
    const { hits } = await typesenseClient
      .collections("products_queries")
      .documents()
      .search({
        q: query,
        query_by: "q",
        sort_by: "count:desc",
      });
  
    setAutoCompleteSuggestions(
      hits.flatMap((hit) => hit.highlights.map((h) => h.snippet))
    );
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    if (!newValue) {
      setSuggestionsFromTopStyles();
      setShowNewRecommendations(true);
      setFilteredNewRecommendations(NewRecommendations);
      setFilteredFavorites(favorites);
      return;
    }

    const filteredNew = highlightMatch(NewRecommendations, newValue);
    setShowNewRecommendations(filteredNew.length > 0);
    setFilteredNewRecommendations(filteredNew);
    setFilteredFavorites(highlightMatch(favorites, newValue));
    getAutoCompleteSuggestions(newValue);
  };

  const handleFocus = (e) => {
    e.target.select();
    setIsFocused(true);
    setAnchorEl(containerRef.current);

    if (!inputValue) {
      setSuggestionsFromTopStyles();
      setShowNewRecommendations(true);
      setFilteredNewRecommendations(NewRecommendations);
      setFilteredFavorites(favorites);
      return;
    }

    const filteredNew = highlightMatch(NewRecommendations, inputValue);
    setShowNewRecommendations(filteredNew.length > 0);
    setFilteredNewRecommendations(filteredNew);
    setFilteredFavorites(highlightMatch(favorites, inputValue));
    setIsInitialFocusLoading(true);
    setAutoCompleteSuggestions([]);

    getAutoCompleteSuggestions(inputValue).then(() =>
      setIsInitialFocusLoading(false)
    );
  };

  return {
    isFocused,
    anchorEl,
    isInitialFocusLoading,
    showNewRecommendations,
    filteredNewRecommendations,
    filteredFavorites,
    autoCompleteSuggestions,
    usingDefaultFavorites,
    handleChange,
    handleFocus,
    setIsFocused,
    setAnchorEl,
  };
};
