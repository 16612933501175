import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Grid,
  Paper,
  Modal,
} from "@mui/material";
import prod_client from "../../axios/prod_client.js";
import endpoints from "../../axios/endpoints.js";

import { fetchUserSuccess } from "features/userSlice";
import { useDispatch } from "react-redux";
import { PageLoadingBackdrop } from "./DirectCheckoutPage.js";

const AddressForm = ({
  address,
  setAddress,
  onSubmit,
  onClose,
  hasAddress = true,
}) => {
  const handleNewAddressChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={address?.name || ""}
            onChange={handleNewAddressChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Street 1"
            name="street1"
            value={address?.street1 || ""}
            onChange={handleNewAddressChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Street 2"
            name="street2"
            value={address?.street2 || ""}
            onChange={handleNewAddressChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="City"
            name="city"
            value={address?.city || ""}
            onChange={handleNewAddressChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="State"
            name="state"
            value={address?.state || ""}
            onChange={handleNewAddressChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="ZIP"
            name="zip"
            value={address?.zip || ""}
            onChange={handleNewAddressChange}
          />
        </Grid>
      </Grid>
      <Box
        sx={{ mt: 2, display: "flex", justifyContent: "flex-end", gap: "16px" }}
      >
        {hasAddress && (
          <Button
            onClick={onClose}
            sx={{ mr: 1 }}
            className="normal-case text-base text-lighterblueblue hover:bg-teenBlue"
          >
            Cancel
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          className="normal-case bg-lighterblueblue hover:bg-blueblue text-base"
        >
          Save Address
        </Button>
      </Box>
    </Box>
  );
};

const ShippingMethodSelector = ({
  vendors,
  selectedMethods,
  setSelectedMethods,
  formErrors,
  setFormErrors,
  vendorCode,
  setShippingTotal,
}) => {
  const handleMethodChange = (vendorIndex, methodId, vendorCode) => {
    const newMethods = [...selectedMethods];
    newMethods[vendorIndex].selectedShippingMethod = vendors[
      vendorIndex
    ].shippingMethods.find((m) => m.object_id === methodId);
    setSelectedMethods(newMethods);
    setShippingTotal(() =>
      newMethods.reduce(
        (acc, item) => acc + item.selectedShippingMethod?.freightCost,
        0
      )
    );
    const formErrorKey = "shippingMethod" + vendorCode;
    if (formErrors[formErrorKey]) {
      setFormErrors((prevFormErrors) => {
        return {
          ...prevFormErrors,
          formErrorKey: false,
        };
      });
    }
  };

  return (
    <Box>
      {vendors.map((vendor, index) => (
        <Paper key={index} elevation={0} sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6" className="text-charcoal">
            {vendor.vendorName} Shipping Methods
          </Typography>
          <FormControl
            fullWidth
            error={formErrors["shippingMethod" + vendor.directVendor]}
          >
            <Select
              labelId={`shipping-method-label-${index}`}
              id={`shipping-method-select-${index}`}
              value={
                selectedMethods[index]?.selectedShippingMethod?.object_id || ""
              }
              onChange={(e) =>
                handleMethodChange(index, e.target.value, vendorCode)
              }
              sx={{
                "& .MuiOutlinedInput-input": {
                  paddingBottom: "10.5px",
                },
              }}
            >
              {vendor.shippingMethods.map((method) => (
                <MenuItem key={method.object_id} value={method.object_id}>
                  <Box>
                    <Typography variant="body1">{method.name}</Typography>
                    <Typography variant="body2">
                      Price: ${method.freightCost?.toFixed(2)} - Est Delivery
                      Date: {method.estDelivery}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formErrors["shippingMethod" + vendor.directVendor] && (
            <Box className="flex flex-row justify-end mr-[10px] mt-[12px]">
              <p className="text-[#df1b41] text-sm m-[0px]">
                Your shipping method is incomplete.
              </p>
            </Box>
          )}
        </Paper>
      ))}
    </Box>
  );
};

const DirectShippingDetails = ({
  userCreds,
  checkoutDetails,
  setSelectedShippingMethods,
  fetchCheckoutDetails,
  selectedAddress,
  setSelectedAddress,
  formErrors,
  setFormErrors,
  setShippingTotal,
  hasAddress = true,
  loadingCheckoutDetails
}) => {
  const [selectedMethods, setSelectedMethods] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addressChangeLoading, setAddressChangeLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (checkoutDetails.vendors && checkoutDetails.vendors.length > 0) {
      const initialMethods = checkoutDetails.vendors.map((vendor) => ({
        selectedShippingMethod:
          vendor.shippingMethods && vendor.shippingMethods.length > 0
            ? vendor.shippingMethods[0]
            : null,
        directVendor: vendor.directVendor,
        vendorName: vendor.vendorName,
        vendorAddress: vendor.vendorAddress,
      }));
      setSelectedMethods(initialMethods);
    }
  }, [checkoutDetails.vendors]);

  const handleAddressChange = async (event) => {
    const newAddress = event.target.value;
    if (newAddress === formatAddress(selectedAddress)) {
      return;
    }
    if (newAddress === "add_new") {
      setIsModalOpen(true);
    } else {
      try {
        //setAddressChangeLoading(true);
        //const userResp = await prod_client.put(endpoints.users(userCreds.cognitoID), {
        //shipping_address: newAddress,
        //});
        //dispatch(fetchUserSuccess({ userObj: userResp?.data }));
        fetchCheckoutDetails(JSON.parse(newAddress));
        if (formErrors["shippingAddress"]) {
          setFormErrors((...prevFormErrors) => {
            return {
              ...prevFormErrors,
              shippingAddress: false,
            };
          });
        }
      } catch (error) {
        console.error(`Error handling address change: ${error}`);
      } finally {
        setAddressChangeLoading(false);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setAddressChangeLoading(true);
      const userResp = await prod_client.put(
        endpoints.users(userCreds.cognitoID),
        {
          shipping_address: selectedAddress,
        }
      );
      dispatch(fetchUserSuccess({ userObj: userResp?.data }));
      fetchCheckoutDetails();
      setIsModalOpen(false);
      if (formErrors["shippingAddress"]) {
        setFormErrors((...prevFormErrors) => {
          return {
            ...prevFormErrors,
            shippingAddress: false,
          };
        });
      }
    } catch (error) {
      console.error(`Error handling user preference change: ${error}`);
    } finally {
      setAddressChangeLoading(false);
    }
    setSelectedShippingMethods(selectedMethods);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const formatAddress = (address) => {
    if (!address) return "";
    return `${address.name}, ${address.street1}, ${address.city}, ${address.state} ${address.zip}`;
  };

  const showShippingMethods =
    checkoutDetails.vendors && checkoutDetails.vendors.length > 0;

  if (!hasAddress) {
    return (
      <>
        <AddressForm
          address={selectedAddress}
          setAddress={setSelectedAddress}
          onSubmit={handleSubmit}
          onClose={handleCloseModal}
          hasAddress={hasAddress}
        />
        <PageLoadingBackdrop loading={addressChangeLoading || loadingCheckoutDetails} />
      </>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          p: 0.75,
          px: 2,
          mb: "28px",
        }}
        className="flex items-center bg-grayscaleIce"
      >
        <Typography
          variant="h5"
          gutterBottom
          className="text-wolfGrey mb-[0px]"
        >
          Shipping Details
        </Typography>
      </Box>
      <Paper key="shipping_address" elevation={0} sx={{ px: 2 }}>
        <Typography variant="h6" className="text-charcoal mb-[0px]">
          Shipping Address
        </Typography>
        <FormControl
          fullWidth
          sx={{ mb: 2 }}
          error={formErrors["shippingAddress"]}
        >
          <Select
            value={selectedAddress ? JSON.stringify(selectedAddress) : ""}
            onChange={handleAddressChange}
            disabled={addressChangeLoading}
            sx={{
              "& .MuiOutlinedInput-input": {
                paddingTop: "15.5px",
                paddingBottom: "8.5px",
              },
            }}
          >
            <MenuItem value="" disabled>
              Select a shipping address
            </MenuItem>
            {checkoutDetails.shippingAddresses &&
              checkoutDetails.shippingAddresses.map((address, index) => (
                <MenuItem key={index} value={JSON.stringify(address)}>
                  {formatAddress(address)}
                </MenuItem>
              ))}
            <MenuItem value="add_new">+ Add new address</MenuItem>
          </Select>
        </FormControl>
      </Paper>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="address-form-modal"
        aria-describedby="modal-to-add-new-shipping-address"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Add New Shipping Address
          </Typography>
          <AddressForm
            address={selectedAddress}
            setAddress={setSelectedAddress}
            onSubmit={handleSubmit}
            onClose={handleCloseModal}
            hasAddress={hasAddress}
          />
        </Box>
      </Modal>
      {showShippingMethods && (
        <ShippingMethodSelector
          vendors={checkoutDetails.vendors}
          selectedMethods={selectedMethods}
          setSelectedMethods={setSelectedMethods}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          vendorCode={checkoutDetails.directVendor}
          setShippingTotal={setShippingTotal}
        />
      )}
    </Box>
  );
};

export default DirectShippingDetails;
