const CLOUDFRONT_URL = "https://d34c0c1in94n75.cloudfront.net";

export const getURLColor = (selectedColor) => {
  if (!selectedColor || !selectedColor?.name) {
    return "";
  }

  const newSubColors = [];
  const subColors = selectedColor?.name?.split(" ");
  if (!subColors) {
    return newSubColors;
  }

  for (const subColor of subColors) {
    const newSubColor = subColor[0].toUpperCase() + subColor.slice(1);
    newSubColors.push(newSubColor);
  }

  return newSubColors.join(" ");
};

export const constructImageUrl = (dgiStyle, imgColor, imgIndex) => {
  const imgIdentifier = dgiStyle + "-" + imgColor;
  return CLOUDFRONT_URL + "/" + imgIdentifier + "-" + imgIndex + ".jpg";
};

export const constructSwatchImageUrl = (dgiStyle, master_color) => {
  return CLOUDFRONT_URL + "/" + dgiStyle + "-" + master_color + "-swatch.jpg";
};

export const getValidImageUrls = (dgiStyle, imgColor) => {
  const validUrls = [];
  const maxImgIndex = 9; // based off the # of images we get from some vendor
  let currentIndex = 0;
  while (currentIndex <= maxImgIndex) {
    const currentImgUrl = constructImageUrl(dgiStyle, imgColor, currentIndex);
    validUrls.push(currentImgUrl);
    currentIndex += 1;
  }
  return validUrls;
};
