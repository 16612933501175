import { createSlice } from "@reduxjs/toolkit";
import vendors from "constants/vendors";

export const LOADING_STATES = {
  IDLE_STATUS: "idle",
  LOADING_STATUS: "loading",
  SUCCESS_STATUS: "success",
  FAILURE_STATUS: "failure",
};

const cartsSlice = createSlice({
  name: "carts",
  initialState: { carts: {} },
  reducers: {
    fetchCartsStartBatch(state, action) {
      const { vendorCodes, onlyIfIdle } = action.payload;
      const updatedCarts = {};
      const updatedVendors = [];

      // relevant when refreshing carts
      const directVendorCodes = Object.keys(state.carts)?.filter((code) => {
        return !Object.values(vendors).find(
          (vendor) => vendor.code === code,
        )
      })

      // at least know we're loading direct carts if they exist
      if (directVendorCodes.length === 0) {
        updatedCarts["direct"] = {
          loading: LOADING_STATES.LOADING_STATUS,
        }
      }

      const allVendorCodes = vendorCodes.concat(directVendorCodes);
      allVendorCodes.forEach((vendorCode) => {
        const cartLoadingStatus = state.carts?.[vendorCode]?.loading;
        if (
          (onlyIfIdle && cartLoadingStatus === LOADING_STATES.IDLE_STATUS) ||
          (!onlyIfIdle && cartLoadingStatus !== LOADING_STATES.LOADING_STATUS)
        ) {
          updatedCarts[vendorCode] = {
            ...state.carts?.[vendorCode],
            loading: LOADING_STATES.LOADING_STATUS,
            error: null,
          };
          updatedVendors.push(vendorCode);
        }
      });
      state.carts = {
        ...state.carts,
        ...updatedCarts,
      };
      action.payload.updatedVendors = updatedVendors;
    },

    fetchCartSuccess(state, action) {
      const { vendorCode, cartData } = action.payload;
      if (cartData?.items?.length === 0 || cartData?.totalPrice === 0) {
        delete state.carts[vendorCode];
        return;
      }
      state.carts[vendorCode] = {
        ...state.carts[vendorCode],
        loading: LOADING_STATES.SUCCESS_STATUS,
        error: null,
        totalPrice: cartData?.totalPrice ?? 0,
        items: cartData?.items ?? [],
        ...cartData,
      };
    },

    fetchDirectCartSuccess(state, action) {
      const { directCartData } = action.payload;
      // remove this non-cart we established at loading state
      delete state.carts?.["direct"];

      if (directCartData) {
        const filteredDirectCartData = Object.fromEntries(
          Object.entries(directCartData).filter(
            ([vendorCode]) =>
              !["action", "error", "vendorCode"].includes(vendorCode),
          ),
        );

        Object.entries(filteredDirectCartData).forEach(
          ([vendorCode, cartData]) => {
            if (Object.keys(cartData).length === 0) {
              delete state.carts[vendorCode];
              return;
            } 
            state.carts[vendorCode] = {
              ...state.carts[vendorCode],
              loading: LOADING_STATES.SUCCESS_STATUS,
              error: false,
              totalPrice: cartData?.totalPrice ?? 0,
              ...cartData,
            };
          },
        );
      }
    },

    fetchCartFailure(state, action) {
      const { vendorCode, errorCode, errorMessage } = action.payload;
      state.carts[vendorCode] = {
        ...state.carts[vendorCode],
        loading: LOADING_STATES.FAILURE_STATUS,
        error: { [errorCode]: errorMessage },
        data: { items: [], totalPrice: 0.0 },
      };
    },
  },
  selectors: {
    selectCart: (state, vendorCode) => {
      return state.carts?.[vendorCode]
    },
    selectCarts: (state, vendorCodes) => {
      const selectedCarts = {};
      for (const code of vendorCodes) {
        selectedCarts[code] = state.carts?.[code];
      }
      return selectedCarts;
    },
    selectDirectCarts: (state) => {
      // still loading in direct cart data
      if (state.carts["direct"]) {
        return state.carts["direct"]
      }
      const directCarts = {};
      const allCarts = Object.entries(state.carts);
      for (const [vendorCode, cartData] of allCarts) {
        const isDirectVendor = !Object.values(vendors).find(
          (vendor) => vendor.code === vendorCode,
        );
        if (isDirectVendor) {
          directCarts[vendorCode] = cartData;
        }
      }
      return directCarts;
    },
  },
});

export default cartsSlice.reducer;
export const { selectCart, selectCarts, selectDirectCarts } =
  cartsSlice.selectors;

export const {
  fetchCartsStartBatch,
  fetchCartSuccess,
  fetchCartFailure,
  fetchDirectCartSuccess,
} = cartsSlice.actions;
