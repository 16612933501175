import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import styles from "./styles.module.scss";
import { useSearchParams } from "react-router-dom";
import { useUserStateContext } from "App";
import { sendGetCartsPayload } from "features/sendGetCartsPayload";
import { useWebsocketContext } from "authsignin/AuthedPage";
import { useDispatch, useSelector } from "react-redux";
import { getVendorName } from "./CartCheckout";
import vendors from "constants/vendors";

export default function CartsHeader({
  selectedFilter,
  selectedDirectCart,
  connectedVendorCodes,
}) {
  const [searchParams] = useSearchParams();
  const displayCartData = useSelector((state) => state.carts?.carts);
  const { userCreds } = useUserStateContext();
  const { sendJsonMessage } = useWebsocketContext();
  const dispatch = useDispatch();

  const handleRefreshCarts = () => {
    sendGetCartsPayload(
      userCreds.cognitoID,
      sendJsonMessage,
      dispatch,
      connectedVendorCodes
    );
  };

  let numberOfItems = 0;
  let priceTotal = 0.0;
  let numberOfCarts = 0;
  if (displayCartData) {
    let cartDataEntries = Object.entries(displayCartData).filter(
      (cartEntry) => cartEntry[1]
    ); // filter for valid cart data

    if (searchParams.get("distributor")) {
      if (selectedDirectCart) {
        cartDataEntries = cartDataEntries.filter((cartEntry) => {
          return !Object.values(vendors).find(
            (vendor) => vendor.code === cartEntry[0]
          );
        });
      } else {
        cartDataEntries = cartDataEntries.filter((cartEntry) => {
          return cartEntry[0] === searchParams.get("distributor");
        });
      }
    }

    const newVendorSubtotals = {};
    for (const distributorCartEntry of cartDataEntries) {
      const [distributorCode, distributorCartData] = distributorCartEntry;
      let subtotal = 0.0;
      if (distributorCartData.items) {
        numberOfCarts++;
        for (const item of distributorCartData.items) {
          if (item.quantity) {
            numberOfItems += Number(item.quantity);
          }
          priceTotal +=
            Number(item.totalPrice) ||
            Number(item.unitPrice) * Number(item.quantity);
          subtotal +=
            Number(item.totalPrice) ||
            Number(item.unitPrice) * Number(item.quantity);
        }
      }
      // handle DGI direct vendor case
      if (!vendors[distributorCode]) {
        if (newVendorSubtotals["direct"]) {
          newVendorSubtotals["direct"] += subtotal;
        } else {
          newVendorSubtotals["direct"] = subtotal;
        }
      }
      newVendorSubtotals[distributorCode] = subtotal;
    }

    priceTotal = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(priceTotal);
  }

  return (
    <div className={`mb-[25px] ${styles.checkoutHeader}`}>
      <div className={`bg-dustyBlue ${styles.headerInfo}`}>
        <div>
          <span className="text-charcoal text-2xl">
            {getVendorName(selectedFilter, selectedDirectCart)}
          </span>
        </div>
        <IconButton
          className={`bg-blueblue hover:bg-denim px-[8px] py-[4px] normal-case ${styles.refreshCartWrapper}`}
          onClick={handleRefreshCarts}
        >
          <RefreshIcon className="text-grayscaleWhite" />
          <span className="text-grayscaleWhite text-lg">Refresh Carts</span>
        </IconButton>
      </div>
      <div className={styles.checkoutTotals}>
        <div>
          <span className="text-lg">{`${numberOfItems} items`}</span>
        </div>
        <div>
          <span className="text-lg">{priceTotal}</span>
        </div>
        <div>
          <span className="text-lg">
            {numberOfCarts > 1
              ? `${numberOfCarts} carts`
              : `${numberOfCarts} cart`}
          </span>
        </div>
      </div>
    </div>
  );
}
