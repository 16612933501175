import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  Box,
  Stack,
  Typography,
  Alert,
  AlertTitle,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import directClient from "../../axios/directClient.js";
import { useUserStateContext } from "App";
import DirectShippingDetails from "pages/directCheckout/DirectShippingDetails";
import DirectOrderSummary from "./DirectOrderSummary.js";
import DirectCarts from "./DirectCarts.js";
import CheckoutLoadingProgress from "custom/loading/CheckoutLoadingProgress.js";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY,
);

const StyledInput = ({ poNumber, setPoNumber, inputError, setFormErrors }) => {
  const [isPOFieldFocused, setIsPOFieldFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const baseStyles = {
    boxSizing: "border-box",
    borderWidth: "thin",
    outlineWidth: "thin",
    borderRadius: "10px",
    padding: "8px",
    fontSize: "16px",
    transition: "all 0.2s ease-in-out",
  };

  const getBorderStyle = (isPOFieldFocused, inputError) => {
    if (inputError) return "1px solid #df1b41"; // same as stripe dangerRed
    if (isPOFieldFocused) return "1px solid rgb(2, 129, 192)";
    return "1px solid #c8c8c8";
  };

  const getOutlineStyle = (isHovered, isPOFieldFocused, inputError) => {
    if (inputError) return "1px solid #df1b41"; // same as stripe dangerRed
    if (isHovered) return "1px solid #150D0A";
    if (isPOFieldFocused) return "1px solid rgb(2, 129, 192)";
    return "none";
  };

  const getBackgroundColor = (poNumber) => {
    return poNumber.length > 0 ? "rgba(221, 241, 247, 0.9)" : "transparent";
  };

  const getBoxShadow = (isPOFieldFocused) => {
    return isPOFieldFocused ? "0 0 0 1px rgb(2, 129, 192)" : "none";
  };

  const computedStyles = {
    ...baseStyles,
    border: getBorderStyle(isPOFieldFocused, inputError),
    backgroundColor: getBackgroundColor(poNumber),
    outline: getOutlineStyle(isHovered, isPOFieldFocused, inputError),
    boxShadow: getBoxShadow(isHovered, isPOFieldFocused),
  };

  const handleChange = (e) => {
    setPoNumber(e.target.value);
    if (inputError) {
      setFormErrors((prevFormErrors) => {
        return {
          ...prevFormErrors,
          poNumber: false,
        };
      });
    }
  };

  return (
    <Box className="w-fit">
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap="10px"
        sx={{
          width: "fit-content",
          p: 1,
        }}
      >
        <label htmlFor="poNumber" className="text-wolfGrey text-lg">
          PO Number:
        </label>
        <input
          autoComplete="off"
          type="text"
          id="poNumber"
          value={poNumber}
          onFocus={() => setIsPOFieldFocused(true)}
          onBlur={() => setIsPOFieldFocused(false)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onChange={handleChange}
          style={computedStyles}
        />
      </Stack>
      {inputError && (
        <Box className="flex flex-row justify-end mr-[10px] mb-[6px]">
          <p className="text-[#df1b41] text-sm m-[0px]">
            Your PO number is incomplete.
          </p>
        </Box>
      )}
    </Box>
  );
};

export function CheckoutForm({
  userCreds,
  checkoutDetails,
  fetchCheckoutDetails,
  loadingCheckoutDetails,
  selectedAddress,
  setSelectedAddress,
  selectedShippingMethods,
  setSelectedShippingMethods,
  poNumber,
  setPoNumber,
  formErrors,
  setFormErrors,
  setShippingTotal,
  needAddress,
}) {
  if (needAddress) {
    return (
      <DirectShippingDetails
        userCreds={userCreds}
        checkoutDetails={checkoutDetails}
        setSelectedShippingMethods={setSelectedShippingMethods}
        fetchCheckoutDetails={fetchCheckoutDetails}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        setShippingTotal={setShippingTotal}
        hasAddress={false}
        loadingCheckoutDetails={loadingCheckoutDetails}
      />
    );
  }
  return (
    <Box
      className="grow flex flex-col gap-[42px]"
      sx={{
        "& > *": {
          border: "1px solid lightgray",
          borderLeft: "2px solid rgb(2, 129, 192)", // lighterblueblue
          backgroundColor: "#fff",
        },
      }}
    >
      <StyledInput
        poNumber={poNumber}
        setPoNumber={setPoNumber}
        inputError={formErrors.poNumber}
        setFormErrors={setFormErrors}
      />
      <DirectShippingDetails
        userCreds={userCreds}
        checkoutDetails={checkoutDetails}
        setSelectedShippingMethods={setSelectedShippingMethods}
        fetchCheckoutDetails={fetchCheckoutDetails}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        setShippingTotal={setShippingTotal}
      />
      <RenderPaymentDetails
        userCreds={userCreds}
        checkoutDetails={checkoutDetails}
        selectedShippingMethods={selectedShippingMethods}
        loadingCheckoutDetails={loadingCheckoutDetails}
        selectedAddress={selectedAddress}
        poNumber={poNumber}
      />
      <DirectCarts vendors={checkoutDetails?.vendors} />
    </Box>
  );
}

function RenderPaymentDetails({
  userCreds,
  checkoutDetails,
  selectedShippingMethods,
  loadingCheckoutDetails,
  selectedAddress,
  poNumber,
}) {
  if (loadingCheckoutDetails || !checkoutDetails?.total) {
    return;
  }

  const paymentElementOptions = {
    layout: "tabs",
  };
  return (
    <Box>
      <Box
        sx={{
          p: 0.75,
          px: 2,
          mb: "28px",
        }}
        className="flex items-center bg-grayscaleIce"
      >
        <Typography
          variant="h5"
          gutterBottom
          className="text-wolfGrey mb-[0px]"
        >
          Billing Details
        </Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
      </Box>
    </Box>
  );
}

async function getDirectCheckoutDetails(
  userCreds,
  setCustomerSessionClientSecret,
  setCheckoutDetails,
  setLoadingCheckoutDetails,
  address,
  setSelectedAddress,
  setSelectedShippingMethods,
  loadingDescriptionIntervalRef,
  navigate,
  setShippingTotal,
  setError,
) {
  if (!userCreds?.cognitoID) {
    return;
  }

  setLoadingCheckoutDetails(true);
  const headers = {
    "User-Identifier": userCreds.cognitoID,
  };

  const params = {};
  try {
    let checkoutDetailsResp;
    if (address?.street1) {
      const body = {
        action: "shippingAddress",
        shippingAddress: address,
      };
      checkoutDetailsResp = await directClient.post(
        "/direct/checkout/shipping-address",
        body,
        {
          headers,
          params,
        },
      );
    } else {
      checkoutDetailsResp = await directClient.get("/direct/checkout", {
        headers,
        params,
      });
    }
    setCheckoutDetails(checkoutDetailsResp.data);
    setShippingTotal(checkoutDetailsResp.data?.shippingTotal?.toFixed(2));
    setCustomerSessionClientSecret(
      checkoutDetailsResp.data?.customerSessionClientSecret,
    );
    setSelectedAddress(checkoutDetailsResp.data?.shippingAddresses?.[0]);
    setSelectedShippingMethods(() => {
      const initialMethods = [];
      if (checkoutDetailsResp.data?.vendors) {
        checkoutDetailsResp.data.vendors.forEach((vendor) => {
          if (vendor.shippingMethods && vendor.shippingMethods.length > 0) {
            initialMethods.push({
              selectedShippingMethod: vendor.shippingMethods[0],
              directVendor: vendor?.directVendor,
              vendorName: vendor?.vendorName,
              vendorAddress: vendor?.vendorAddress,
            });
          }
        });
      }
      return initialMethods;
    });
    setLoadingCheckoutDetails(false);
  } catch (error) {
    console.error(
      `Error getting checkout details for direct: ${error?.response?.data?.errorMessage}`,
    );
    console.log(error.response);
    setError(
      error?.response?.data?.errorMessage ||
        "An error occurred while loading checkout details",
    );
    if (error?.response?.status === 400) {
      // ValueError -> no items in cart
      navigate("/cart");
      return;
    }

    setLoadingCheckoutDetails(false);
  } finally {
    clearInterval(loadingDescriptionIntervalRef.current);
  }
}

const pageLoadingMessages = [
  "Loading your addresses and billing methods...",
  "Almost done...",
];

export const PageLoadingBackdrop = ({ loading }) => {
  return (
    <Backdrop
      sx={{
        backgroundColor: "rgba(88, 88, 88, 0.2)",
        zIndex: (theme) => theme.zIndex.modal,
      }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default function DirectCheckoutPage({}) {
  const [customerSessionClientSecret, setCustomerSessionClientSecret] =
    useState("");
  const [checkoutDetails, setCheckoutDetails] = useState({});
  const [loadingCheckoutDetails, setLoadingCheckoutDetails] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState(
    checkoutDetails?.shippingAddresses?.[0] || {},
  );
  const [poNumber, setPoNumber] = useState(
    new Date().toLocaleDateString("en-US"),
  );
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [selectedShippingMethods, setSelectedShippingMethods] = useState(() => {
    const initialMethods = [];
    if (checkoutDetails.vendors) {
      checkoutDetails.vendors.forEach((vendor) => {
        if (vendor.shippingMethods && vendor.shippingMethods.length > 0) {
          initialMethods.push({
            selectedShippingMethod: vendor.shippingMethods[0],
            directVendor: vendor?.directVendor,
            vendorName: vendor?.vendorName,
            vendorAddress: vendor?.vendorAddress,
          });
        }
      });
    }
    return initialMethods;
  });
  const [formErrors, setFormErrors] = useState({});
  const { userCreds } = useUserStateContext();
  const [loadingMessagesIndex, setLoadingMessagesIndex] = useState(0);
  const loadingDescriptionIntervalRef = useRef();
  const [shippingTotal, setShippingTotal] = useState(0);

  const fetchCheckoutDetails = async (address) => {
    await getDirectCheckoutDetails(
      userCreds,
      setCustomerSessionClientSecret,
      setCheckoutDetails,
      setLoadingCheckoutDetails,
      address,
      setSelectedAddress,
      setSelectedShippingMethods,
      loadingDescriptionIntervalRef,
      navigate,
      setShippingTotal,
      setError,
    );
  };
  useEffect(() => {
    fetchCheckoutDetails(selectedAddress);
  }, []);

  useEffect(() => {
    loadingDescriptionIntervalRef.current = setInterval(() => {
      setLoadingMessagesIndex((prevIndex) =>
        prevIndex < pageLoadingMessages.length - 1 ? prevIndex + 1 : prevIndex,
      );
    }, 4000);

    return () => clearInterval(loadingDescriptionIntervalRef.current);
  }, []);

  // if there are already checkoutDetails to display, we want to use the Backdrop
  if (loadingCheckoutDetails && Object.keys(checkoutDetails).length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CheckoutLoadingProgress
          message={pageLoadingMessages[loadingMessagesIndex]}
        />
      </div>
    );
  }
  if (error) {
    return (
      <Alert severity="error" sx={{ fontSize: "15px " }}>
        <AlertTitle sx={{ fontSize: "18px " }}>{error}</AlertTitle>
      </Alert>
    );
  }
  const needAddress = !checkoutDetails?.shippingAddresses?.length;
  if (needAddress) {
    return (
      <Box className="w-full h-[100vh] flex flex justify-center pt-[48px]">
        <Box className="flex flex-col items-center gap-[36px] max-w-[800px]">
          <Box className="flex flex-col gap-[12px]">
            <Typography className="text-wolfGrey text-2xl text-center">
              Please add a shipping address before continuing to checkout.
            </Typography>
            <Typography className="text-wolfGrey text-xl text-center">
              You will only ever have to complete this step once.
            </Typography>
          </Box>

          <CheckoutForm
            userCreds={userCreds}
            checkoutDetails={checkoutDetails}
            loadingCheckoutDetails={loadingCheckoutDetails}
            fetchCheckoutDetails={fetchCheckoutDetails}
            setCheckoutDetails={setCheckoutDetails}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            selectedShippingMethods={selectedShippingMethods}
            setSelectedShippingMethods={setSelectedShippingMethods}
            poNumber={poNumber}
            setPoNumber={setPoNumber}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            setShippingTotal={setShippingTotal}
            needAddress={needAddress}
          />
        </Box>
      </Box>
    );
  }

  const options = {
    amount: Math.round((checkoutDetails?.total || 0) * 100),
    mode: "payment",
    currency: "usd",
    paymentMethodCreation: "manual",
    setup_future_usage: "off_session",
    appearance: {
      theme: "flat",
    },
  };
  const formInputs = {
    poNumber: poNumber.length > 0,
    shippingAddress: Object.keys(selectedAddress).length > 0,
  };
  for (const vendor of checkoutDetails?.vendors) {
    const key = "shippingMethod" + vendor.directVendor;
    const vendorShippingMethod = selectedShippingMethods.find(
      (method) => method.directVendor === vendor.directVendor,
    );
    if (vendorShippingMethod) {
      formInputs[key] =
        Object.keys(vendorShippingMethod?.selectedShippingMethod)?.length > 0;
    } else {
      formInputs[key] = false;
    }
  }

  return (
    <Elements options={options} stripe={stripePromise}>
      <Box
        className="flex flex-col items-center lg:items-start lg:flex-row gap-[36px]"
        sx={{ my: 4, mx: 1 }}
      >
        <CheckoutForm
          userCreds={userCreds}
          checkoutDetails={checkoutDetails}
          loadingCheckoutDetails={loadingCheckoutDetails}
          fetchCheckoutDetails={fetchCheckoutDetails}
          setCheckoutDetails={setCheckoutDetails}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          selectedShippingMethods={selectedShippingMethods}
          setSelectedShippingMethods={setSelectedShippingMethods}
          poNumber={poNumber}
          setPoNumber={setPoNumber}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          setShippingTotal={setShippingTotal}
        />
        <DirectOrderSummary
          userCreds={userCreds}
          checkoutDetails={checkoutDetails}
          selectedAddress={selectedAddress}
          poNumber={poNumber}
          selectedShippingMethods={selectedShippingMethods}
          formInputs={formInputs}
          setFormErrors={setFormErrors}
          shippingTotal={shippingTotal}
          needAddress={needAddress}
        />
        <PageLoadingBackdrop loading={loadingCheckoutDetails} />
      </Box>
    </Elements>
  );
}
