import React, { useState, useMemo } from "react";

import styles from "./styles.module.scss";
import SideCart from "../pages/cart/SideCart";
import { Hits, useInstantSearch } from "react-instantsearch";
import CustomPagination from "dashboard/CustomPagination";
import SearchResultsHeader from "dashboard/SearchResultsHeader";
import RefinementLists from "./RefinementLists";
import {
  Stack,
  Alert,
  CircularProgress,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import MobileRefinementsDrawer from "./MobileRefinementsDrawer";
import DataToggles from "./DataToggles";

import { useDataContext, useUserStateContext } from "../App";
import Product from "items/Product";
import useSocketPayloadSender, {
  getConnectedVendors,
} from "features/useSocketPayloadSender";

const NoResultsBoundary = React.memo(function NoResultsBoundary({
  children,
  fallback,
}) {
  const { results } = useInstantSearch();

  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned.
  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden className="w-full">
          {children}
        </div>
      </>
    );
  }

  return children;
});

function NoResults() {
  const { indexUiState } = useInstantSearch();

  return (
    <div className="w-full">
      <Alert severity="info" className={styles.alertMessage}>
        No results for {indexUiState.query}. Try checking your spelling or
        searching for a different style number.
      </Alert>
    </div>
  );
}

const HitsSocketWrapper = React.memo(
  function HitsSocketWrapper({ userId, vendorCodes, hits }) {
    useSocketPayloadSender(userId, vendorCodes, hits);

    return (
      <Hits
        hitComponent={Product}
        classNames={{
          list: "flex flex-col gap-20 pl-0",
          item: "list-none",
          root: "w-full",
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    // Only re-render if these props actually changed
    return (
      prevProps.userId === nextProps.userId &&
      prevProps.vendorCodes === nextProps.vendorCodes &&
      prevProps.hits === nextProps.hits
    );
  }
);

const MemoizedRenderHits = React.memo(function RenderHits({
  userId,
  vendorCodes,
}) {
  const { results } = useInstantSearch();

  if (results.nbHits === 0 || userId === null) {
    return <CircularProgress />;
  }

  return (
    <HitsSocketWrapper
      userId={userId}
      vendorCodes={vendorCodes}
      hits={results.hits}
    />
  );
});

const Dashboard = React.memo(function Dashboard({
  windowWidth,
  bannerHeight,
  headerHeight,
}) {
  const [openFacetsDrawer, setOpenFacetsDrawer] = useState(false);
  const { connectedVendorCodes } = useDataContext();
  const { userCreds } = useUserStateContext();
  // prevents component from re-rendering when parent component does
  const memoizedRefinements = useMemo(() => <RefinementLists />, []);
  const memoizedDataToggles = useMemo(() => <DataToggles />, []);

  // Memoize expensive vendor calculations
  const { hasConnectedVendor, isConnectionsSet, vendorCodes } = useMemo(() => {
    const vendorCodes = getConnectedVendors(connectedVendorCodes);

    const hasConnectedVendor = Object.entries(connectedVendorCodes).some(
      ([_, vendorData]) => Boolean(vendorData)
    );

    const isConnectionsSet = Object.entries(connectedVendorCodes).some(
      ([_, vendorData]) => vendorData !== null
    );

    return { hasConnectedVendor, isConnectionsSet, vendorCodes };
  }, [connectedVendorCodes]);

  // Memoize drawer margin calculation
  const drawerMarginTop = useMemo(() => {
    if (bannerHeight >= 0 && headerHeight >= 0) {
      return `${bannerHeight + headerHeight}px`;
    }
    return null;
  }, [bannerHeight, headerHeight]);

  if (!hasConnectedVendor && isConnectionsSet) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "2rem",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              maxWidth: "600px",
              color: "rgba(65, 75, 86, 1)", // Set the text color to charcoal
            }}
          >
            You need to connect at least one vendor account before you can enjoy
            a personalized shopping experience on DGI.
          </Typography>
          <Box
            component="img"
            src="https://d34c0c1in94n75.cloudfront.net/e-commerce.gif" // Update with your gif url
            alt="E-commerce"
            sx={{
              maxWidth: "300px",
              width: "100%",
              height: "auto",
              marginTop: "1rem",
              // border: "1px solid #d2d6da", // 1px border as requested
            }}
          />

          <Box
            sx={{
              marginTop: "3rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link
              to="/integrations?mode=tutorial"
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                sx={{
                  padding: "0.5rem 3rem",
                  fontSize: "1.25rem",
                  backgroundColor: "rgba(0, 110, 165, 1)", // Same color as the previous file
                  "&:hover": {
                    backgroundColor: "rgba(0, 110, 165, 0.9)", // Darker on hover
                  },
                }}
              >
                Connect My Vendors
              </Button>
            </Link>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <MobileRefinementsDrawer
        openFacetsDrawer={openFacetsDrawer}
        setOpenFacetsDrawer={setOpenFacetsDrawer}
        drawerMarginTop={drawerMarginTop}
      />
      <Stack
        direction="row"
        sx={{
          gap: "1em",
          marginX: "1em",
        }}
      >
        <Box className="hidden lg:flex flex-col gap-[24px] mt-[64px]">
          {memoizedDataToggles}
          {memoizedRefinements}
        </Box>
        <NoResultsBoundary fallback={<NoResults />}>
          <Stack
            direction="column"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SearchResultsHeader setOpenFacetsDrawer={setOpenFacetsDrawer} />
            <MemoizedRenderHits
              userId={userCreds.cognitoID}
              vendorCodes={vendorCodes}
            />
            <CustomPagination windowWidth={windowWidth} />
          </Stack>
        </NoResultsBoundary>
        <SideCart vendorCodes={vendorCodes} />
      </Stack>
    </>
  );
});

export default Dashboard;
