import React from 'react';
import { Grid, Typography } from '@mui/material';
import VendorCard from './VendorCard';

const VendorGrid = ({ vendors = [] }) => {
  return (
    <Grid container spacing={3}>
      {vendors.length > 0 ? (
        vendors.map((vendor) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={vendor.id}>
            <VendorCard vendor={vendor} />
          </Grid>
        ))
      ) : (
        <Typography variant="body1" align="center" sx={{ width: '100%', mt: 4 }}>
          No vendors available.
        </Typography>
      )}
    </Grid>
  );
};

export default VendorGrid;
