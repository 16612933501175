import React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useGetVendorByCodeQuery } from "features/baseApi";
import DirectBanner from "./DirectBanner";
import DirectVendorBody from "./DirectVendorBody"; // Import the new DirectVendorBody component

const DirectVendorPage = () => {
  const { vendorCode } = useParams();

  // Fetch the vendor data using the API hook
  const { data: vendor, isLoading: isVendorLoading, error } = useGetVendorByCodeQuery(vendorCode);

  // Handle error state
  if (error) {
    return <Typography color="error">Error loading vendor data: {error.message}</Typography>;
  }

  // Handle case where vendor is not found
  if (!vendor && !isVendorLoading) {
    return <Typography>Vendor not found.</Typography>;
  }

  return (
    <Box sx={{ px: "1rem" }}>
      <Box
        sx={{
          "@media (max-width: 800px)": {
            display: "none", // Hide DirectBanner below 800px
          },
        }}
      >
        <DirectBanner vendorCode={vendorCode} isVendorLoadingVendor={isVendorLoading} />
      </Box>
      <DirectVendorBody vendor={vendor} isVendorLoading={isVendorLoading} />
    </Box>
  );
};

export default DirectVendorPage;
