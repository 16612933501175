import React from "react";
import { Box, Skeleton } from "@mui/material";
import HeaderBar from "./HeaderBar";
import About from "./About";
import Products from "./Products";

const DirectVendorShopAboutContainer = ({
  vendor,
  selectedTab,
  handleTabChange,
  isVendorLoading,
}) => {
  const HeaderSkeleton = () => (
    <Box
      sx={{
        width: "100%",
        height: "48px", // Typical height for a header bar
        bgcolor: "background.paper",
        borderRadius: 1,
        mb: 2,
      }}
    >
      <Skeleton
        variant="rectangular"
        width="100%"
        height="100%"
        animation="wave"
      />
    </Box>
  );

  return (
    <Box sx={{ flex: 1 }}>
      {isVendorLoading ? (
        <HeaderSkeleton />
      ) : (
        <HeaderBar
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
        />
      )}
      {selectedTab === "about" && (
        <About vendor={vendor} isVendorLoading={isVendorLoading} />
      )}
      {selectedTab === "products" && (
        <Products vendor={vendor} isVendorLoading={isVendorLoading} />
      )}
    </Box>
  );
};

export default DirectVendorShopAboutContainer;
