import React, { useState, useEffect } from "react";
import {
  Box,
  Skeleton,
  Pagination,
  Typography,
  Alert,
  AlertTitle,
  CircularProgress,
} from "@mui/material";
import OrderTable from "./OrderTable";
import OrderHistoryHeader from "./OrderHistoryHeader";
import { useUserStateContext } from "App";
import { useGetOrdersQuery, baseApi } from "features/baseApi";

const ITEMS_PER_PAGE = 50;
const BATCH_SIZE = 300;
const PAGES_PER_BATCH = BATCH_SIZE / ITEMS_PER_PAGE; // 6 pages per batch

const BetaFeatureNotice = () => {
  return (
    <Box sx={{ maxWidth: "100%", my: "2rem" }}>
      <Alert severity="info" className="pb-[0px]">
        <AlertTitle className="text-lg">Beta Feature Notice</AlertTitle>
        <Typography variant="body2" paragraph className="text-base">
          <Box component="span" fontWeight="bold" className="text-base">
            Important:
          </Box>{" "}
          This feature is currently in Beta. Our team is actively working to
          improve it. We encourage you to share your feedback with us via the
          chat button at the bottom-right of your screen.
        </Typography>
      </Alert>
    </Box>
  );
};

export default function OrderHistoryPage() {
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { userCreds } = useUserStateContext();

  // Calculate which batch we need based on current page
  const batchNumber = Math.ceil(currentPage / PAGES_PER_BATCH);

  const {
    data: orderData,
    isLoading,
    isFetching,
    error,
  } = useGetOrdersQuery({
    userId: userCreds?.cognitoID,
    batchNumber,
    itemsPerPage: BATCH_SIZE,
  });

  useEffect(() => {
    if (searchInput) {
      setCurrentPage(1);
    }
  }, [searchInput]);

  // Prefetch next batch when user gets close to the end of current batch
  const prefetchOrders = baseApi.usePrefetch("getOrders");
  useEffect(() => {
    if (
      (currentPage % PAGES_PER_BATCH === PAGES_PER_BATCH - 1 ||
        currentPage % PAGES_PER_BATCH === 0) &&
      orderData?.hasMore
    ) {
      prefetchOrders({
        userId: userCreds?.cognitoID,
        batchNumber: batchNumber + 1,
        itemsPerPage: BATCH_SIZE,
      });
    }
  }, [currentPage, orderData?.hasMore, batchNumber, userCreds?.cognitoID]);

  const handlePageChange = (page) => {
    if (page !== currentPage) {
      console.log(`PAGE ${page}`);
      setCurrentPage(page);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const currentOrders = orderData?.data || [];

  // Show loading overlay only when fetching a new batch (not first batch)
  const showLoadingOverlay = isFetching && batchNumber !== 1;

  // Filter orders based on search input
  const filteredOrders = currentOrders.filter((order) =>
    searchInput
      ? JSON.stringify(order).toLowerCase().includes(searchInput.toLowerCase())
      : true,
  );

  const totalPages = filteredOrders
    ? Math.ceil(filteredOrders?.length / ITEMS_PER_PAGE)
    : Math.max(currentPage, PAGES_PER_BATCH);
  const startIx = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIx = currentPage * ITEMS_PER_PAGE;
  const ordersOnPage = filteredOrders.slice(startIx, endIx);
  console.log(currentOrders.at(-1));

  return (
    <Box sx={{ padding: "0 1rem" }}>
      <BetaFeatureNotice />
      <OrderHistoryHeader
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        totalPages={totalPages}
        totalOrders={currentOrders?.length}
        earliestOrderDate={currentOrders.at(-1)?.orderDate}
      />
      {isLoading ? (
        <Skeleton variant="rectangular" height="40rem" />
      ) : (
        <Box position="relative">
          {showLoadingOverlay && (
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgcolor="rgba(255, 255, 255, 0.7)"
              zIndex={1}
            >
              <CircularProgress />
            </Box>
          )}
          <OrderTable orders={ordersOnPage} searchInput={searchInput} />
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              showFirstButton={currentPage > 3}
              onChange={(_, page) => handlePageChange(page)}
              shape="rounded"
              variant="outlined"
              color="primary"
              disabled={isFetching}
              boundaryCount={0}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
