import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useDataContext } from 'App';
import { useLocation } from 'react-router-dom';

function PosthogSessionTracker({ children }) {
    const { searchSession, setSearchSession } = useDataContext();
    const posthog = usePostHog();
    const location = useLocation();

    useEffect(() => {
        let isInternalNavigation = false;

        const sendFinalEvents = () => {
            // Don't send events if this is internal navigation
            if (isInternalNavigation) {
                return;
            }

            if (searchSession.in_progress) {  
                posthog?.capture("search_and_update_cart", {
                    updated_cart: searchSession.updated_cart,
                    items_added: Array.from(new Set(searchSession.items_added)),
                    search_term: searchSession.search_term,
                    added_direct_vendor_items: searchSession.added_direct_vendor_items,
                });
                
                setSearchSession({
                    in_progress: false,
                    search_term: null,
                    items_added: [],
                    updated_cart: false,
                    prev_path: null,
                    added_direct_vendor_items: false,
                });
            }
        };

        // Using isRecordingEnabled method for a more reliable check
        const checkRecording = () => {
            if (posthog?.sessionRecording && !posthog.sessionRecording.isRecordingEnabled) {
                sendFinalEvents();
            }
        };

        // Handle visibility change
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                // Only send events if the page is actually being hidden (tab/window closed)
                // and not just during internal navigation
                if (!isInternalNavigation) {
                    sendFinalEvents();
                }
            }
        };

        // Handle before unload
        const handleBeforeUnload = (event) => {
            if (!isInternalNavigation) {
                sendFinalEvents();
            }
        };

        // Set up location change detection
        const cleanup = () => {
            isInternalNavigation = true;
            // Reset the flag after a short delay to handle actual page exits
            setTimeout(() => {
                isInternalNavigation = false;
            }, 100);
        };

        // Set up event listeners
        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('beforeunload', handleBeforeUnload);
            cleanup();
            // Only check recording on actual unmount (not during navigation)
            if (!isInternalNavigation) {
                checkRecording();
            }
        };
    }, [searchSession, posthog, location]); // Added location to dependencies

    return children;
}

export default PosthogSessionTracker;