import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { fetchProductStartBatch, fetchProductFailure } from "./productsSlice";
import { useWebsocketContext } from "authsignin/AuthedPage";
import vendors from "constants/vendors";

export const fetchPrice = (
  dispatch,
  userId,
  vendorCodes,
  styleData,
  masterColorName,
  sendJsonMessage,
) => {
  const getMasterColor = (styleData) => {
    return styleData.colors.find((color) => color?.name === masterColorName);
  };
  const productKeys = [
    {
      dgi_style_sku: styleData["dgi_style_sku"],
      master_color: masterColorName,
    },
  ];
  const existingProductKeys = getExistingProductKeys(
    dispatch,
    productKeys,
    vendorCodes,
  );
  const payload = createSocketPayload(
    dispatch,
    userId,
    existingProductKeys,
    [styleData],
    vendorCodes,
    () => getMasterColor(styleData),
  );
  sendPayload(sendJsonMessage, payload);
};

export const getConnectedVendors = (connectedVendorCodes) => {
  // expecting connectedVendorCodes to be pulled from App-level data context
  return Object.entries(connectedVendorCodes)
    .filter((vendor) => {
      return vendor[1];
    })
    .map((vendor) => {
      const dataKey = vendor[0];
      return dataKey?.split("_")[0];
    });
};

const getMasterColor = (hit) => {
  if (!hit?._highlightResult?.colors) {
    return null;
  }
  let colorIndex = hit._highlightResult.colors.findIndex(
    (color) => color.name.matchLevel === "full",
  );
  if (colorIndex == -1) {
    colorIndex = 0;
  }
  return hit["colors"][colorIndex];
};

export const createSocketPayload = (
  dispatch,
  userId,
  existingProductKeys,
  hits,
  connectedVendors,
  getMasterColor,
) => {
  const items = [];

  for (const hit of hits) {
    const hitPayload = {};
    const dgi_style_sku = hit.dgi_style_sku || hit.id;
    const target_color = getMasterColor(hit);
    const master_color = target_color?.["name"];
    if (hit?.direct) {
      hitPayload["dgi_style_sku"] = dgi_style_sku;
      hitPayload["direct_vendor"] = hit?.direct_vendor;
      hitPayload["dgi_style_sku"] = dgi_style_sku;
      hitPayload["master_style"] = hit?.master_style;
      hitPayload["master_color"] = master_color;
      items.push(hitPayload);
      continue;
    }

    for (const vendor of connectedVendors) {
      const key = dgi_style_sku + vendor + master_color;
      // skips hits that have incoming or received data
      if (existingProductKeys.includes(key)) {
        continue;
      }
      const vendorPayloadKey = Object.values(vendors).find(
        (v) => v.code === vendor,
      )
        ? vendor
        : "direct";
      for (const field of vendorPayloadFields[vendorPayloadKey]) {
        if (colorFields.has(field)) {
          hitPayload[field] = target_color?.[field];
          hitPayload["master_color"] = master_color;

          // If the field is undefined then we can update the store
          // With an empty result
          if (field !== "ab_style_alt" && hitPayload?.[field] === undefined) {
            dispatch(
              fetchProductFailure({
                dgi_style_sku: dgi_style_sku,
                master_color: master_color,
                vendorCode: vendor,
                errorCode: 404,
                errorMessage: "Color N/A",
              }),
            );
          }
        } else {
          hitPayload[field] = hit[field];
        }
      }
    }

    if (Object.keys(hitPayload).length === 0) {
      continue;
    }
    hitPayload["dgi_style_sku"] = dgi_style_sku;
    items.push(hitPayload);
  }

  return {
    action: "fetch_products",
    items: items,
    user_id: userId,
  };
};

export const getExistingProductKeys = (
  dispatch,
  productKeys,
  connectedVendors,
) => {
  const dispatchResp = dispatch(
    fetchProductStartBatch({
      productStyles: productKeys,
      vendorCodes: connectedVendors,
    }),
  );
  return dispatchResp.payload.existingProductKeys;
};

export const sendPayload = (sendJsonMessage, payload) => {
  if (payload.items.length > 0) {
    sendJsonMessage(payload);
    console.log("Sending json payload", payload);
  } else {
    console.log("All items already exist in the Redux store");
  }
};

const useSocketPayloadSender = (userId, connectedVendors, hits) => {
  const { sendJsonMessage } = useWebsocketContext();
  const dispatch = useDispatch();

  // Find the matched color index
  // Prepare the input array for selectVendorProductsBatch
  const productKeys = useMemo(() => {
    return hits.flatMap((hit) => {
      if (!hit?._highlightResult?.colors) {
        return {
          dgi_style_sku: hit.dgi_style_sku || hit.id,
          direct_vendor: hit?.direct_vendor,
        };
      }
      let colorIndex = hit._highlightResult.colors.findIndex(
        (color) => color.name.matchLevel === "full",
      );
      if (colorIndex == -1) {
        colorIndex = 0;
      }
      return {
        dgi_style_sku: hit.dgi_style_sku || hit.id,
        master_color: hit["colors"]?.[colorIndex]?.["name"],
        colorIndex: colorIndex,
        direct_vendor: hit?.direct_vendor,
      };
    });
  }, [JSON.stringify(hits)]);

  useEffect(() => {
    if (userId && connectedVendors.length > 0 && hits.length > 0) {
      const existingProductKeys = getExistingProductKeys(
        dispatch,
        productKeys,
        connectedVendors,
      );
      const payload = createSocketPayload(
        dispatch,
        userId,
        existingProductKeys,
        hits,
        connectedVendors,
        getMasterColor,
      );
      console.log(payload);
      sendPayload(sendJsonMessage, payload);
      return;
    }
  }, [userId, connectedVendors.length, JSON.stringify(hits), dispatch]);
};

export default useSocketPayloadSender;

export const colorFields = new Set([
  "ab_color_code",
  "ab_style_alt",
  "cm_color_code",
  "sm_short_color",
  "ss_color",
  "as_color_code",
  "as_cat_entry_ids",
  "acc_color_code",
  "pg_color_code",
  "sta_color_code",
  "asc_color_code",
  "slc_color",
]);

export const vendorPayloadFields = {
  ab: ["ab_style", "ab_color_code", "ab_style_alt"],
  cm: ["cm_style", "cm_color_code"],
  sm: [
    "sm_product_id",
    "sm_short_color",
    "sm_style", // need for deciding whether to display SM on product page
  ],
  ss: [
    "ss_product_url",
    "ss_color",
    "ss_style", // need for deciding whether to display SS on product page
  ],
  as: ["as_style", "as_color_code", "as_cat_entry_ids"],
  acc: ["acc_style", "acc_color_code"],
  pg: ["pg_style", "pg_color_code"],
  asc: ["asc_style", "asc_color_code", "asc_product_id"],
  sta: ["sta_style", "sta_color_code"],
  slc: ["slc_style", "slc_url_key", "slc_parent_sku", "slc_color"],
  direct: ["direct"],
};
