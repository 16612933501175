import React from "react";

import styles from "./styles.module.scss";
import CartFilter from "./CartFilter";

export default function CartFilterBar({
  selectedFilter,
  displayFilters,
  setSelectedFilter,
  allCarts,
}) {

  const vendorQuantityTotals = {};
  let allQuantityTotal = 0;
  for (const cartData of Object.entries(allCarts)) {
    const [vendorCode, vendorData] = cartData;
    if (!vendorData["items"]) {
      continue;
    }
    let quantityTotal = 0;
    for (const item of vendorData["items"]) {
      const quantityExists = Object.keys(item).includes("quantity");
      if (quantityExists) {
        quantityTotal += Number(item["quantity"]);
      }
    }
    allQuantityTotal += quantityTotal;
    vendorQuantityTotals[vendorCode] = quantityTotal;
  }
  vendorQuantityTotals["All Carts"] = allQuantityTotal;

  return (
    <div className={styles.cartFilterBar}>
      {displayFilters.sort().map((filter) => (
        <CartFilter
          key={filter}
          filterCode={filter}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          vendorQuantityTotals={vendorQuantityTotals}
        />
      ))}
    </div>
  );
}
