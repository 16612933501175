import React, { useState, useEffect, useRef } from "react";

import CheckoutFields from "./CheckoutFields";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography"; // Import Typography for MUI text components
import CheckoutLoadingProgress from "custom/loading/CheckoutLoadingProgress";

const RenderCheckoutFields = ({
  vendorName,
  loadingCheckoutDetails,
  checkoutDetails,
  getShippingMethods,
  loadingShippingMethods,
  setInvalidPONumber,
}) => {
  const [missingShippingMethods, setMissingShippingMethods] = useState(false);
  const [messageIndex, setMessageIndex] = useState(0);
  const timerRef = useRef(null);

  const messages = [
    `Connecting to ${vendorName}...`,
    "Loading your addresses and billing methods...",
    "Verifying your info...",
    "Almost done...",
  ];

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setMessageIndex((prevIndex) =>
        prevIndex < messages.length - 1 ? prevIndex + 1 : prevIndex
      );
    }, 4000);

    return () => clearInterval(timerRef.current);
  }, []);

  useEffect(() => {
    if (!loadingCheckoutDetails) {
      clearInterval(timerRef.current);
    }
  }, [loadingCheckoutDetails]);

  if (loadingCheckoutDetails) {
    return (
      <CheckoutLoadingProgress message={messages[messageIndex]} />
    );
  } else if (!loadingCheckoutDetails && checkoutDetails && !missingShippingMethods) {
    return (
      <CheckoutFields
        checkoutDetails={checkoutDetails}
        getShippingMethods={getShippingMethods}
        loadingShippingMethods={loadingShippingMethods}
        setInvalidPONumber={setInvalidPONumber}
        setMissingShippingMethods={setMissingShippingMethods}
      />
    );
  } else if (
    (!loadingCheckoutDetails && !checkoutDetails) ||
    missingShippingMethods // Suspecting that sometimes checkout details is missing from AB response
  ) {
    return (
      <Alert severity="error">
        <AlertTitle>
          Sorry, we couldn't load your shipping & billing info
        </AlertTitle>
        <div>
          Try refreshing the page or click the chat button at the bottom right
          of your screen for assistance.
        </div>
        <div className="mt-4">
          If the issue persists, please use {vendorName}'s site to complete your
          purchase.
        </div>
      </Alert>
    );
  }

  return null;
};

export default RenderCheckoutFields;
